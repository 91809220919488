/*Le header en général (logo + texte + boutons de connexion)*/
.loginheader {
    width: 100%;
    min-height: 4rem;
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 0 0 15px 15px;
    box-shadow: 0px 0px 10px 1px #979797;
    background-color: white;
    position: fixed;
    top: 0;
    z-index: 100;
}

.loginheader svg {
    cursor: pointer;
}

/*Le titre*/
/*.loginheader .loginheader-title {

}*/

/*Les boutons de connexion du header*/
.loginheader .loginheader-accountBtns {
    gap: 1rem;
    display: flex;
    width: 22rem;
}

.translate-icon-login-header {
    margin-left: auto;
    margin-right: 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 900px) {
    .loginheader .loginheader-accountBtns {
        gap: 1rem;
        order: 2;
    }

    .loginheader .loginheader-accountBtns .action-button {
        padding: 5px;
    }

    .translate-icon-login-header {
        margin-left: unset;
        order: 3;
    }
}
@media screen and (max-width: 420px) {
    .loginheader .diamond-logo {
        display: none;
    }
    .loginheader{
        padding-right: 0;
    }
    .translate-icon-login-header{
        margin-left: 1rem;
    }
}

@media screen and (max-width: 350px) {
    .loginheader .loginheader-accountBtns {
        width: 15rem;
    }
}

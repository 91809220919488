.container-admin-users {
    /*border: 1px solid red;*/
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.container-add-admin {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /*border: 1px solid palegreen;*/
}

.container-choice-admin {
    display: flex;
    justify-content: space-between;
    border: 1px solid var(--primary10);
    border-radius: 10px;
    margin-top: 2rem;
    padding: .5em;
}

.block-button {
    width: auto;
}

.block-description {
    display: flex;
    align-items: center;
    gap: .5rem;
    margin-left: .5rem;
}

.block-description .icon svg path {
    fill: var(--primary50)
}

.container-add-admin .button {
    max-width: 15rem;
}

.block-chip {
    margin-right: 1rem;
}

.container-list-admin {
    margin-top: 1rem;
    /*border: 1px solid black;*/
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.item-admin {
    background-color: var(--primary10);
    border-radius: 12px;
    /*border: 1px solid red;*/
    display: flex;
    justify-content: space-between;
    padding: 1rem;
}

.block-button-list-admin {
    /*border: 1px solid darkblue;*/
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.block-button-list-admin svg {
    height: 1.5rem;
}

.block-button-list-admin svg path {
    fill: black;
}

.block-button-list-admin span {
    text-transform: none;
    color: black;
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 11px;
}

.block-details-user {
    /*border: 1px solid blue*/
}

.block-right {
    /*border: 1px solid violet;*/
    display: flex;
    align-items: center;
    gap: .5rem;
}

.item-admin .active {
    background: var(--validate10);
}

.item-admin .pending {
    background: var(--wait10);
    display: flex;
    width: fit-content;
}

.item-admin .pending svg {
    margin-left: .4rem !important;
    min-width: 20px;
    min-height: 20px;
}

.item-admin .expired {
    background: var(--actionprimary50);
}

.item-admin .expired span {
    color: var(--primary00);
}

.item-admin .active svg {
    height: 20px;
    width: 20px;
    margin: 0px;
}

.item-admin .pending svg {
    height: 20px;
    width: 20px;
    margin: 0;
}

.item-admin .active svg path {
    fill: var(--validate50)
}

.item-admin .pending svg path {
    stroke: var(--wait50)
}

.item-admin .isolation-white {
    width: 3px;
    height: 100%;
    background-color: white;
}

.item-admin .return span {
    color: #000000;
    text-transform: none;
}
.br{
    width: 100%;
    height: 2px;
    background-color: white;
    margin: .5rem auto;
}
.identity {
    color: var(--primary90);
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.block-icons {
    /*border: 1px solid red;*/
    display: flex !important;
    align-items: center !important;
    height: 5rem;
    position: relative;
}
@media screen and (max-width: 900px) {
    .item-admin{
        flex-direction: column;
        padding: 1.5rem 1.5rem 0 1.5rem;
    }
    .block-right{
        justify-content: center;
    }
    .container-choice-admin{
        flex-direction: column;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
    }
    .container-add-admin{
        flex-direction: column;
        gap: 1rem;
    }
}
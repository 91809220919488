/* totalité de la page de vérification de code */
.verifyCodePage {
    margin-top: 6rem;
    width: 100vw;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    margin-bottom: 3rem;
}

/* on supprime l'image en entête mobile et tablet */
.verifyCodePage .container .diamondLogin {
    display: none;
}

/* le container de la page de vérification de code */
.verifyCodePage .container {
    width: 50vw;
}

/* texte de la page de vérification de code */
.verifyCodePage h1 {
    margin-bottom: 0.6rem;
    text-align: center;
}

.verifyCodePage h2 {
    margin-bottom: 0.6rem;
}

.verifyCodePage .paragraphs {
    margin-bottom: 2.5rem;
    text-align: center;
    white-space: pre-wrap;
}

.verifyCodePage .buttons {
    display: flex;
    align-items: space-between;
    gap: 0.9rem;
    margin-bottom: 2.5rem;
}

.verifyCodePage .link {
    color: var(--actionprimary50);
    text-align: center;
    font-family: var(--font-secondary);
    text-decoration: none;
}

/* OTP input */
.otp-input-container {
    margin-bottom: 5.3rem;
}

/* responsive tablette */
@media screen and (max-width: 768px) {

    /* page */
    .verifyCodePage {
        margin-top: 0;
        display: block;
        width: 100%;
        margin-bottom: 8rem;
    }

    /* container de la page */
    .verifyCodePage .container {
        width: 100%;
        padding: 0 7rem;
    }

    /* affichage du logo */
    .verifyCodePage .container .diamondLogin {
        display: block;
        margin: auto;
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
}

/* responsive mobile */
@media screen and (max-width: 768px) {

    /* container de la page */
    .verifyCodePage .container {
        width: 100%;
        padding: 0 2rem;
    }

    /* affichage du logo */
    .verifyCodePage .container .diamondLogin {
        display: block;
        margin: auto;
        margin-top: 2rem;
        margin-bottom: 3rem;
    }

    .verifyCodePage .container .paragraphs {
        margin-bottom: 2rem;
    }

    .verifyCodePage .container .buttons {
        margin-top: 0;
    }

    .otp-input-container {
        margin-bottom: 3.5rem;
    }

}

@media screen and (min-width: 768px) {
    .verifyCodePage .container {
        width: 60%;
        padding: 0 2rem;
        margin: 2rem;
    }
}

@media screen and (min-width: 1100px) {
    .verifyCodePage .container {
        width: 50vw;
        padding: 0 2rem;
        margin: 2rem;
    }
}

@media screen and (min-width: 1250px) {
    .verifyCodePage .container {
        width: 36vw;
        padding: 0 2rem;
        margin: 2rem;
    }
}
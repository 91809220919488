/* ------------------------------------------------------------- */

/* nav bar DESKTOP */

/* ------------------------------------------------------------- */

.nav-bar {
    width: 16.4vw;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    background-color: var(--primary10);
    border-radius: 0px 30px 0px 0px;
    z-index: 101;
    color: var(--primary90);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

/* Header de la nav-bar */
.nav-bar-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 20vh;
    gap: 10px;
    position: relative;
    z-index: 1;
}
.nav-bar-header a{
    padding: 0;
}
.text-status-user {
    padding: 0;
    text-align: center;
    font-family: var(--font-secondary);
}

.text-status-user .small-lead-text {
    line-height: 12px;
    font-weight: bold;
}

.text-status-user p {
    font-size: 1rem;

}

/* logo de l'application */

.nav-bar-module-desktop .diamond-header-svg-desktop,
.nav-bar-module-desktop .diamond-header-svg-tablet {
    width: 16.5vw;
    padding: 2.9vh 0;
    box-sizing: content-box;
}

/* liste des liens de la nav bar */

/* Block invisible contenant les dimensions de la nav bar afin de centrer verticalement les navlinks */
.nav-bar-block-navlinks {
    position: absolute;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.nav-bar-navlinks-center {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 15px;
    width: 100%;
}

.nav-bar-navlinks {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.nav-bar-navlinks-item {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.nav-bar-navlinks ul {
    padding: 0;
    margin: 0;
}

.nav-bar-navlinks-list {
    list-style-type: none;
    display: flex;
    align-items: center;
    justify-content: start;
    padding: 0.5rem 1rem 0.5rem 1rem;
    transition: all 0.1s ease-in;
    border-radius: 15px;
    gap: 10px;
    color: var(--primary90);
    font-family: Barlow, sans-serif;
    font-size: 1.2rem;
    font-weight: 500;
    position: relative;
    cursor: pointer;
}

.nav-bar-navlinks-icon {
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.2s ease-in;
    z-index: 5;
}

.nav-bar-navlinks-links {
    z-index: 5;
    transition: all 0.2s ease-in-out;
}

.nav-bar-navlinks-list:hover .nav-bar-navlinks-icon path {
    fill: var(--primary00);
    transition: all 0.3s ease-in-out;
}

/* apparence des textes des liens */

.nav-bar-navlinks ul li a {
    display: flex;
    gap: 15px;
}

.nav-bar-navlinks-list:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(275.49deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%);
    transition: all 0.2s ease-in-out;
    opacity: 0;
    z-index: 0;
    border-radius: 15px;
}

.nav-bar-navlinks-list:hover:after {
    opacity: 1;
}

.nav-bar-navlinks-list:hover {
    color: var(--primary00);
}
.nav-bar-footer {
    z-index: 2;
}
.nav-bar-footer p {
    text-align: center;
    font-size: 1rem;
    padding: 1.5rem 1rem;
}

.nav-bar-footer a {
    color: var(--primary90);
    font-size: 0.8rem;
}

.nav-bar-footer svg {
    width: 17px;
    margin-right: 0.2rem;
}

/* apparence des liens actifs */

.nav-bar-navlinks-list-active {
    background: linear-gradient(275.49deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%);
    color: var(--primary00);
}

.nav-bar-navlinks-list-active path {
    fill: var(--primary00);
}

.nav-bar-navlinks-icon {
    width: 24px;
    height: 24px;
    transition: all 0.2s ease-in-out;
}

/* Responsive */

/* Desktop */
@media screen and (min-width: 1000px) {
    .diamond-header-svg-desktop {
        display: block;
    }

    .diamond-header-svg-tablet {
        display: none;
    }
}

/* Tablette */

@media screen and (max-width: 1300px) {
    .text-status-user {
        display: none;
    }

    .nav-bar-navlinks-links {
        font-size: 15px;
        display: flex;
        align-items: center;
    }
}

@media screen and (max-width: 999px) {
    .diamond-header-svg-desktop {
        display: none;
    }

    .diamond-header-svg-tablet {
        display: block;
    }

    .nav-bar-footer p {
        text-align: center;
        font-size: 0.8rem;
        padding: 1rem 0.5rem;
    }

    .nav-bar-footer a {
        color: var(--primary90);
        font-size: 0.7rem;
    }
}

/* Mobile */

@media screen and (max-width: 900px) {
    .nav-bar {
        height: 10vh;
        width: 100%;
        position: fixed;
        top: auto;
        left: 0;
        right: 0;
        bottom: 0;
        border-radius: 30px 30px 0 0;
        z-index: 101;
        background-color: var(--primary00);
        box-shadow: var(--menu);
    }

    .nav-bar-header {
        display: none;
    }

    .nav-bar-block-navlinks {
        width: 100%;
        height: 10vh;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .nav-bar-navlinks-center {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 15px;
        width: 100%;
        height: 10vh;
        position: relative;
    }

    .nav-bar-navlinks {
        width: 80%;
    }

    .nav-bar-navlinks-item {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        gap: 20px;
    }

    .menu-profile-icon-close {
        right: 2rem;
        left: unset;
    }

    .menu-profile-logo {
        position: absolute;
        left: 2rem;
        top: 1rem;
    }

    .menu-profile-block-identity {
        margin-top: 10vh;
    }

    .nav-bar-navlinks-list {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        gap: 2px;
        height: 7vh;
    }

    .nav-bar-navlinks-icon path {
        fill: var(--primary90);
    }

    .nav-bar-navlinks-list:hover .nav-bar-navlinks-icon path {
        fill: var(--actionprimary50);
    }

    .nav-bar-navlinks-list:hover .nav-bar-navlinks-links {
        color: var(--actionprimary50);
    }

    .active .nav-bar-navlinks-links {
        color: var(--actionprimary50);
        font-weight: bold;
    }

    .active .nav-bar-navlinks-icon path {
        fill: var(--actionprimary50);
    }

    .nav-bar-navlinks-links {
        font-size: 12px;
        font-weight: 400;
        color: var(--primary90);
    }

    .nav-bar-navlinks-list:after {
        background: transparent;
    }

    .nav-bar-navlinks-list-active {
        background: transparent;
    }

    .nav-bar-footer {
        display: none;
    }
}

.nav-bar-links-skeleton{
    display: flex;
    width: 100%;
    gap: 1rem;
}

.line {
    height: 75vh;
    border-left: 2px solid var(--primary30);
    margin: 0;
    position: fixed;
    margin-top: -5rem;
    
}

.h1-intervenant-admin {
    margin: 0 !important;
}

.all-school-cards {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 2rem;
}

@media screen and (max-width: 768px) {
    .line {
        display: none;
    }
}
.my-project{
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
}

.my-project h3 {
    text-align: left;
    margin-bottom: 0;
}

.my-project .coverImg img{
    width: 100%;
    object-fit: cover;
}

.my-project .projectInfos{
    background-color: var(--primary10);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    height: 100%;
    text-align: left;
}

.my-project .step-status {
    display: flex;
    justify-content: space-between;
}

.my-project .projectInfos .step-status p {
    font-style: italic;
}

.step-status .good{
    color: var(--validate50) !important;
}
.step-status .bad{
    color: var(--error50) !important;
}
.step-status .draft{
    color: var(--primary50) !important;
}
.prize{
    border-radius: 10px;
    border: 1px solid var(--primary50);
}

.prize > div{
    padding: 0.62rem;
}

.prize > div:not(:last-child){
    border-bottom: 1px solid var(--primary50);
}

.prize-title div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prize-title .icons{
    display: flex;
    gap: 0.5rem;
}

.prize .category-title{
    margin-bottom: 0.31rem;
}

.prize-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.prize-header > .prize-title{
    width: 100%;
}

.prize-image img{
    min-width: 20vh;
    min-height: 20vh;
}

.prize-image .image-uploader{
    width: fit-content;
    margin: auto;
}

.prize .icon-clickable:hover{
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease-in-out;
}

.prize input.input-editable,.prize textarea.input-editable{
    border: none;
    outline: none;
    width: 100%;
    resize: none !important;
}

.prize .thin{
    font-weight: 400;
}
.question-list .question{
    border-radius: 10px;
    border: 1px solid var(--primary50);
}

.question-list .question > div{
    padding: 0.62rem;
}

.question-list .question > div:not(:last-child){
    border-bottom: 1px solid var(--primary50);
}

.question-list .question .question-title div{
    padding: 0px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-list .question .question-title .icons{
    display: flex;
    gap: 0.5rem;
}

.question-list .question .category-title{
    margin-bottom: 0.31rem;
}

.question-list .question .question-header{
    background-color: var(--primary00);
    border-radius: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.question-list .question .question-header > .question-title{
    width: 100%;
}

.question-list .question .icon-clickable:hover{
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease-in-out;
}

.question-list .question input.input-editable,.question textarea.input-editable{
    border: none;
    outline: none;
    width: 100%;
    resize: none !important;
}

.question .thin {
    font-weight: 400;
}
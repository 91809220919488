.component-modal {
    width: 100%;
    height: 100vh;
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    transition: all 0.5s ease-in-out;
}

.component-modal-hidden {
    opacity: 0;
    z-index: -1;
    height: 0;
    transition: all 0.5s ease-in-out;
}

.component-modal-visible {
    opacity: 1;
}

.component-modal-overlay {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.component-modal-overlay-visible {
    opacity: 1;
    z-index: 1;
}

.component-modal-overlay-hidden {
    opacity: 0;
    transition: opacity 0.5s ease-in-out, width 0.5s ease-in-out 0.25s;
    width: 0;
}

.component-modal-content {
    width: 50%;
    background-color: white;
    z-index: 2;
    position: relative;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: var(--pop-up);
    transition: all 0.2s ease-in-out;
    max-height: 90vh;
}

.component-modal-content-visible {
    opacity: 1;
    z-index: 1;
}

.component-modal-content-hidden {
    opacity: 0;
    z-index: 0;
    transition: all 0.5s ease-in-out 1s;
}

.component-modal-content-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 3rem;
    gap: 1rem;
    overflow-wrap: anywhere;
    max-width: 80%;
    text-align: center;
}

.component-modal-content-header svg {
    width: 2rem;
    height: 2rem;
}

.component-modal-content-body {
    width: 80%;
    margin-top: 1rem;
    text-align: center;
    overflow-y: auto;
    padding: 10px; /*ça passe ?*/
}

.component-modal-content-body .ps {
    margin-top: 2rem;
}

.component-modal-content-footer {
    width: 100%;
    margin-top: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 3rem;
}

.component-modal-content-footer .buttons {
    width: 80%;
    max-width: 80%;
    display: flex;
    gap: 2rem;
    justify-content: center;
    align-items: center;
}

.component-modal-content-footer .buttons button {
    max-width: 15rem;
}

.component-modal-content-footer .buttons-column {
    flex-direction: column;
}

.component-modal-content-footer a {
    width: 100%;
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.linkModal {
    line-height: 15px;
    color: var(--actionprimary50);
    border-bottom: 1px solid var(--actionprimary50);
    transition: all 0.2s ease-in-out;
}

.linkModal:hover {
    color: #c01818;
    border-bottom: 1px solid #c01818;
}

@media screen and (max-width: 900px) {
    .component-modal-content {
        width: 100%;
        border-radius: 30px 30px 0 0;
        margin-top: auto;
    }

    .component-modal-content-hidden {
        margin-bottom: -100vh;
    }
}
.red svg path{
    fill: var(--error50);
}
.errorModal {
    border: solid 2px var(--error50);
}
.errorIconFill path{
    fill: var(--error50);
}
.errorIconStroke path{
    stroke: var(--error50);
}
.infoModal {
    border: solid 2px var(--error50);
}

@media screen and (max-width: 900px) {
    .errorModal {
        border-bottom: none;
    }

    .infoModal {
        border-bottom: none;
    }
}

.errorModal-message {
    color: var(--error50);
}


#module-collapse-steps-recap {

}

#module-collapse-steps-recap .chip-info-recap {
    background-color: var(--primary10);
    padding: 0.5em;
    border-radius: 10px;
    margin-bottom: 0.5em;
}

#module-collapse-steps-recap .chip-info-recap h4{
    margin-bottom: 0;
}

#module-collapse-steps-recap .summary-collapse{
    width: '90%'
    
}
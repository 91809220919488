.label-resource {
    color: var(--primary90, #122236);
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    text-align: left!important;
}

.name-resource {
    color: var(--primary90, #122236);
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: left!important;
}

.cursor-pointer {
    cursor: pointer;
}

.exceeding {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}
.container-admin-infos-challenge-select {
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.container-admin-infos-challenge-select-menu-tabs {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-infos-challenge-select-menu-tabs {
    background-color: var(--secondary10);
    color: var(--secondary50);
    padding: 5px;
    border-radius: 8px;
    width: fit-content;
    display: flex;
    gap: .3rem;
}

.admin-infos-challenge-select-menu-tabs p {
    padding: 13px 15px;
    font-family: var(--font-primary);
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
    background-color: transparent;
    color: var(--secondary50);
    border-radius: 8px;
    cursor: pointer;
    transition: all .2s ease-in-out;
}

.admin-infos-challenge-select-menu-tabs p:hover {
    color: white;
    background-color: var(--secondary50);
}

.admin-infos-challenge-select-menu-tabs .selected {
    background-color: var(--secondary50);
    color: white;
}

.container-admin-infos-challenge-select-list-content {
    margin-top: 1rem;
    display: flex;
    width: 100%;
    gap: 3rem;
}

@media screen and (max-width: 900px) {
    .container-admin-infos-challenge-select-list-content {
        flex-wrap: wrap;
        justify-content: center;
    }
}

.container-inscrits {
    width: 75%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    transition: all 0.2s ease-in-out;
}

.loaded {
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
}

.notLoaded {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    animation: loading 0.5s;
    display: none !important;
}

@keyframes loading {
    from {
        display: block;
    }
    to {
        display: none;
    }
}

.container-inscrit {
    width: 100% !important;
    background-color: var(--primary10);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    border-radius: 10px;
    transition: all 0.3s ease-in-out;
}

.container-inscrits-infos-perso {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.list-inscrit-img-profile {
    min-width: 5rem;
    min-height: 5rem;
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 50%;
}

.infos-perso-name {
    display: flex;
    gap: .2rem;
}

.infos-perso-name p {
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: 700;
}

.infos-perso {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: .3rem;
    height: fit-content;
}

.infos-perso .infos-perso-email-tel {
    font-family: var(--font-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 5px;
    justify-content: start;
    flex-wrap: wrap;
}

.infos-perso .infos-perso-job-company {
    display: flex;
    gap: 5px;
    justify-content: start;
    flex-wrap: wrap;
}

.infos-perso .infos-perso-job-company p {
    font-family: var(--font-secondary);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.infos-perso .infos-perso-degree-school {
    display: flex;
    gap: 5px;
    justify-content: start;
    flex-wrap: wrap;
}

.infos-perso .infos-perso-degree-school p {
    font-family: var(--font-secondary);
    font-size: 14px !important;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.block-search-filter {
    width: 30%;
}

.container-checkboxs {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: 3rem;
}

.container-checkboxs p {
    color: var(--primary50);
    font-weight: 400;
    font-family: var(--font-secondary);
}

.checkboxs {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-weight: 800;
    line-height: 20px;
}

.MuiFormControlLabel-label {
    font-weight: 600 !important;
    font-size: 14px !important;
    font-family: var(--font-secondary);
}

.nb-inscrits {
    text-align: center;
    font-weight: 700;
    font-family: var(--font-secondary);
    font-size: 30px;
}

.container-top {
    margin-top: 2rem;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
}

.container-top .participations {
    /*border: 1px solid red;*/
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.skeleton-inscrit-name {

}

.skeleton-infos-perso-email-tel {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    flex-wrap: wrap;
}

.skeleton-infos-perso-email-tel div {
    display: flex;
    gap: .5rem;
    flex-wrap: wrap;
}

.no-result {
    padding: .5rem 1rem;
    font-size: 1.5rem;
    font-family: var(--font-secondary);
    font-weight: 400;
    background-color: var(--primary10);
    border-radius: 10px;
}

@media screen and (max-width: 900px) {
    .container-top .participations {
        width: 100%;
    }

    .container-top .participations-chip {
        flex-wrap: wrap;
    }

    .container-top .participations p {
        text-align: center;
    }
}

.container-top .participations p {
    color: var(--primary50);
    font-weight: 400;
    word-spacing: 3px;
    font-size: 16px;
}

.container-top .participations-chip {
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}

.container-top .participations-chip .test {
    font-weight: bold;
}

.container-inscrits-infos-equipe-project {
    width: fit-content;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: end;
    gap: 1.5rem;
}

.container-inscrits-infos-equipe-project .chip-container {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: .5rem;
}


.container-inscrits-infos-equipe-project .chip-container .team {
    font-weight: bold;
    text-align: right;
    max-width: 10rem;
}
@media screen and (max-width: 500px) {
    .container-inscrits-infos-equipe-project .chip-container {
        flex-direction: row;
        align-items: center;
    }

    .container-inscrits-infos-equipe-project .chip-container .team {
        text-align: left;
    }
}

.no-users {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
}

.no-users p {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: 700;
}

.no-users svg {
    width: 20rem;
}

@media screen and (max-width: 900px) {
    .admin-infos-challenge .admin-infos-challenge-content {
        width: 90%;
    }

    .block-search-filter {
        width: 100%;
    }

    .container-inscrits {
        width: 100%;
    }

    .checkboxs {
        flex-direction: row !important;
        flex-wrap: wrap;
        width: 100%;
    }

    .infos-perso-name p {
        font-size: 18px
    }

    .infos-perso .infos-perso-email-tel p {
        font-size: 12px;
    }
}

.button-see-project {
    width: max-content !important;
}

@media screen and (max-width: 500px) {
    .container-inscrits {
        gap: 1rem;
    }

    .container-inscrit {
        flex-direction: column;
        gap: 1rem;
    }

    .button-see-project {
        font-size: 12px;
        width: fit-content !important;
    }

    .chip-infos-equipe-project {
        font-size: 10px !important;
        padding: 0px !important;
        height: fit-content !important;
    }

    .chip-infos-equipe-project svg {
        width: 15px !important;
    }

    .container-inscrits-infos-perso img {
        width: 3rem;
        height: 3rem;
        min-width: 3rem;
        min-height: 3rem;
    }

    .infos-perso-name p {
        font-size: 16px;
    }

    .infos-perso .infos-perso-email-tel {
        flex-wrap: wrap;
    }

    .container-inscrits-infos-equipe-project {
        gap: .5rem;
        flex-direction: row;
        width: 100%;
        align-items: center;
    }

    .container-inscrits-infos-perso {
        width: 100%;
    }
}

.profile-admin-part {
    width: 50vw;
    padding: 30px;
    margin-top: 5rem;
    margin-bottom: 5rem;
    border-radius: 20px;
    box-shadow: 0px 9px 14px 0px rgba(18, 34, 54, 0.10);
    background-color: var(--primary00);
}

.block-profile-admin {
    display: flex;
    flex-direction: column;
    gap: 29px;
}
.block-pp-name{
}
.block-pp-name .circle-contact-pict{
    width: 124px;
    height: 124px;
}

.block-buttons-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}
.block-infos{
    display: flex;
    flex-direction: column;
    gap: 20px;
}

@media screen and (max-width: 900px) {
    .profile-admin-part{
        width: 90%;
        box-shadow: none;
        padding: 0;
    }
}
@media screen and (max-width: 600px) {
    .profile-admin-part{
        width: 80%;
        box-shadow: none;
        padding: 0;
    }
}
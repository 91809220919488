.participant-challenges {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

/*Autres divs de la page*/

.participant-challenges .participant-challenges-empty {
    margin-top: .5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    align-items: center;
    text-align: center;
    max-width: 80%;
}

.participant-challenges .participant-challenges-empty .action-button {
    max-width: 50%;
}

.card-my-participation h4 {
    width: 85%;
}

.participant-challenges .participant-challenges-empty .participant-challenges-empty-paragraph {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.button-see-all-challenges{
    width: 15rem;
}
.participant-my-project-content{
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.my-projects{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    gap: 1rem;
}

@media screen and (max-width: 768px) {
    .card-my-participation h4 {
        width: 75%;
    }
}
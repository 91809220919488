.image-uploader{
    margin-top: 1vh;
    /*display: flex;*/
    /*max-height: 35vh;*/
    /*overflow: hidden;*/
    padding: 1rem;
    width: 100%;
}

.image-uploader > .buttons{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    margin-left: 10px;
    gap: 5px;
}

.image-uploader > img{
    border: 1px solid var(--primary30);
    border-radius: 10px;
}

.image-uploader-no-image{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 2px solid var(--actionprimary50);
    border-radius: 10px;
    min-height: 20vh;
    /*max-height: 35vh;*/
    gap: 1rem;
    /*overflow: hidden;*/
}
.block-buttons{
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}
.image-uploader-no-image > .add-logo-button{
    width: 40%;
}

.image-uploader-no-image > .add-logo-button > div{
    padding: 0.8rem 1.3rem;
}

.image-uploader-no-image-text{
   text-align: center;
}

/* mobile view of dialog element */
:modal.cropper{
    overflow: hidden;
    border: none;
    border-radius: 20px;
    box-shadow: 0px 9px 14px rgba(18, 34, 54, 0.1);
    width: 100vw;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.4rem;
    overflow: scroll;
    margin: auto;
}

/*backdrop*/
:modal.cropper::backdrop{
    background-color: rgba(0, 0, 0, 0.5);
}

:modal.cropper > .cropper.form-modal{
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.cropper.buttons{
    display: flex;
    justify-content: center;
    gap: 1.6rem;
    width: 100%;
}

.cropper.buttons > button{
    width: 30%;
    justify-content: center;
}

.buttons-image-uploader{
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
    margin-left: 0.6rem;
    justify-content: flex-end;
}

.buttons-image-uploader > button{
    width: 100%;
}

.progress-bar-image-uploader{
    width: 100%;
    text-align: center;
}

.progress-bar-image-uploading{
    height: 2vh !important;
}

.uploaderImage {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover;

}

.uploaderImage img{
    width: 100%;
    height: 100%;

}

@media screen and (max-width: 768px){
    /* .establishment-image-big{
        height: 18.46vh;
        width: 18.46vh;
    } */

    .image-uploader-no-image > .add-logo-button{
        width: 60%;
        padding: 0 !important;
    }

    /* .establishment-image-big-rectangle{
        height: 11.5vh;
        width: 18.46vh;
    } */

    .image-uploader-no-image-text{
        margin: 0 2rem;
    }
}


@media screen and (min-width: 768px){
    :modal.cropper{
        width: 50%;
        overflow: scroll;
    }

    :modal.cropper::-webkit-scrollbar{
        display: none;
    }

    /* .establishment-image-big{
        height: 16.5vh;
        width: 16.5vh;
    }

    .establishment-image-big-rectangle{
        height: 16.5vh;
        width: 15vw;
    } */

    .progress-bar-image-uploader{
        width: 30%;
    }
}

.add-logo-button{
    white-space: nowrap;
}
.container-image{
    -webkit-flex-direction: column!important;
    display: flex!important;
    flex-direction: column!important;
}
@media screen and (max-width: 450px){
    .container-image img{
        width: 100% !important;
        max-height: 10rem!important;
    }
}
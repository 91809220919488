.basic-button-classic{
    background: var(--gradient);
    color: var(--primary00);
    border: 2px solid transparent;
    border-right-width: 0px;
    border-left-width: 0px;
    border-radius: 10px;
    display: flex;
    gap: 0.25rem;
}

.basic-button{
    display: flex;
}

.basic-button:hover{
    cursor: pointer;
}

.basic-button-classic:hover{
    background: linear-gradient(white, white) padding-box,
                var(--gradient) border-box;
    border-radius: 10px;
    border: 2px solid transparent;
}

.basic-button-classic:hover > p{
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.basic-button-classic:hover > svg > path{
    stroke: url(#add-hover-gradient);
}

.basic-button-submit{
    background: var(--gradient);
    color: var(--primary00);
    border: none;
    border-radius: 10px;
    gap: 0.25rem;
}

.basic-button-submit:hover{
    background: var(--error50);
    color: var(--primary00) !important;
}

.basic-button-cancel, .basic-button-delete, .basic-button-update{
    background: var(--actionprimary10);
    color: var(--actionprimary50);
    border: none;
    border-radius: 10px;
    gap: 0.25rem;
}

.basic-button:hover{
    color: var(--error50);
    transition: 0.5s;
}

.basic-button:hover > svg > path{
    transition: 0.5s;
}

.basic-button-delete > svg > path{
    fill: var(--actionprimary50);
}

.basic-button-delete:hover > svg > path{
    fill: var(--error50);
}

.basic-button-update > svg > path{
    stroke: var(--actionprimary50);
}

.basic-button-update:hover > svg > path{
    stroke: var(--error50);
}

.disabled-button {
    background-color: var(--primary30);
    user-select: none;
}

.disabled-button p {
    background-color: var(--primary30);
    user-select: none;
    color: var(--primary50);
}

.disabled-button > svg > path {
    fill: var(--primary50);
    stroke: var(--primary50)
}

.disabled-button:hover > svg > path {
    fill: var(--primary50);
    stroke: var(--primary50);
}
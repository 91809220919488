.button-base, .button-base-darken {
    border-radius: 40px;
    height: fit-content;
    width: fit-content;
    padding: 0.4rem 0.6rem;
    border: none;
    display: flex;
    gap: 0.5rem;
}

.button-base {
    background-color: var(--primary00);
    color: var(--primary90);
}
.button-base-darken {
    background-color: var(--primary90);
    color: var(--primary00);
}

.button-small-text {
    font-size: 0.7rem;
}
.resource{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: var(--primary10);
    padding: 1.25rem;
    border-radius: 10px;
}

.resource.resource-error{
    border: 2px solid red;
    /* border: 2px solid var(--error50); */
    transition: 1s ease-in-out;
}

.resource > div{
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.resource .resource-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    justify-content: right;
}

/* .resource-header .icon-trash{
    transform: scale(1.2);
} */

.resource .resource-form > form{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.resource .project-submission .project-submission-header{
    text-align: center;
}

.resource .project-submission .fixed-questions .project-submission-content{
    display: flex;
    flex-direction: column;
    gap: 0.31rem;
}

.resource .project-submission .fixed-questions{
    display: flex;
    flex-direction: column;
    gap: 0.62rem;
}

.resource .project-submission .fixed-questions .fixed-questions-list{
    background-color: var(--secondary10);
    padding: 1.2rem 0.62rem;
    border-radius: 10px;
}

@media screen and (min-width: 768px){
    .resource{
        /* width: 48%; */
        padding: 2rem;
    }
}

.IconButtonActive {
    pointer-events: none;
    cursor: pointer;
    background-color: var(--secondary50) !important;
    border-radius:12px !important;
    outline: auto !important;
    outline-color: var(--secondary50) !important;
}

.IconButton {
    background-color: var(--secondary50) !important;
    border-radius:12px !important;
    background-color: var(--actionprimary10) !important;
    outline: auto !important;
    outline-color: var(--secondary50) !important;
}
.IconButton:hover {
    background-color: var(--secondary50) !important;
    border-radius:12px !important;
    outline: auto !important;
    outline-color: var(--secondary50) !important;
}

.IconButtonActive.doc svg path {
    fill: var(--primary10) !important;
}

.IconButton.doc svg path {
    fill: var(--primary10) !important;
}

.IconButton.doc:hover svg path {
    fill: var(--primary10) !important;
}

.IconButton.doc svg path {
    fill: var(--secondary50) !important;
}

.IconButtonActive.link svg path {
    stroke: var(--primary10) !important;
}

.IconButton.link:hover svg path {
    stroke: var(--primary10) !important;
    fill: var(--secondary50) !important;
}

.IconButton.link svg path {
    stroke: var(--secondary50) !important;
    fill: var(--primary10) !important;
}
.upload-file {
    box-shadow: none !important;
    border: solid rgba(0, 0, 0, 0.23) 1px !important;
    border-radius: 12px !important;
}

.upload-file-error {
    box-shadow: none !important;
    border: solid #d32f2f 1px !important;
    border-radius: 12px !important;
}

.upload-file {
    background-color: var(--primary00) !important;
}

.upload-file-error {
    background-color: var(--primary00) !important;
}

.upload-file p {
    color: var(--primary90);
    font: inherit;
    letter-spacing: inherit;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    height: 1.4375em;
    margin: 0;
    display: block;
    min-width: 0;
    width: 100%;
    padding-right: 40px;
    padding-left: 8.5px;
    border-radius: 12px;
}

.upload-file-error p {
    color: #d32f2f;
    font: inherit;
    letter-spacing: inherit;
    padding: 4px 0 5px;
    border: 0;
    box-sizing: content-box;
    height: 1.4375em;
    margin: 0;
    display: block;
    min-width: 0;
    width: 100%;
    padding-right: 40px;
    padding-left: 8.5px;
    border-radius: 12px;
}

/* Quelqu'un a hardcode ce truc, décommentez s'il s'avère que c'est nécessaire
.css-1t1j96h-MuiPaper-root-MuiDialog-paper {
    box-shadow: 0 0 0 3px var(--error50) !important;
    border-radius: 12px !important;
}*/

/* .dialog {
    padding: 2rem !important;

} */
.dialog .confirm {
    color: var(--secondary10);
    background: linear-gradient(275.49deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%);
    border-radius: 10px;
    padding: 2px !important;
    /* border: 2px solid var(--secondary50); */
    width: 100%;
    height: 100%;
}

.dialog .confirm:hover {
    font-weight: 700;
    color: var(--secondary50);
    background: var(--secondary10);
    border-radius: 10px;
    padding: 0px !important;
    border: 2px solid var(--secondary50);
}

.dialog .cancel {
    padding: 14px 20px !important;
    font-weight: 700;
    color: var(--secondary50);
    background-color: var(--secondary10);
    border-radius: 10px;
    height: 100%;
    width: 100%;
    text-transform: none;
}

.dialog .cancel:hover {
    font-weight: 700;
    color: var(--error50);
    background-color: var(--secondary10);
    border-radius: 10px;
}

.dialog .change-icon {
    height: 50px !important;
    width: 50px !important;
}

.dialog .change-icon path {
    fill: var(--error50);
}

.resource-header .icon-trash {
    transform: scale(1.2);
    cursor: pointer;
    display: flex;
}
.resource-header .icon-trash:hover {
    transform: scale(1.3);
    cursor: pointer;
    display: flex;
}
.resource-header .error-file {
    border: solid red 1px !important;
    /* border-color: red !important; */
}
/* div globale de la page connexion */

.login-email-psw-svg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 84vh;
    min-height: fit-content;
    max-height: 100%;
    margin-top: 8rem;
}

.login-email-psw-svg .diamond-login {
    height: 90vh;
    position: fixed;
    right: 0;
    top: 10vh;
}

/* icon makee-diamond-login */

.makee-diamond-login {
    display: none;
}

/* partie à gauche de la page connexion
(seulement l'email et mdp) */

.login-email-psw {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    margin-left: 10vw;
}

.login-email-psw h1 {
    margin-bottom: 0.6rem;
}

.login-email-psw .account {
    margin-bottom: 2.2rem;
}

/* lien vers la page d'inscription */

.login-email-psw p .login-email-psw-link {
    color: #F86767;
    margin-left: .3rem;
}

/* formulaire de connexion */

.login-email-psw form {
    display: flex;
    flex-direction: column;
}

.login-email-psw form input {
    border-radius: 5px;
    font-weight: 400;
    color: #122236;
}

#password-helper-text {
    margin-bottom: 0;
}

/* lien vers le mot de passe oublié */

.login-email-psw-svg-link {
    margin-top: 0.9rem;
    color: var(--actionprimary50);
    text-align: center;
}

/* réglage des marges du formulaire */

.css-239a0v>:not(style) {
    margin-top: 0 !important;
    margin-bottom: 37.5px;
}
.login-email-psw .translate-icon{
    position: absolute;
    right: 2rem;
    top: 2rem;
}
/* image à droite (diamand) qui réduit en dessous de 1200px */

@media screen and (max-width: 1200px) {
    .login-email-psw-svg {
        margin-top: 6rem;
    }

    .login-email-psw-svg .diamond-login {
        width: 40%;
        z-index: 1;
    }

}

@media screen and (max-width: 992px) {
}

/* -------------------------- */

/* --------- tablet --------- */

/* -------------------------- */

@media screen and (max-width: 768px) {

    .login-email-psw-svg .diamond-login {
        display: none;
    }

    .login-email-psw-svg {
        margin-top: 3rem;
    }

    .login-email-psw {
        width: 100%;
        padding: 0 3rem;
        display: flex;
        justify-content: center;
        height: 100vh;
        text-align: center;
        margin-top: 2rem;
        z-index: 2;
    }
}

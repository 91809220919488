.forgot-password-reset-done body:has(.reset-done){
    height: 100%;
    /* overflow: hidden; */
}

.forgot-password-reset-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    margin-top: 10rem;
}

.forgot-password-reset-done .reset-done-header{
    display: flex;
    position: fixed;
    top: 0;
    margin: auto;
    margin-top: 5.46vh;
    width: 100%;
}

.forgot-password-reset-done .section-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.forgot-password-reset-done .makee-diamond-login-reset-password{
    margin-top: 1vh;
    transform: scale(1.2);
}

.forgot-password-reset-done .main{
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8vh;
    margin-top: 15vh;
}

.forgot-password-reset-done .diamond{
    position: fixed;
    bottom: 3rem;
}

.forgot-password-reset-done .auth-button > div{
    padding: 0.9rem 2.1rem !important;
}

.forgot-password-reset-done .auth-button{
    white-space: nowrap;
}

@media screen and (min-width: 768px){
    .forgot-password-reset-done .makee-diamond-login-reset-password{
        display: none;
    }

    .forgot-password-reset-done .go-back{
        display: none;
    }

    .forgot-password-reset-done .diamond{
        height: 16.25vh;
    }

}
.button-go-back{
    padding: 0;
}
.button-go-back,
.button-go-back svg path {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-family: var(--secondary-font);
    transition: 0.2s ease-in-out;
    color: var(--primary90);
}

.button-go-back:hover svg path,
.button-go-back:hover {
    cursor: pointer;
    color: var(--primary30);
    transition: 0.2s ease-in-out;
    stroke: var(--primary30);
}

@media screen and (max-width: 480px) {
    .button-go-back .delete-text-mobile {
        display: none;
    }
}
.add-text-mobile{
    color: var(--primary90);
    font-weight: 500;
    width: max-content;
}
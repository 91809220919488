.support-footer {
    width: 100%;
    height: 3rem;
    position: fixed;
    bottom: 0;
    background-color: #EDF1F7;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100;
}

.support-footer p {
    text-align: center;
}

.support-footer a {
    color: #122236;
}
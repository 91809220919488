/* global */
.admin-edit-challenge {
    width: 100%;
    top: 0;
    z-index: 2;
    background-color: var(--primary00);
    display: flex;
    gap: 1rem;
    justify-content: center;
    /* margin-top: 2vh; */
}

.admin-edit-challenge-outlet-nav-background {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    align-items: start;
    justify-content: center;
    top: 0;
    z-index: 100;
    position: relative;
    width: 90%;
    transition: all 0.2s ease-in-out;
}
.admin-edit-challenge-outlet-nav-background h1{
    text-align: center;
    width: 100%;
    transition: all 0.2s ease-in-out;
    margin-bottom: 2rem;
}
.scrolled {
    margin: 0!important;
    opacity: 0!important;
    height: 0px!important;
    /* height: 0px; */
    transition: all 0.2s ease-in-out 0.2s;
}
.scrolled h1 {
    margin: 0!important;
    opacity: 0!important;
    /* height: 0px; */
    transition: all 0.2s ease-in-out 0.1s;
}
.scrolled-nav{
    transition: all 0.2s ease-in-out 0.1s;
}
.scrolled-nav-items{

}
.admin-edit-challenge-outlet-nav-background .scrolled{

}
/*.nav-links-content {*/
/*    display: flex;*/
/*    z-index: 1;*/
/*}*/

.admin-edit-challenge-outlet-nav-background .div-go-back {
    min-width: 10rem;
    z-index: 2;
    border: 1px solid gold;
}

.admin-edit-challenge-outlet-nav {
    border-bottom: var(--border);
    display: flex;
    justify-content: center;
    z-index: 100;
    background-color: var(--primary00);
    width: 100%;
    padding-top: 3vh;
    padding-bottom: 3vh;
    position: sticky;
    top: 0;
    transition: all 0.2s ease-in-out;
}
.admin-edit-challenge-nav-items{
    display: flex;
    flex-wrap: wrap;
    transition: all 0.2s ease-in-out;
    width: 100%;
    justify-content: center;
    position: relative;
}

.admin-edit-challenge-nav-items .button-go-back{
    /*position: absolute;*/
    /*left: 0;*/
    /*top: 0;*/
    /*bottom: 0;*/
}
@media screen and (max-width: 1212px) {
    .admin-edit-challenge-nav-items .button-go-back{
        top: -7vh;
    }
}

.admin-edit-challenge .header {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.admin-edit-challenge .header .text-section {
    width: 100%;
}

.admin-edit-challenge .header .text-section .header{
    justify-content: space-between;
}

.admin-edit-challenge .header .buttons {
    display: flex;
    gap: 0.6rem;
}

.admin-edit-challenge .header .buttons .button-disabled {
    padding: 0.5rem 1rem;
}

.admin-edit-challenge .header .buttons .button-classic,
.admin-edit-challenge .header .buttons .primary-button-hover {
    padding: 0 !important;
}

.admin-edit-challenge .header .buttons .secondary-button,
.admin-edit-challenge .header .buttons .primary-button {
    padding: 0.5rem 1rem !important;
}

.admin-edit-challenge .header .buttons div {
    border-radius: 10px;
}

.admin-edit-challenge .header .buttons div p {
    font-size: 0.875rem;
    padding: 0;
}
.admin-edit-challenge-outlet-nav-background .button-go-back{
    position: relative;
    width: calc(100vw - 80%);
    text-align: start;
    z-index: 1;
}
.admin-edit-challenge-outlet-nav-background .button-go-back p{
    width: 100%;
    z-index: -1;
}
.menu-tabs-secondary{
    z-index: 99;
    position: relative;
}
.admin-edit-challenge .button-go-back {
    padding: 0;
}

.admin-edit-challenge .button-go-back p {
    font-size: 0.8rem;
}

.admin-edit-challenge-nav-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: var(--primary00);
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 6;
    border-top: var(--border);
}

.admin-edit-challenge-nav-bottom-content {
    width: 80%;
    height: 5vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* general */


.admin-edit-challenge-general {
    margin: auto;
}

.admin-edit-challenge-general .p-annonce {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 0.5rem;
}

.admin-edit-challenge-general .p-annonce svg path {
    stroke: var(--actionprimary50);
}

.admin-edit-challenge-general .annonce-text {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.admin-edit-challenge-general .annonce-text svg path {
    stroke: var(--actionprimary50)
}

.admin-edit-challenge-general .annonce-text-div .text {
    margin-bottom: 1rem;
}

.admin-edit-challenge-general .header-text {
    text-align: center;
}

.admin-edit-challenge-general .radio-buttons span:nth-child(1) {
    color: var(--actionprimary50);
    padding: 0.2rem;
}

.admin-edit-challenge-general .radio-buttons span {
    font-weight: 400;
    font-size: 0.9rem;
}

.admin-edit-challenge-general .header-text p {
    font-style: italic;
    color: var(--actionprimary50);
    font-weight: 600;
}

.admin-edit-challenge-general .text-champs-obligatoires {
    margin: 1.5rem 0;
}

.admin-edit-challenge-general .challenges-details-switch {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.admin-edit-challenge-general .challenges-details-switch .div-text {
    text-align: center;
}

.admin-edit-challenge-general .challenges-details-switch .div-text p {
    font-style: italic;
}

.admin-edit-challenge-general .challenges-details-switch .switch-boxes {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.admin-edit-challenge-general .challenges-details-switch .switch-boxes .text-section {
    width: 100%;
}

.admin-edit-challenge-general .challenges-details-switch .switch-boxes .header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 1rem;
}

.admin-edit-challenge-general .challenges-details-switch .switch-boxes .header .italic {
    font-style: italic;
}

.admin-edit-challenge-general .challenges-details-switch .switch-boxes .header .small-lead-text {
    width: 100%;
    text-align: start;
}

.admin-edit-challenge-general .challenges-details-switch .switch-boxes .header .active {
    font-style: italic;
    color: var(--actionprimary50)
}


.admin-edit-challenge-general .desktop {
    display: none;
}

.text-field-date {
    display: flex;
    gap: 1rem;
}

.questions {
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.partners {
    margin-bottom: 2rem;
}

.prizes {
    margin-bottom: 2rem;
}

.switch-rubriques {
    margin-bottom: 2rem;
    padding: 2rem;
}

@media screen and (max-width: 900px) {
    .switch-rubriques {
        margin-bottom: 0rem;
        padding-bottom: 0rem;
        padding-left: 0rem;
        padding-right: 0rem;

    }
}

.switch-rubriques.desktop .desktop .action-button {
    margin: auto;
    min-width: 15rem;
}

.switch-rubriques.desktop .desktop .title .text {
    max-width: 75%;
    margin: auto;
}

/* inscription */

.admin-edit-challenge-signup {
    width: 80%;
}

.admin-edit-challenge-signup .header-text {
    margin-bottom: 2rem;
}

.modalites-incription .header-text .h1 {
    margin: 0 !important;
}

.modalites-incription .header-text p {
    text-align: center;
    font-style: italic;
    color: var(--actionprimary50);
    font-weight: 600;
}

.admin-edit-challenge-signup .text-field-date {
    margin-bottom: 2rem;
    /* width: fit-content; */
    max-width: 100% !important;
    display: flex;
    flex-direction: row;
    overflow: hidden;
    justify-content: space-between;
    gap: 0;
}

/* .admin-edit-challenge-signup .text-field-date div {
    width: 95%;
} */


.admin-edit-challenge-signup .text-field-date .small-lead-text {
    margin-bottom: 0.5rem;
}

.admin-edit-challenge-signup .h1 {
    margin-top: 1rem;
    text-align: center;
    margin-bottom: 2rem;
}

.admin-edit-challenge-signup .header-text .small-lead-text {
    margin-bottom: 0.2rem;
}

.admin-edit-challenge-signup .div-alert {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-radius: 10px;
    padding: 0.5rem 1rem !important;
    border: 2px solid var(--error50);
    background-color: var(--error10);
    text-align: center;
    gap: 0.5rem;
    flex-direction: column;
    margin: 0 auto 3rem auto;
}

.admin-edit-challenge-signup .div-alert svg path {
    fill: var(--error50);
}

.max-participants {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.max-participants .small-lead-text {
    margin-bottom: 0.5rem;
}

.max-participants .compteur-max {
    margin-bottom: 2rem;
    display: flex;
    gap: 1rem;
    align-items: center;
}

.max-participants .compteur-max input {
    padding: 0.5rem;
}

.max-participants .compteur-max div p {
    font-size: 0.9rem !important;
    font-family: 'Barlow', sans-serif;
}

.admin-edit-challenge-signup .radio-buttons svg {
    fill: var(--actionprimary50)
}

.admin-edit-challenge-signup .radio-buttons span:nth-child(2) {
    font-size: 0.9rem;
    font-family: 'Barlow', sans-serif;
}

.admin-edit-challenge-signup .type-participation .small-lead-text {
    margin-bottom: 0.5rem;
    font-style: normal;
}

.nb-participants {
    display: flex;
    gap: 1rem;
    align-items: center;
    margin-left: 2rem;
    margin-top: -0.5rem;
}

.nb-participants input {
    padding: 0.5rem;
}

.nb-participants div p {
    font-size: 0.9rem !important;
    font-family: 'Barlow', sans-serif;
}

.admin-edit-challenge-signup .infos-participants {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    border-radius: 10px;
    padding: 1rem 2rem !important;
    background-color: var(--primary10);
    gap: 0.5rem;
    flex-direction: column;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.admin-edit-challenge-signup .infos-participants ul {
    width: 100%;
}

.admin-edit-challenge-signup .infos-participants svg path {
    fill: var(--primary70);
}

.admin-edit-challenge-signup .p-annonce {
    display: flex;
    align-items: start;
    justify-content: center;
    gap: 0.5rem;
    margin-top: 0.5rem;
}

.admin-edit-challenge-signup .p-annonce svg path {
    stroke: var(--actionprimary50)
}

.admin-edit-challenge-signup .annonce-text {
    display: flex;
    align-items: start;
    justify-content: space-between;
}

.admin-edit-challenge-signup .annonce-text svg path {
    stroke: var(--actionprimary50)
}

.admin-edit-challenge-signup .file-name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    margin-top: 0.5rem;
    border: 1px solid var(--primary50);
    border-radius: 5px;
    padding: 0.5rem 1rem;
}

.admin-edit-challenge-signup .file-name .buttons {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
}

.admin-edit-challenge-signup .file-name .buttons svg:nth-child(2) path {
    stroke: var(--primary50);
}

@media screen and (min-width: 480px) {

    .admin-edit-challenge-signup .div-alert {
        max-width: 400px;
    }

    .admin-edit-challenge-signup .modalites-incription {
        max-width: 500px;
        margin: 0 auto;
    }
}

.pop-up-save {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background-color: rgba(18, 34, 54, 0.10);
    z-index: 5;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pop-up-save .container {
    background-color: var(--primary00);
    padding: 2.5rem 2rem;
    box-shadow: var(--menu);
    display: flex;
    flex-direction: column;
    gap: 1rem;
    border-radius: 30px 30px 0 0;
    /* max-width: 40rem; */
    position: fixed;
    bottom: 0;
    width: 100vw;
}

.pop-up-save .container .h1 {
    margin-bottom: 1rem;
    text-align: center;
}
.scroll-rules {
    margin-bottom: 2vh;
}
.scroll-rules .header-text .text {
    color: var(--primary90);
    font-weight: 400;
    font-style: normal;
    margin-top: 0.5rem;
}

.scroll-rules .form {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}
.admin-edit-challenge-nav-bottom .buttons{
    display: flex;
    justify-content: center;
    gap: 1rem;
    width: 100%;
}
.buttons-mobile{
    display: flex;
    top: 3rem;
    right: 0;
    gap: 1vw;
    z-index: 999;
    position: absolute;
    align-items: center;
}
@media screen and (max-width: 320px) {
    .buttons-mobile {
        top: 4.5rem;
    }
}
.buttons-mobile button{
    width: 25vw;
    max-width: 8rem;
    height: 1rem;
}
.buttons-mobile-scrolled{
}
.admin-edit-challenge-nav-bottom .buttons button{
    max-width: 15rem;
}
.desktop-header{
    display: flex;
    flex-direction: column;
    text-align: center;
    width: 100%;
    align-items: end;
    margin-bottom: 1rem;
}
.mobile-header{
    gap: 1rem;
    margin-bottom: -1rem
}
@media screen and (max-width: 900px) {
    .admin-edit-challenge-nav-bottom .buttons{
        top: 0;
        z-index: 999;
    }
    .admin-edit-challenge-outlet-nav{
        padding-bottom: 0;
    }
}
@media screen and (min-width: 480px) {

    .admin-edit-challenge-nav-bottom {
        display: flex;
    }


    .pop-up-save .container {
        background-color: var(--primary00);
        padding: 2.5rem 2rem;
        box-shadow: var(--menu);
        display: flex;
        flex-direction: column;
        gap: 1rem;
        border-radius: 30px;
        max-width: 40rem;
        position: initial
    }

}

@media screen and (min-width: 700px) {
    /*Plus grand espace entre le titre et le reste de la page, sur PC*/



}

@media screen and (min-width: 1000px) {
    .admin-edit-challenge-signup {
        display: flex;
    }

    /* .gerer-inscription {
        width: 30%;
        max-width: none;
    } */
}

/* .all-switches {
    position: sticky;
    overflow-y: auto;
    top: 20vh;
    bottom: 0;
    margin: auto 0;
    height: 65vh;
} */

.challenges-details-switch .switch-boxes-list{
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

@media screen and (min-width: 720px) {
    .challenges-details-switch .switch-boxes-list{
        gap: .8rem;
    }
}

.switch-boxes-list .text.active {
    font-style: italic;
    color: var(--actionprimary50);
}

/* RESSOURCES */

.admin-edit-ressources {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--primary00);
    width: 80%;
}

.admin-edit-ressources .primary-button {
    margin-bottom: 1.5rem;
    max-width: 30rem;
    margin: 0 auto;
}

.admin-edit-ressources .header {
    margin-bottom: 2rem;
}

.admin-edit-ressources .all-ressources {
    padding: 0 1rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 9rem;
    padding-top: 15rem;
}
.all-ressources .ressource{
    display: flex;
    gap: 1rem;
}
@media screen and (min-width: 480px) {

    .all-ressources {
        width: 100vw;
        align-items: center;
        max-width: 100%;
    }

    .all-ressources .section-ressource-card {
        max-width: 30rem;
        width: 100%;
    }
}

@media screen and (min-width: 900px) {

    .all-ressources .section-ressource-card {
        max-width: none;
    }
}

.admin-edit-challenge-general .switch-rubriques.mobile {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
}

.admin-edit-challenge-general .switch-rubriques.mobile > div.mobile {
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.admin-edit-challenge-general .switch-rubriques.mobile > div.mobile .title {
    text-align: center;
    width: 90%;
    margin: auto;
}

.forgot-password body:has(.forgot-password) {
    height: 100%;
    overflow: hidden;
}

.forgot-password {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 70%;
    margin-top: 5rem;
}

.forgot-password .section-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.forgot-password .form {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8vh;
    margin-top: 4vh;
}

.forgot-password .form-header {
    display: flex;
    flex-direction: column;
    gap: 4.5vh;
}

.forgot-password .form-content {
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.forgot-password .diamond {
    position: fixed;
    bottom: 3rem;
}

.forgot-password .auth-button>div {
    padding: 0.9rem 2.1rem !important;
}

.forgot-password .auth-button {
    white-space: nowrap;
}

@media screen and (min-width: 900px) {
    .forgot-password .makee-diamond-login-reset-password {
        display: none;
    }
}

@media screen and (min-width: 768px) {
    .forgot-password {
        margin-top: 10rem;
    }

    .forgot-password .forgot-password-header {
        position: fixed;
        top: 11vh;
    }

    .forgot-password .form-header h1 {
        width: 60%;
        margin: auto;
    }

    .forgot-password .form-content {
        width: 60%;
        margin: auto;
    }

    .forgot-password .diamond {
        height: 16.25vh;
    }
}
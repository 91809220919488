.profile-school-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--primary10);
    padding: 0.8rem 2rem;
    border-radius: 10px;
}

.profile-school-infos a {
    text-decoration: none;
    color: var(--actionprimary50);
}

.private-infos {
    padding: 0 1.5rem;
}

.private-infos > div {
    margin-bottom: 1.5rem;
}

.private-infos .bold {
    font-weight: bold;
}

.private-infos .coordonnees {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.coordonnee {
    display: flex;
    gap: 1rem;
}

.coordonnee-left {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: .5rem;
}

.coordonnee-left svg {
    width: 2rem !important;
    min-width: 2rem !important;
    max-width: 2rem !important;
}

.coordonnee-left .text svg {
    /*margin-right: 1rem;*/
    /*width: 28rem;*/
}

@media screen and (max-width: 1275px) {
    /*.coordonnee-left .text{*/
    /*    width: 25rem;*/
    /*}*/
}

.private-infos .h2 {
    margin-bottom: 0.5rem;
}

.private-infos .coordonnees svg {
    width: 3rem;
}

.private-infos .coordonnees svg.icon-edit path {
    fill: var(--primary50);
}

.private-infos .coordonnees svg.icon-edit:hover {
    cursor: pointer;
}

.private-infos .coordonnees svg.icon-edit:hover path {
    fill: var(--actionprimary50);
    transition: all 0.3s ease-in-out;
}

.grey-background {
    background-color: var(--primary10);
    width: 100vw;
    height: 50vh;
    position: fixed;
    top: 0;
    opacity: 50%;
    z-index: -10;
    display: none;
}

.button-desktop {
    display: none;
}

.outlet-profile {
    padding: 0 1rem;
}

.creator-challenges {
    display: none !important;
}

.creator-challenges-desktop {
    display: flex !important;
    flex-direction: column;
    gap: 1rem;
    padding: 0 1rem;
    margin-top: 2rem;
}

.creator-challenges-desktop h2 {
    text-align: center;
}

.button-link {
    text-decoration: none;
    color: var(--actionprimary50);
    background-color: transparent;
    border: 1px solid transparent;
    transition: all 0.2s ease-in-out;
    width: fit-content;
    padding: 0;
    line-height: 17px;
}

.button-link:hover {
    border-bottom: 1px solid var(--actionprimary50);
}

.button-mobile {
    display: flex;
    gap: 0.9rem;
    flex-direction: column;
}

@media screen and (min-width: 481px) {
    .grey-background {
        display: block;
    }

    .button-mobile {
        display: none;
    }

    .button-desktop {
        display: flex;
        gap: 0.9rem;
        flex-direction: column;
    }

    .outlet-profile {
        margin-left: 16vw;
    }

    .creator-challenges {
        display: none;
    }

    .creator-challenges-desktop {
        margin-left: 18.5vw;
        width: calc(100% - 20.5vw) !important;
    }

}

@media screen and (min-width: 768px) {
    .creator-challenges-desktop {
        width: 60vw;
        max-width: 100%;
    }

    .creator-challenges {
        display: none;
    }

}

@media screen and (min-width: 1100px) {

    .outlet-profile {
        display: none;
    }

    .creator-challenges {
        display: flex !important;
        flex-direction: column;
        gap: 1rem;
    }
}


/* ------------------------------------------------------ */

.global-modification-buttons {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    bottom: 0;
    right: 0;
    width: 100%;
    padding: 1rem;
    gap: 1rem;
    background-color: var(--primary00);
    z-index: 1;
    box-shadow: var(--menu);
    border-radius: 20px 20px 0 0;
}

.MuiFormControl-root {
    width: 100%;
}

.global-modification-buttons button {
    width: 50%;
}

.form-inputs {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

.reset-mdp {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    border-radius: 5px;
    height: fit-content;
    background-color: var(--primary10);
    padding: 1rem 1.5rem;
    /* margin-bottom: 7rem; */
}

.reset-mdp .texts {
    display: flex;
    justify-content: space-between;
    gap: 0.5rem;
    text-decoration: none;
    text-align: center;
    border: none;
    width: 100%;
    border-radius: 5px;
}

.profile-update .reset-mdp .texts button {
    background-color: transparent;
    border: none;
    text-decoration: none;
}

.profile-update .bold {
    font-weight: bold;
}

.profile-update .inputs {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
}

.profile-update .block-inputs-job-comp-degree-school {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.profile-update .block-inputs-job-comp-degree-school .inputs{
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    width: 100%;
}
.profile-update .block-inputs-job-comp {
    display: flex;
    gap: 1rem
}

.profile-update .block-inputs-degree-school {
    display: flex;
    gap: 1rem;
}

.profile-update .container form {
    padding: 0;
}

.profile-update .mdp-close {
    transform: rotate(0deg);
}

.profile-update .mdp-open {
    transform: rotate(90deg);
}

.profile-update .change-mdp-buttons {
    display: flex;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    z-index: 1;
}

.profile-update .change-mdp-buttons button {
    width: 50%;
}

.profile-update {
    margin: auto;
    width: 100%;
    /* box-shadow: var(--menu); */
    padding: 1rem;
    margin-bottom: 2em;
    margin-top: 2em;
    border-radius: 20px;
}

@media screen and (min-width: 768px) {
    .profile-update {
        margin: auto;
        width: 80%;
        /* box-shadow: var(--menu); */
        padding: 1rem;
        margin-bottom: 2em;
        margin-top: 2em;
        border-radius: 20px;
    }

}

@media screen and (min-width: 481px) {

    .profile-update .global-modification-buttons {
        /* width: calc(100vw - 16.5vw); */
        margin-bottom: 0;
        justify-content: center;
        gap: 2rem;
    }

    .profile-update .global-modification-buttons .button {
        max-width: 20vw;
    }


    .reset-mdp {
        gap: 1rem;
        width: 100%;
        background-color: var(--primary10);
        padding: 1rem 1.5rem;
        margin-bottom: 0rem;
    }
}

/* div globale de la page connexion */

.login-email-psw-svg {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 85vh;
}
.login-email-psw-svg .diamond{
    height: 90vh;
    position: fixed;
    right: 0;
    top: 10vh;
}

/* icon makee-diamond-login */

.makee-diamond-login {
    display: none;
}

/* partie à gauche de la page connexion
(seulement l'email et mdp) */

.login-email-psw {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 40%;
    margin-left: 10vw;
    margin-bottom: 4.5rem;
}

.login-email-psw h1 {
    margin-bottom: 0.6rem;
}

.login-email-psw .account {
    margin-bottom: 2.2rem;
}

.login-email-psw-svg .instruction-password {
    margin-bottom: 1rem;
}

/* lien vers la page d'inscription */

p .login-email-psw-link {
    color: #F86767;
}

/* formulaire de connexion */

.login-email-psw form {
    display: flex;
    flex-direction: column;
}

.login-email-psw form input {
    border-radius: 5px;
    font-weight: 400;
    color: #122236;
}

#password-helper-text {
    margin-bottom: 0;
}

/* lien vers le mot de passe oublié */

.login-email-psw-svg-link {
    margin-top: 0.9rem;
    color: var(--actionprimary50);
    text-align: center;
}

/* réglage des marges du formulaire */

.login-email-psw .translate-icon{
    position: absolute;
    right: 2rem;
    top: 2rem;
}

/* image à droite (diamand) qui réduit en dessous de 1200px */

@media screen and (max-width: 1200px) {
    .diamond {
        width: 40%;
    }

}

/* -------------------------- */

/* --------- tablet --------- */

/* -------------------------- */
.container-form-signup-participant{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
@media screen and (max-width: 768px) {

    /* on enleve les marges du formulaire */
    .login-email-psw {
        width: 100%;
        padding: 0 3rem;
        display: flex;
        justify-content: center;
        height: 85vh;
        text-align: center;
        margin: 0 0 5rem;
    }

    /* on retire le diamand */
    .diamond {
        display: none;
    }

    /* on affiche le petit diamand au dessus du formulaire */
    .makee-diamond-login {
        display: block;
        margin: 0 auto 2rem auto;
    }
}

.admin-edit-challenge .sticky-desktop {
    width: 50%;
    position: fixed;
    transform: translateY(-50%);
    word-wrap: break-all;
    padding: 0px 32px 0px 0px;
}

.admin-edit-challenge .sticky-desktop-registration {
    width: 30%;
    position: fixed;
    transform: translateY(-50%);
    word-wrap: break-all;
    padding: 0px 32px 0px 0px;
    overflow-y: auto;
    max-height: 70vh;
}

.admin-edit-challenge .bloc-edit-general {
    padding-top: 2vh;
}
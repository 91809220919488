.content-accordion p{
    color: var(--Bleu90, #122236);
    font-family: Barlow;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.accordion-summary {
    background: var(--Bleu10, #EDF1F7);
}

#accordion .icon {
    transform: rotate(180deg);
}

#accordion h5 {
    margin: 0;
    font-weight: 700;
}
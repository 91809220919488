#section-faq {
    margin-top: 3rem;
    margin-bottom: 3rem;
    width: 100%;
}
#bloc-faq {
    /* padding-left: 1em;
    padding-right: 1em; */
    padding: 0 2rem;
    width: 100%;
}

#bloc-faq .content-faq {
    display: flex;
    flex-direction: column;
    align-items: center;
}

#bloc-faq .content-faq h1 {
    color: var(--Bleu90, #122236);
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.title-accordion h2 {
    color: var(--Bleu90, #122236);
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-align: left;
}
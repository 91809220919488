/* div globale qui englobe l'otp input */

.otp-group {
  display: flex;
  width: 100%;
  max-width: 360px;
  column-gap: 10px;
  overflow: hidden;
}

/* tout les inputs */

.otp-input {
  width: 100%;
  height: 60px;
  border: none;
  border-radius: 5px;
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 1;
  color: var(--primary90);
  background: transparent;
}

.otp-input::placeholder {
  color: var(--primary30);
}

/* input erreur de saisie */
.otp-input-error:focus {
  outline-color: var(--error50);
  border-radius: 15px;
}

.otp-error {
  color: var(--error50);
}

/* input actif (couleur rosée) */

.otp-input:focus {
  outline-color: transparent;
}

.otp-input-div-page{
  margin: auto;
  border: 3px solid var(--primary30);
  border-radius: 15px;
  padding: 4.4rem 0 3.1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
}

.otp-input-div-page-error{
  margin: auto;
  border-radius: 15px;
  padding: 4.4rem 0 3.1rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-bottom: 1rem;
  border: 3px solid var(--error50);
}
.admin-preview-challenge {
    width: 100%;
    /*border-radius: 10px;*/
    /*overflow: hidden;*/
}

.admin-preview-challenge-logout {
    width: 80%;
    margin-top: 8rem;
}

.admin-preview-challenge .header {
    padding: 0 2rem;
}

@media screen and (max-width: 600px) {
    .admin-preview-challenge-logout {
        padding: 0!important;
        width: 100% !important;
        margin: 0!important;
    }
}

.admin-preview-challenge .logo-challenge {
    width: 10rem;
    height: 10rem;
    padding-left: 10rem;
    padding-top: 10rem;
    border-radius: 10px;
    margin: -3rem auto 0;
    box-shadow: var(--pop-up);

}

.admin-preview-challenge .challenge-infos {
    margin-bottom: 4rem;
}

.admin-preview-challenge-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

.admin-preview-challenge .sticky-nav-bar-mobile {
    display: block;
    margin-bottom: 4rem;
    padding: 0 2rem;
}

.admin-preview-challenge .sticky-nav-bar-desktop {
    display: none;
}

.admin-preview-challenge .outlet-nav-bar-slider {
    margin-bottom: 2rem;
}

.admin-preview-challenge .organizer {
    display: flex;
    gap: 1rem;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    border: 2px solid var(--primary10);
    border-radius: 10px;
    width: fit-content;
}

.admin-preview-challenge .organizer .img-organizer {
    width: 2rem;
    height: 2rem;
}

.admin-preview-challenge button {
    /*max-width: 30rem;*/
}

.admin-preview-challenge .challenge-infos .state {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    background-color: var(--primary10);
    padding: 0.3rem 0.6rem;
    border-radius: 40px;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.admin-preview-challenge .challenge-infos h1 {
    text-align: center;
    margin-bottom: 0.5rem;
}

.admin-preview-challenge .challenge-infos .text {
    text-align: center;
}

.admin-preview-challenge .img-background {
    width: 100vw;
    height: 35vh;
    margin-bottom: 1.5rem;
    margin-top: -3rem;
    max-width: 100%;
}

.admin-preview-challenge .bold {
    font-weight: 600;
}

.admin-preview-challenge .modalites {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    margin-bottom: 1rem;
}

.admin-preview-challenge .modalites div {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
    min-height: 7rem;
    padding: 1rem;
    background-color: var(--primary10);
    border-radius: 10px;
}

.admin-preview-challenge .registrations {
    display: flex;
    flex-direction: row;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    background-color: var(--actionprimary10);
    padding: 0.5rem;
    border-radius: 10px;
}

.admin-preview-challenge .registrations svg path {
    stroke: var(--actionprimary50);
}

.admin-preview-challenge .go-back-tablet {
    display: none;
}

.admin-preview-challenge .go-back-title {
    display: none;
}


/* général */

.admin-preview-challenge-general {
    width: 100%;
}

.admin-preview-challenge-general .infos-generales {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* padding: 0 2rem; */
    margin: 0 auto;
}

.admin-preview-challenge-general .h1 {
    text-align: center;
}

/* .admin-preview-challenge-general .registrations-dates {
    padding: 0 2rem;
    margin-top: 2rem;
} */

.admin-preview-challenge-general .registrations-dates div {
    background-color: var(--primary10);
    border-radius: 10px;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    align-items: center;
}

.challenge-back-to-home {
    margin-bottom: 1.5rem;
}

.admin-preview-challenge-general .rules {
    margin-top: 2rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    /* padding: 0 2rem; */
}

.admin-preview-challenge-general .rules .rules-name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    background-color: var(--primary10);
    cursor: pointer;
    text-decoration: none;
    color: var(--primary90);
    margin-bottom: 2rem;
}

.admin-preview-challenge-general .rules .rules-name:hover {
    cursor: pointer;
}

.admin-preview-challenge-general .rules .rules-name svg path {
    fill: var(--primary50);
    transition: 0.3s ease;
}

.admin-preview-challenge-general .rules .rules-name:hover svg path {
    fill: var(--primary90);
    cursor: pointer;
    transition: 0.3s ease;
}

.admin-preview-challenge-general .list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding: 4rem 2rem;
}

.admin-preview-challenge-general .list .defi-list {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.admin-preview-challenge-general .list > .h1 {
    margin-bottom: 1rem;
}

.admin-preview-challenge-general .all-defis {
    background-color: var(--primary30);
    margin-top: 3rem;
}

.admin-preview-challenge-general .all-defis .defis {
    background-color: var(--primary00);
    padding: 2rem;
    border-radius: 20px;
    box-shadow: var(--pop-up);
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.admin-preview-challenge-general .all-defis .defis .h2 {
    text-align: center;
}

.admin-preview-challenge-general .all-defis .defis .img-defis {
    background: url('../../../../assets/defi-background.svg') center center/cover no-repeat;
    width: 8rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--actionprimary50);
    margin: 0 auto;
}

.admin-preview-challenge-general .all-defis .defis .img-defis .h1 {
    font-size: 3rem;
    margin-top: 0.5rem;
}

.admin-preview-challenge-general .all-defis .defis p {
    text-align: center;
}

.admin-preview-challenge-general .all-prizes {
    background-color: var(--primary00);
    margin: auto;
}

.w-65 {
    width: 65%;
}

.admin-preview-challenge-general .all-prizes .prizes {
    background-color: var(--primary10);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    overflow: hidden;
}

.admin-preview-challenge-general .all-prizes .prizes .top {
    display: flex;
    gap: 1rem;
    align-items: center;
    padding-right: 1rem;
}

.admin-preview-challenge-general .all-prizes .prizes .img-prizes {
    width: 7rem !important;
    height: 7rem !important;
    padding-left: 7rem;
    padding-top: 7rem;
}

.admin-preview-challenge-general .all-prizes .prizes .text {
    padding: 1rem;
    padding-top: 0;
    width: 100%;
}

.admin-preview-challenge-general .organizer-focus {
    background-color: var(--primary10);
}

.admin-preview-challenge-general .organizer-focus .block-img-organizer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

@media screen and (max-width: 350px) {
    .admin-preview-challenge-general .organizer-focus .img-organizer {
        width: 10rem !important;
        height: 10rem !important;
    }

    .admin-preview-challenge-general .organizer-focus .block-img-organizer {
        width: 100%;
        /*padding: 0;*/
    }

    .organizer-focus {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.admin-preview-challenge-general .organizer-focus .img-organizer {
    width: 16rem;
    /* max-width: 100%; */
    height: 16rem;
    margin-left: auto;
    margin-right: auto;
    border-radius: 20px;
}

.admin-preview-challenge-general .all-partenaires {
    background-color: var(--primary30);
}

.admin-preview-challenge-general .all-partenaires .partenaires-list {
    gap: 1rem;
    display: flex;
    flex-direction: column;
}

.admin-preview-challenge-general .all-partenaires .partenaires {
    background-color: var(--primary10);
    border-radius: 10px;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.admin-preview-challenge-general .all-partenaires .partenaires .img-partenaires {
    width: 7rem;
    height: 7rem;
    padding-left: 7rem;
    padding-top: 7rem;
}

.admin-preview-challenge-general .all-partenaires .partenaires .left {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 1rem;
    width: 100%;
}

.admin-preview-challenge-general .all-partenaires .partenaires .left a {
    text-decoration: none;
    color: var(--actionprimary50);
    display: flex;
    align-items: center;
    font-style: italic;
    gap: 1rem;
}


.logo-establishment {
    aspect-ratio: 1/1;
    width: 100%;
    object-fit: cover;
    border-radius: 12px;
    box-shadow: var(--pop-up);
}

.logo-establishment-mobile {
    /*margin-top: -7.5rem !important;*/
    margin-left: auto !important;
    margin-right: auto !important;
    margin-bottom: 2rem !important;
    width: 10rem !important;
    max-width: 10rem !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    z-index: 1 !important;
    padding: 0 !important;
}

#info .h1 {
    text-align: left;
    margin-top: 1rem;
}

@media screen and (max-width: 900px) {
    #info .h1 {
        text-align: center;
    }
}

.text-start {
    text-align: left;
}

.text-justify {
    text-align: justify;
}

.chip-organizer {
    margin-top: 1rem;
    border-radius: 12px !important;
}


.info-primary-bloc {
    text-align: center;
    background: var(--primary10);
    height: 100%;
    border-radius: 12px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
}

.block-logo-establishment {
    /*border: 1px solid red;*/
}

@media screen and (max-width: 900px) {
    .block-logo-establishment {
        display: flex;
        justify-content: center;
    }
}

.alignlast-center {
    text-align-last: center;
}

.chip-organizers {
    margin-top: 1rem;
    width: fit-content;
    /* border-radius: 12px !important; */
}

.chip-countMembers {
    color: var(--primary90, #122236);
    font-family: var(--font-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 20px 20px !important;
    border-radius: 10px !important;
    position: absolute;
    bottom: 1rem;
    right: 3rem;
}

.chip-countMembers-mobile-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
}

.chip-countMembers-mobile {
    color: var(--primary90, #122236);
    font-family: var(--font-secondary);
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 20px 20px !important;
    border-radius: 10px !important;
}

.live-challenge {
    background-color: var(--primary10);
    max-width: 100%;
    width: 100%;
    padding: 1rem;
    border-radius: 10px;
}

.live-challenge-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}

.live-challenge-description {
    font-size: 14px;
    text-align: left;
}

.live-challenge .add-button {
    margin-top: 1rem;
}

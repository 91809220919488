.challenge-card {
    width: 100%
}

.challenge-card .img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1200 / 350;
    border-radius: 10px 10px 0 0;
}

.challenge-card .small-lead-text {
    font-weight: 700;
    font-size: 1.125rem;
}

.challenge-card .participant-div {
    border-radius: 0;
}

.challenge-card .grey-img {
    filter: grayscale(100%);
    opacity: 50%;
}

.challenge-card .participants {
    background-color: var(--primary10);
    text-align: center;
    padding: 0.5rem;
    border-radius: 10px 10px 0 0;
}

.challenge-card .all-text {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 0.5rem;
    height: 100%;
    padding: 1rem 1rem 0 1rem;
    background-color: var(--primary10);
}

.challenge-card .radius-text {
    border-radius: 0 0 10px 10px;
}

.challenge-card .grey-all-text {
    color: var(--primary50);
    font-style: italic;
}

.challenge-card .all-text .organisateur {
    color: var(--primary50);
}

.challenge-card .all-text .bold {
    font-weight: 600;
}

.challenge-card .all-text .bottom-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
}

.challenge-card .all-text .bottom-infos a {
    text-decoration: none;
    color: var(--actionprimary50);
    font-size: 0.9rem;
    font-style: italic;
}

.challenge-card .all-text .bottom-infos a:hover {
    text-decoration: underline;
}

.challenge-card .registrations {
    padding: 1rem 0;
    border-top: 2px solid var(--primary00);

}

.challenge-card .registrations .registrations-dates {
    margin-top: 0.5rem;
    background-color: var(--primary00);
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 10px;
}

.challenge-card .see-challenge {
    background-color: var(--actionprimary10);
    width: 100%;
    border-radius: 0 0 10px 10px;
    padding: 1rem;
    text-align: center;
    color: var(--actionprimary50);
    transition: 0.2s ease;
}

.challenge-card .see-challenge:hover {
    background-color: var(--actionprimary50);
    color: var(--actionprimary10);
    transition: 0.2s ease;
    cursor: pointer;
}

/* ----------------------------------------------------- */

/* --------------- CHALLENGE CARD DESKTOP -------------- */

/* ----------------------------------------------------- */


.challenge-card-desktop {
    width: 100%;
    overflow: hidden;
    border-radius: 10px;
    display: flex;
    height: fit-content;
}

.challenge-card-desktop .div-left,
.challenge-card-desktop .div-right {
    width: 100%;
    max-width: 50%;
}

.challenge-card-desktop .div-right .top-infos {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5rem;
    padding-bottom: 1rem;
}

.challenge-card-desktop .div-right .bold {
    font-weight: 600;
    margin-bottom: 0.5rem;
}

.challenge-card-desktop .div-right .top-infos a {
    text-decoration: none;
    color: var(--actionprimary50);
    font-size: 0.9rem;
    font-style: italic;
}

.challenge-card-desktop .div-right .top-infos a:hover {
    text-decoration: underline;
}

.challenge-card-desktop .participations-registrations {
    display: flex;
    justify-content: space-between;
    padding-top: 1rem;
    border-top: 2px solid var(--primary00);
    padding-bottom: 2rem;
    flex-wrap: wrap;
    gap: 1rem;
}



.challenge-card-desktop .div-right {
    background-color: var(--primary30);
    padding: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.challenge-card-desktop .div-right .top{
    background-color: var(--primary30);
    padding: 2rem;
}

.challenge-card-desktop .img {
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1200/350;
    border-radius: 10px 0 0 0;
}

.challenge-card-desktop .grey-img {
    filter: grayscale(100%);
    opacity: 50%;
}

.challenge-card-desktop .participants {
    background-color: var(--primary10);
    text-align: center;
    padding: 0.5rem;
    border-radius: 10px 10px 0 0;
}

.challenge-card-desktop .all-text {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2.5rem;
    background-color: var(--primary10);
    height: 100%;
}

.challenge-card-desktop .grey-all-text {
    color: var(--primary50);
    font-style: italic;
}

.challenge-card-desktop .all-text .organisateur {
    color: var(--primary50);
}

.challenge-card-desktop .all-text .bold {
    font-weight: 600;
}

.challenge-card-desktop .registrations .registrations-dates {
    margin-top: 0.5rem;
    background-color: var(--primary00);
    width: fit-content;
    padding: 0.5rem 1rem;
    border-radius: 10px;
}

.challenge-card-desktop .see-challenge {
    background-color: var(--actionprimary10);
    width: 100%;
    padding: 1rem;
    text-align: center;
    color: var(--actionprimary50);
    transition: 0.2s ease;
}

.challenge-card-desktop .see-challenge:hover {
    background-color: var(--actionprimary50);
    color: var(--actionprimary10);
    transition: 0.2s ease;
    cursor: pointer;
}



/* ----------------------------------------- */

/* ---------- CHALLENGE CARD DONE ---------- */

/* ----------------------------------------- */

.challenge-card-done {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: var(--primary10);
    padding: 1rem 1.5rem;
}

.challenge-card-header-top-done {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.challenge-card-header-bottom-done {
    display: flex;
    justify-content: space-between;
    margin-top: 2rem;
    align-items: center;
}

.challenge-card-header-bottom-done .state-button {
    margin: auto 0;
}

/* Responsive */

@media screen and (max-width: 900px){
    .challenge-card-desktop{
        flex-direction: column;
    }

    .challenge-card-desktop .div-left,
    .challenge-card-desktop .div-right {
        max-width: 100%;
    }
}

.exceeding {
    word-wrap: break-word;
}


.wordbreak {
    word-wrap: break-word;
}

.mxw-90 {
    max-width: 90%;
}

.title-private{
    display: flex;
    flex-wrap: wrap;
}

.mt5p5 {
    margin-top: 5.5px;
    margin-left: 8px;
}
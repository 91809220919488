.password-reset body:has(.password-reset){
    height: 100%;
    overflow: hidden;
}

.password-reset {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    padding: 0 3rem;
    min-height: fit-content;
    margin-top: 4rem;
}

.password-reset .password-reset-header{
    display: flex;
    position: fixed;
    top: 0;
    margin: auto;
    margin-top: 5.46vh;
    width: 100%;
}

.password-reset .section-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.password-reset .form{
    display: flex;
    flex-direction: column;
    gap: 8vh;
    margin-top: 2vh;
    margin-bottom: 1vh;
}

.password-reset .form-header{
    display: flex;
    flex-direction: column;
    gap: 4.5vh;
}

.password-reset .form-content{
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.password-reset .makee-diamond-login-reset-password{
    margin-top: 3rem;
    margin-bottom: 2rem;
}

.password-reset .auth-button > div{
    padding: 0.9rem 2.1rem !important;
}

.password-reset .auth-button{
    white-space: nowrap;
}

@media screen and (min-width: 768px){

    .password-reset {
        margin-top: 8rem;
    }

    .password-reset .makee-diamond-login-reset-password{
        display: none;
    }

    .password-reset .password-reset-header{
        position: fixed;
        top: 11vh;
    }

    .password-reset .button-go-back{
        justify-content: flex-start !important;
        gap: 1vw;
    }

    .password-reset .go-back{
        margin-left: 5.5vh;
    }

    .password-reset .form-header h1{
        width: 60%;
        margin: auto;
    }

    .password-reset .form-header p{
        width: 50%;
        margin: auto;
    }

    .password-reset .form-content{
        width: 60%;
        margin: auto;
    }

    .password-reset .section-container{
        margin-top: 7vh;
        height: fit-content;
    }

    .password-reset .diamond{
        height: 16.25vh;
    }
}

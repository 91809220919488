#section_team_mangement {
    margin-top: 3vh;
    margin-bottom: 3vh;
    padding-left: 3em;
    padding-right: 3em;
    width: 100%;
}

#section_team_mangement h1 {
    color: var(--primary90);
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#section_team_mangement h5 {
    color: var(--primary90);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#section_team_mangement .icon-edit {
    width: 14px;
}

#section_team_mangement .icon-edit path {
    fill: #828D9B
}

#section_team_mangement .info-general-team {
    /*border: solid 1px #E5E5E5;*/
}

#section_team_mangement .info-general-team .info-registration {
    background-color: var(--primary10);
    padding: 1em;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

#section_team_mangement .info-general-team .info-registration p {
    background-color: var(--primary10);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#section_team_mangement .info-general-team .info-nb-member {
    padding: 1em;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    color: black;
    background-color: var(--primary10);
}

#section_team_mangement .info-general-team .info-nb-member svg path {
    fill: black;
}

#section_team_mangement .info-general-team .info-nb-member-warning {
    background-color: var(--error10);
    color: var(--error50);
}

#section_team_mangement .info-general-team .info-nb-member-warning svg path {
    fill: var(--error50);
}

#section_team_mangement .info-general-team .info-registration .icon-private path {
    fill: var(--primary90);
}

#section_team_mangement .info-general-team .info-registration .icon-public path {
    fill: var(--primary90);
}

#section_team_mangement .info-general-team .info-nb-member .nb-members {
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
}

#section_team_mangement .info-general-team .info-nb-member .warning-complete-team {
    font-family: Barlow;
    font-size: 14px;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}


#section_team_mangement .bloc-member-team .info {
    margin-top: 4em;
}

#section_team_mangement .bloc-member-team .info p {
    text-align: center;
    font-family: Barlow;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#section_team_mangement .bloc-member-team .info .warning {
    color: var(--error50);
}

#section_team_mangement .bloc-member-team .info .full-members {
    color: var(--validate50);
}

#section_team_mangement #manage-team {
    background-color: var(--primary10);
    border-radius: 10px;
    padding: 1.5em;
}

@media screen and (max-width: 900px) {
    #section_team_mangement #manage-team {
        background-color: transparent;
        padding: 0;
    }
}

#section_team_mangement #manage-team .warning-date-complete {
    padding: 1.5em;
    background-color: var(--error10);
    border-radius: 10px;
    border: solid var(--error50) 2px;
}

#section_team_mangement #manage-team .warning-date-complete .icon-alert path {
}

#section_team_mangement #manage-team .warning-date-complete {
    text-align: center;
}

#section_team_mangement #manage-team .warning-date-complete p {
    color: var(--primary90);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#section_team_mangement #manage-team .warning-date-complete .date {
    font-weight: 700;
}

#bloc-invit-mail {
    margin-top: 3em;
    margin-bottom: 3em;
    background-color: var(--primary10);
    border-radius: 10px;
    padding: 1.5em;
    word-break: break-word;
    margin-left: 0px;
    margin-right: 0px;
}

@media screen and (max-width: 900px) {
    #bloc-invit-mail {
        margin-left: 1.5em;
        margin-right: 1.5em;
    }

    #section_team_mangement {
        margin-top: 3vh;
        margin-bottom: 3vh;
        padding-left: 2em;
        padding-right: 2em;
        width: 100%;
    }
}

#bloc-invit-mail .title {
    color: var(--primary90);
    font-family: Barlow;
    font-style: normal;
    font-size: 17px;
    font-weight: 600;
    line-height: 20px;
}

#section_team_mangement .team-name-label {
    color: var(--primary90);
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
}


#section_team_mangement .icon-change path {
    fill: var(--actionprimary50);
}

#section_team_mangement .radio-label {
    color: var(--primary90);
    font-family: Barlow;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#section_team_mangement .check {
    font-weight: 700;
}

.team-full-text {
    text-align: center;
    margin-top: 1rem;
    font-style: italic;
}

.subtext-invite-mates {
    font-family: var(--font-secondary);
    font-weight: 600;
    color: var(--primary90)
}

.icon-cursor {
    cursor: pointer;
}

.label-textfields {
    font-weight: bold;
    margin-bottom: -.8rem;
    text-align: left;
}

.icon-search-close {
    width: 1.2rem;
    height: 1.2rem;
}

.fonctions-collabo {
    /*border: 1px solid red;*/
}

.fonctions-collabo-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 2rem;
    margin-top: 2rem;
}

.fonctions-collabo-content {
    background-color: var(--primary10);
    border-radius: 10px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fonctions-collabo-content-resource {
    margin-top: 1rem;
    width: 90%;
    padding: 1rem;
}

.fonctions-collabo-content-resource-title {
    font-size: 18px;
    font-weight: bold;
}

.fonctions-collabo-content-resource-description {

}

.fonctions-collabo-content-resource .button-goto {
    margin-top: 1rem;
}

.chip-customize {
    background-color: var(--primary10);
    display: flex;
    align-items: center;
    gap: 1rem;
    border-radius: 20px;
    padding: 1rem;
    width: fit-content;
}
.chip-customize img{
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
}
.chip-customize-text{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 5px;
}
.chip-customize-text .email {
    font-style: italic;
    display: flex;
    gap: .5rem;
}
.chip-customize-text .email .icon-email svg path{
    fill: rgb(130, 141, 155);
}

.send-message-whole-team{
    margin-top: 1rem;
    width: 30rem;
}
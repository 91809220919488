.container-app-logout #project-page {
    margin-top: 8rem;
}

#project-page #team-detail {
    margin-top: 1em;
    background-color: var(--primary10);
}

.w-90 {
    width: 90%;
}

#project-page #team-detail .project-identity {
    border-bottom: solid 1px var(--primary90);
    border-right:0;
    border-right: solid 1px var(--primary90);
    border-bottom: 0;
    word-break: break-word;
    padding-right: 1.5rem;
}

#project-page #team-detail .project-identity-main {
    padding-left: 1.5rem;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
    padding-right: 0rem;
}

#project-page .team {
    padding: 1.5rem;
    word-break: break-word;
}

#project-page .action-button.disabled {
    cursor: none;
    pointer-events: none;
}

#project-page .team svg{
    width: 8px;
}


#project-page h5 {
    color: var(--primary90);
    font-family: Barlow;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

#project-page #team-detail .project-identity h3 {
    color: var(--primary90);
    font-family: Ubuntu;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#project-page #longdesc {
    background-color: var(--primary90);
    padding-right: 2.5rem;
    padding-left: 2.5rem;
    padding-bottom: 2rem;
    padding-top: 2rem;
    margin-top: 1em;;
}

#project-page #longdesc h5 {
    color: var(--primary00);
    font-family: Barlow;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

#project-page #longdesc p {
    color: var(--primary00);
}

#project-page .portfolio-empty {
    width: 50%;
    margin: auto;
}

@media screen and (max-width: 900px) {
    #project-page .portfolio-empty {
        width: 80%;
    }

    #project-page #team-detail .project-identity {
        border-bottom: solid 1px var(--primary90);
        border-right:0;
        word-break: break-word;
        padding-right: 0rem;
        padding-bottom: 1.5rem;
    }

    #project-page #team-detail .project-identity-main {
        padding-left: 1.5rem;
        padding-top: 1.5rem;
        padding-bottom: 0rem;
        padding-right: 1.5rem;
    }
    #project-page #longdesc {
        background-color: var(--primary90);
        padding-right: 1rem;
        padding-left: 1rem;
        padding-bottom: 0.75rem;
        padding-top: 0.75rem;
    }

    #project-page #steps {
        padding-left: 2.5em;
        padding-right: 2.5em;
    }
}

#project-page #steps {
    padding-left: 1em;
    padding-right: 1em;
}

#project-page #steps .step-detail {
    margin-top: 3rem;
}

#project-page #steps .step-detail h4{
    color: var(--primary90);
    font-family: Ubuntu;
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#project-page #steps .step-detail .inline{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--primary90);
    font-family: Barlow;
    font-size: 1rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#project-page #steps .step-detail .deliverable{
    margin-top: 1em;
    border-radius: 8px;
    background-color: var(--primary10);
}

#project-page .bloc-info-project-challenge {
    padding-top: 2em!important;
    padding-bottom: 2em!important;
}

#project-page .number-step span{
    color: var(--primary90);
    font-family: Ubuntu;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}
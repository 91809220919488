.profile-merge-div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    text-align: center;
    padding: 0 4rem;
}

.profile-merge-div .no-account {
    margin-bottom: 3rem;
}

.link-signup-create {
    color: var(--actionprimary50);
}

.profile-merge-div .buttons {
    display: flex;
    justify-content: space-between;
    margin-top: 5.3rem;
    gap: 1rem;
}

.profile-merge {
    display: flex;
    height: 100vh;
    text-align: center;
    width: 90%;
    gap: 2rem;
    justify-content: center;
    margin: auto;
}

.profile-merge-search {
    width: 50%;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    padding: 0 4rem;
}

.profile-merge-search h2 {
    margin-bottom: 1.2rem;
}

.div-profile-merge-search {
    margin-bottom: 2.5rem;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.profile-merge-search .buttons {
    display: flex;
    gap: 1rem;
}

.line {
    height: 70vh;
    border-left: 2px solid var(--primary30);
    margin: 0;
    margin-top: 10rem;
}

.profile-merge .makee-diamond-login {
    display: none;
}

/*@media screen and (min-width: 1200px) {*/
/*    .profile-merge {*/
/*        width: 50vw;*/
/*    }*/
/*}*/

@media screen and (max-width: 900px) {

    .profile-merge-div {
        width: 100%;
        margin-bottom: 3rem;
        height: auto;
    }

    .profile-merge-div .no-account {
        margin-bottom: 3rem;
    }

    .profile-merge-div .buttons {
        margin-top: 3rem;
    }

    .profile-merge .line{
        display: none;
    }

    .profile-merge-search {
        width: 100%;
        margin: 0;
        margin-bottom: 3rem;
    }

    .profile-merge {
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: auto;
        max-width: 600px;
        padding: 0 2rem;
    }

    .profile-merge-div, .profile-merge-search {
        padding: 0;
    }

    .profile-merge .makee-diamond-login {
        margin: 3rem auto 2rem auto;
        display: block;
    }

    div.loginHeader2 {
        display: none;
    }
}

/*@media screen and (max-width: 900px) {*/
/*    .profile-merge .makee-diamond-login {*/
/*        margin: 3rem auto 2rem auto;*/
/*        display: block;*/
/*    }*/
/*}*/

/*@media screen and (max-width: 1200px) {*/
/*    .profile-merge-div, .profile-merge-search {*/
/*        padding: 0 1rem;*/
/*    }*/
/*}*/

@media screen and (max-width: 768px) {
}

#actioncard a {
    color: var(--Saumon50, #F86767);
    text-align: right;
    font-family: Barlow;
    font-style: italic;
    font-weight: 500;
    line-height: normal;
}

.square-img {
    aspect-ratio: 1/1;
}

.price-img-mobile {
    height: 7rem !important;
    margin: auto;
}

.price-img-desktop {
    height: 12rem !important;
    margin: auto;
}

.card-price {
    background: var(--Saumon50);
}

.defis {
    word-wrap: break-word;
    margin-bottom: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.defis .title {
    align-items: center;
}

.admin-preview-challenge-general .h1 {
    color: var(--Bleu90, #122236);
    text-align: center;

    /* Makee-phone/H1-bold-24 */
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.admin-preview-challenge-general .salmon {
    color: var(--Saumon50, #F86767);
}

.px-2em {
    padding-left: 2em;
    padding-right: 2em;
}

.px-10em {
    padding-left: 10em;
    padding-right: 10em;
}

.px-12em {
    padding-left: 12em;
    padding-right: 12em;
}

.mb-2em {
    margin-bottom: 2em;
}

.organizer-name {
    color: var(--Bleu90, #122236);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    line-height: normal;
    font-size: 1.5rem;
    font-weight: 700;
}

#partenaires-list .h2 {
    font-family: var(--font-secondary) !important;
    font-weight: 700 !important;
    font-size: 1.125rem !important;
    margin: 0 !important;
    padding: 0 !important;
}

#actioncard p {
    word-break: break-word;
}

#all-prizes p {
    word-break: break-word;
}

.infos-generales {
    text-align: center;
}

.registrations-dates {
    margin-bottom: 1rem;
}

.title-eligibilities {
    font-size: 16px;
    text-align: left;
}
.bloc-eligibilities {
    margin-bottom: 2rem;
}
.eligibility {
    text-align: left;
    font-size: 14px;
    list-style-type: disc;
}

.no-eligibilities {
    text-align: left;
    font-size: 14px;
    font-style: italic;
}

.block-eligibilities {
    margin-bottom: 2rem;
}
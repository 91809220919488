.admin-update-establishment{
    padding: 3.8rem 2.5rem;
}

.form-content{
    display: flex;
    flex-direction: column;
    gap: 1.25rem;
}

.footer-update-establishment{
    bottom: 0;
    left: 0;
    position: fixed;
    z-index: 100000;
    display: flex;
    justify-content: space-between;
    gap: 0.6rem;
    width: 100%;
    background-color: var(--primary00);
    box-shadow: 0px -5px 14px rgba(18, 34, 54, 0.1);
    border-radius: 20px 20px 0px 0px;
    padding: .75rem;
}

.footer-update-establishment > button{
    width: 100%;
    display: flex;
    justify-content: center;
}
.project-card {
    display: flex;
    padding: 20px;
    justify-content: center;
    gap: 22px;
    flex-shrink: 0;
    background-color: var(--primary10);
    border-radius: 10px;
    box-shadow: 0px -5px 14px var(--primary10);
    height: 100%;
}
.error {
    border: 1px solid var(--error50) !important;
}
.project-card-content {
    width: 90%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.project-card-content h2 {
    color: var(--primary90);
    font-family: var(--font-primary);
    font-size: 24px;
    font-weight: 700;
    text-align: center;
}
.project-card-content hr{
    width: 50%;
    border: 1px solid var(--actionprimary50);
    opacity: 1;
    margin: 0 auto;
}
.project-card-header{
}
h5 {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 500;
}

h3 {
    color: var(--primary90);
    font-family: var(--font-primary);
    font-size: 24px;
    font-weight: 700;
}
.project-card-header p{
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 16px;
    font-weight: 500;
}
.project-card-challenge{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.chip-see-challenge {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.chip-status-challenge{
    background-color: white!important;
    width: fit-content;
}
.project-card-challenge img{
    width: 100%;
    object-fit: contain;
    aspect-ratio: 1200/350;
    border-radius: 10px;
}
.project-card-participation{
}
.type-participation{
    margin-top: 1rem;
    margin-bottom: 1rem;
}
.type-participation p{
    text-align: center;
    font-family: var(--font-secondary);
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
}
.type-participation .nbMembers{
    font-weight: 250;
}
.position-team{
    color: var(--primary90) !important;
    font-family: var(--font-secondary) !important;
    font-size: 14px!important;
    font-weight: 400!important;
}
.my-participations{
    display: flex;
    flex-direction: column;
    gap: 1rem;
}
.status-project{
    position: absolute;
    right: 1rem;
    top: 1rem;
}
.status-project p{
    font-family: var(--font-secondary) !important;
    font-size: 14px!important;
    font-style: italic!important;
    font-weight: 600!important;
}
.status-project-good{
    color: var(--validate50) !important;
}
.status-project-bad{
    color: var(--error50) !important;
}
.status-project-draft{
    color: var(--primary50) !important;
}
.to-complete{
    color: var(--error50);
    font-family: var(--font-secondary);
    font-size: 14px;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: .5rem;
}
.to-complete svg path{
    fill: var(--error50);
}

@media screen and (max-width: 500px) {
    .project-card{
        width: 95%;
    }
}

.participation-mode .small-lead-text {
    margin-bottom: 0.5rem;
}
.section-admin-invitation-profile {
    width: 100vw;
    height: 84vh;
    display: flex;
    min-height: fit-content;
    justify-content: center;
    text-align: center;
    margin-top: 8rem;
}

.div-admin-invitation-profile {
    width: 36vw;
}

.go-back {
    margin: 0 4rem;
    position: fixed;
}

.div-admin-invitation-profile h1,
.div-admin-invitation-profile h2 {
    margin-bottom: 0.6rem;
}

.div-admin-invitation-profile .new-profile {
    margin-bottom: 5.3rem;
}

.div-admin-invitation-profile .interface-access {
    margin-bottom: 2.5rem;
}

.div-admin-invitation-profile .horizontal-line-ou {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2.5rem 0;
    color: var(--primary50);
}

.div-admin-invitation-profile .horizontal-line-ou p {
    margin: 0 1.2rem;
}

.div-admin-invitation-profile .horizontal-line-ou .horizontal-line {
    width: 100%;
    border-bottom: 1.5px solid var(--primary50);
}

.section-admin-invitation-profile .makee-diamond-login {
    display: none;
}

@media screen and (max-width: 992px) {
    .div-admin-invitation-profile {
        width: 60%;
        margin-bottom: 3rem;
    }

    .section-admin-invitation-profile {
        margin-top: 6rem;
    }
}

@media screen and (max-width: 768px) {
    .div-admin-invitation-profile {
        width: 80%;
        margin-bottom: 3rem;
    }

    .section-admin-invitation-profile {
        align-items: start;
        margin-top: 0;
    }

    .section-admin-invitation-profile .makee-diamond-login {
        display: block;
        margin: 3rem auto 2rem auto;
    }
}
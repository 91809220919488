.admin-challenges {
    width: 80%;
    margin-top: 3vh;
    margin-bottom: 3vh;
}

.admin-challenges .admin-challenges-noChallengesPresentation {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-bottom: 2rem;
}

.error-message {
    color: red;
}
 
.admin-challenges-list {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.admin-challenges-list .admin-challenges-list-empty {
    width: 60%;
    margin: auto;
}



.admin-challenges-list .challenges .challenges-flex>div{
    margin-top: 1rem;
}

.admin-challenges .h1 {
    text-align: center;
    margin-bottom: 2rem;
    margin-top: 4rem;
}

.admin-challenges .button-create {
    margin: auto;
    margin-bottom: 2rem;
}

@media screen and (min-width: 900px) {
    .challenges-flex {
        display: flex;
        grid-template-columns: repeat(3, 1fr);
        gap: 1rem;
        justify-content: center;
    }

    .admin-challenges-list .admin-challenges-list-empty {
        width: 40%;
    }

    .admin-challenges .button-create {
        width: 50%;
    }

    .challenges-flex>div {
        margin-top: 0;
    }

    .admin-challenges .h1 {
        margin-top: 5rem;
    }
}

.nb-run-challenge {
    color: #000;
    text-align: center;
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
}

.nbchallenges {
    color: #000;
    text-align: center;
    font-family: Barlow;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.bloc-limit-challenge {
    border-radius: 10px;
    border: 2px solid var(--Magenta50, #B00362);
    background: var(--Magenta10, #F8E2EE);
    text-align: center;
    padding-left: 2em;
    padding-right: 2em;
    padding-bottom: 1em;
    padding-top: 1em;
    margin: auto;
}

.bloc-limit-challenge p {
    margin-top: 0.5em;
    color: var(--Bleu90, #122236);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bloc-limit-challenge span button {
    color: var(--Bleu90, #122236);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    background: none;
    border: none;
}

.w-33 {
    width: 33%!important;
}
.w-50 {
    width: 50%!important;
}

.w-100 {
    width: 100%!important;
}
.justify-content-center{
    justify-content: center;
}
.spacing-2 {
    gap: 2em;
    margin-top: 1em;
    margin-bottom: 1em;
}
.wrap {
    flex-wrap: wrap!important;
}
.criteria{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    width: 90%;
    margin-top: 2rem;
}

.criteria .header{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.3rem;
    margin-bottom: 1rem;
    text-align: center;
}

.criteria .footer{
    width: 90%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
}

.criteria .footer .total-points{
    width: 33%;
    margin-bottom: 1rem;
}

.criteria .footer .total-points .points{
    border-radius: 10px;
    border: 1px solid var(--primary50);
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
}

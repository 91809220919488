.menu-tabs {

}
.menu-tabs-mobile{
    background-color: transparent!important;
    margin-bottom: 3vh;
}
.menu-tabs-primary{
    display: flex;
    border-radius: 10px;
    color: var(--primary90);
    font-size: 14px;
    font-weight: 700;
    font-family: var(--font-primary);
    background-color: var(--primary10);
}
.menu-tabs-secondary {
    background-color: var(--secondary10) !important;
    padding: 5px;
    width: 100%;
    overflow-x: auto;
    display: flex;
    border-radius: 10px;
    justify-content: space-between;
    gap: 2vw;
}

@media screen and (max-width: 900px) {
    .menu-tabs-secondary {
        gap: 0vw;
    }
    
}
/* Variant Primary */
.menu-tabs-primary .menu-tabs-button {
    padding: 13px 25px;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    border-right: 3px solid white;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}
.menu-tabs-primary .menu-tabs-button:hover{
    background-color: var(--actionprimary50);
    color: white;
}
.menu-tabs-primary .menu-tabs-button:nth-child(1){
    border-radius: 10px 0 0 10px;
}
.menu-tabs-primary .menu-tabs-button-active{
    background-color: var(--actionprimary50);
    color: white;
}
/* Variant Secondary */
.menu-tabs-secondary .menu-tabs-button {
    text-align: center;
    padding: 8px 3%;
    width: 11rem;
    max-width: 11rem;
    font-size: 14px;
    font-family: var(--font-primary);
    font-weight: 700;
    cursor: pointer;
    border-radius: 10px;
    color: var(--secondary50);
    transition: all 0.2s ease-in-out;
}
.menu-tabs-secondary .menu-tabs-button:hover{
    background-color: var(--secondary50);
    color: white;
}
.menu-tabs-secondary .menu-tabs-button-active{
    background-color: var(--secondary50);
    color: white;
}

/* Responsive mobile */
@media screen and (max-width: 900px) {
    .menu-tabs-secondary .menu-tabs-button{
        font-size: 12px;
    }
}
@media screen and (max-width: 600px) {
    .menu-tabs-secondary .menu-tabs-button{
        font-size: 2.5vw;
    }
}
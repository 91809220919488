header {
    width: 100%;
    height: 15vh;
    background-color: var(--primary00);
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
    border-bottom: var(--border);
}
.header-edit{
    display: none;
}
@media screen and (max-width: 900px) {
    .header-edit {
        display: none;
    }
}

.delete-border {
    border: none;
}

header h1 {
    text-align: center;
}

header .titleEdit {
    width: 100%;
}

.block-header {
    height: 15vh;
    margin-left: 16.4vw;
    display: flex;
    justify-content: center;
    z-index: 999;
}
.block-header .cancel-preview-button-mobile{
    position: absolute;
    bottom: 0;
    top: 0;
    height: 100%;
    left: 2rem;
    display: flex;
    align-items: center;
    width: 2rem;
}
.content-header {
    width: 80%;
    height: 15vh;
    display: flex;
    align-items: center;
    z-index: 10;
}
.content-header h1{
    display: flex;
    align-items: center;
    position: relative;
}
.profile-picture {
    width: 5rem;
    height: 5rem;
    object-fit: cover;
    border-radius: 50%;
    position: absolute;
    right: 3rem;
    cursor: pointer;
    z-index: 2;
    padding: 0.6rem;
    border: none;
    background-color: rgba(0, 0, 0, 0.0);
    transition: all 0.3s ease-in-out;
}

.profile-picture:hover {
    background-color: rgba(0, 0, 0, 0.05);
}

.profile-picture img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    object-fit: cover;
}


.profile-picture-border {
    background-color: black;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    position: absolute;
    right: 42px;
    opacity: 0.05;
    transform: scale(0);
    transition: all 0.3s ease-in-out;
    z-index: 1;
}

.profile-picture-border-animated {
    transform: scale(1);
}
.profile-picture-skeleton-hide{
   display: none;
}
.menu-profile {
    width: 20rem;
    height: 100vh;
    position: fixed;
    right: 0;
    transform: translateX(20rem);
    top: 0;
    background-color: var(--primary30);
    opacity: 0;
    z-index: 0;
    transition: all 0.25s cubic-bezier(0.42, 0, 0, 0.98);;
    border-radius: 30px 0 0 30px;
}

.menu-profile-visible {
    right: 0;
    transform: translateX(0);
    opacity: 1;
    z-index: 999;
}

.overlay {
    width: 100%;
    height: 100vh;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.2);
    top: 0;
    left: 100%;
    right: 0;
    bottom: 0;
    display: none;
    z-index: 0;
}

.overlay-visible {
    display: block;
    z-index: 102;
    left: 0;
}

.header-up {
    z-index: 102;
    margin-left: 16.4vw;
}

.block-header-up {
    margin-left: -1.8%;
}

.block-header-no-nav-bar {
    margin: 0;
}

.menu-profile-header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 5vh;
}

.menu-profile-logo {
    width: 8rem;
}

.menu-profile-profile-picture {
    width: 7rem;
    height: 7rem;
    border-radius: 50%;
    object-fit: cover;
}

.menu-profile-name {
    display: flex;
    align-items: center;
    flex-direction: column;
}

.menu-profile-name p {
    text-align: center;
    font-weight: bold;
    font-size: 24px;
    margin-block-start: 0;
    margin-block-end: 0;
    line-height: 25px;
    color: var(--primary90)
}

.menu-profile-icon-close {
    position: absolute;
    top: 2rem;
    left: 2rem;
    cursor: pointer;
    transform: scale(1.2);
}

.menu-profile-my-profile a {
    color: var(--actionprimary50);
    text-decoration: none;
    border-bottom: 1px solid var(--actionprimary50);
    font-size: 15px;
    font-weight: 500;

}

.menu-profile-my-profile {
    margin-top: 2vh;
}

.menu-profile-block-identity {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    margin-top: 6vh;
}

.menu-profile-others-accounts {
    margin-top: 5vh;
}

.menu-profile-footer {
    position: absolute;
    bottom: 3vh;
    left: 0;
    right: 0;
    width: 100%;
    height: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 15px;
}

.contact-support {
    display: none;
}

.menu-profile-footer svg {
    width: 29px;
    height: 29px;
}

.menu-profile-footer svg path {
    fill: var(--primary90);
}

.menu-profile-footer p {
    color: var(--primary90);
    font-size: 16px;
    font-weight: 400;
}

.menu-profile-footer button {
    background-color: transparent;
    border: none;
    padding: 0;
}

.go-back {
    margin: 0;
    position: absolute;
    left: -2rem;
}

.delete-go-back {
    display: none !important;
}
.delete-profile-picture {
    display: none;
}

/* Responsive */

@media screen and (max-width: 900px) {
    header {
        height: initial;
        border-bottom: none;
    }
    .go-back{
        left: 1rem;
    }
    .delete-profile-picture-mobile {
        display: none;
    }

    .block-header {
        margin-left: 0;
        background-color: var(--primary10);
        height: 10vh;
        display: flex;
        align-items: center;
    }

    .content-header {
        justify-content: center;
        width: 50%;
        max-height: 100%;
    }
    .content-header h1{
        position: initial;
    }
    .profile-picture {
        left: 1rem;
        width: 4rem;
        height: 4rem;
    }

    .header-up {
        margin-left: 0;
    }

    .menu-profile {
        left: -40rem;
        bottom: 0;
        border-radius: 0 30px 30px 0;
    }

    .menu-profile-visible {
        left: 0;
    }

    .contact-support {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #EDF1F7;
        padding: 1rem 1.5rem;
        border-radius: 10px;
        margin: 2rem 1.5rem 0;
    }

    .contact-support p {
        font-size: 1.1rem;
        text-align: center;
    }

    .contact-support a {
        font-size: 0.9rem;
        color: var(--primary90);
        padding-top: 1rem;
    }

    .contact-support svg {
        margin-right: 0.2rem;
    }


    /*Titre du header ("Accueil, Challenges, Config établissement...") */
    header .headerTitle {
        max-width: 100%;
        max-height: 100%;
        font-size: min(6vmax, 1.25rem);
    }

}

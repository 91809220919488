/* il y a deux types de cartes écoles */

/* ce premier variant possède le background blanc */

.school-card {
    background-color: var(--primary00);
    width: 100%;
    display: flex;
    border-radius: 10px;
    align-items: center;
    margin-top: 1.5rem;
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    font-size: 1.7rem;
}

/* ce deuxième variant possède le background gris */

.school-card-grey {
    background-color: var(--primary10);
    height: auto;
    width: 100%;
    display: flex;
    gap: 2rem;
    padding: .25rem;
    border-radius: 10px;
    align-items: center;
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    font-size: 1.7rem;
    border: 1.5px solid var(--primary10);
    transition: 0.2s ease-in-out;
}

.school-card-white {
    background-color: var(--primary00);
    height: 7.3rem;
    width: 100%;
    display: flex;
    gap: 2rem;
    padding: .25rem;
    border-radius: 10px;
    align-items: center;
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    font-size: 1.7rem;
    border: 1.5px solid var(--primary00);
    transition: 0.2s ease-in-out;
}

.school-card-white .school-card-button { /*Le chip d'établissement a un arrière-plan gris dans ce cas-ci uniquement*/
    background-color: var(--primary10);
}

.schoolcard-container .focus:focus {
    background-color: var(--primary90);
    border: 1.5px solid var(--primary90);
    color: var(--primary00);
    transition: 0.2s ease-in-out;
}

.schoolcard-container .hover:hover {
    background-color: var(--primary90);
    border: 1.5px solid var(--primary90);
    transition: 0.2s ease-in-out;
    cursor: pointer;
}


/* image à gauche en background d'une div */

.school-card .img-left-school-card, 
.school-card-white .img-left-school-card,
.school-card-grey .img-left-school-card 
{
    max-height: 6.5rem;
    border-radius: 10px;
}

.text-school-card {
    display: flex;
    width: 100%;
    gap: 0.5rem;
    color: var(--primary90);
    flex-direction: column;
    text-align: left;
    word-break: break-all;
}

.school-card-white.hover:hover .text-school-card {
    color: var(--primary90);
}

.school-card-grey.hover:hover .text-school-card {
    color: var(--primary00);
}
.img-left-school-card{
    aspect-ratio: 1/1;
}
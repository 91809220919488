/* bouton de retour en arrière */
.login-profile .login-go-back {
    position: absolute;
    top: 0;
    left: 1rem;
}

/* contenu de la page (choix du profil) */

.login-profile .section-container {
    transition: 0.2s ease-in-out;
    width: 100vw;
    display: flex;
    min-height: fit-content;
    justify-content: center;
    position: relative;
}

.login-profile {
    transition: 0.2s ease-in-out;
    width: 100%;
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
}

.login-go-back {
    height: fit-content;
    width: fit-content;
}

.login-profile .container {
    width: 50%;
    margin-top: 3rem;
    text-align: center;
    transition: 0.2s ease-in-out;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-profile .container > p {
    margin-bottom: 1.6rem;
}

.login-profile .container .connect {

}

.login-profile .avatar {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
    object-fit: cover;
    margin-bottom: 1rem;
}

.h1-auth {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 2rem;
    margin: 0;
    padding: 0;
}


.login-profile .profile-choice {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 1.5rem;
    width: 80%;
    margin-bottom: 5rem;
}

.school-card-login-choice {
    display: flex;
}

.bjr {
    font-weight: bold;
}

.logo-makee-diamond-login {
    width: 4.5rem;
}

.error-profile {
    color: var(--error50);
    text-align: left;
    margin-top: .5rem;
}

.error-button {
    border: 1px solid var(--error50);
}
.name-profile{
    text-align: left!important;
    font-weight: 700!important;
    font-size: 18px
}
@media screen and (max-width: 900px) {
    .login-profile .section-container {
        flex-direction: column;
        margin-bottom: 5rem;
    }

    .login-profile .container {
        width: 80%;
    }

    .login-profile .avatar {
        width: 5rem;
        height: 5rem;
    }

    .logo-makee-diamond-login {
        margin-bottom: 4rem;
    }

    .h1-auth {
        font-size: 1.5rem;
    }
}

@media screen and (max-width: 600px) {
    .login-profile .container {
        width: 95%;
    }
}
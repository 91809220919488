.error-404 {
    width: 100%;
    height: 100%;
    padding: 3rem;
    margin-top: 10rem;
}

.error-404 .error-404-text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    text-align: center;
}

.error-404 .error-404-text .error-404-text-chip {
    color: var(--primary70);
    font-family: var(--font-primary);
    font-size: medium;
}

.error-404 .error-404-text .error-404-text-btns {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
}

/*.error-404 {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    margin-left: 16.4vw;
}

.error-404 div {
    display: flex;
    flex-direction: column;
    width: 80%;
    gap: 1.5rem;
    max-width: 40rem;
    margin: 3rem;
}

.error-404 svg {
    width: 80%;
    height: 100%;
}

.error-404 .return-svg {
    text-align: center;
    transform: rotate(180deg);
}

.error-404 h1 {
    text-align: center;
}*/

/* Responsive */

@media screen and (max-width: 900px) {
    .error-404{
        margin-left: 0;
        margin-top: 0;
    }
}
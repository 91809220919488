.admin-config{
    padding-bottom: 15vh;
}

.admin-config-header{
    display: flex;
    align-items: center;
    gap: 3vw;
    padding-left: 2vh;
    background-color: var(--primary10);
    height: 7.3vh;
}

.main-admin-config{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.1rem;
    margin-top: 2.2rem;
}

.admin-config .admin-config-card-mobile h1{
    word-break: break-word; /*Retour à la ligne pour le nom d'établissement possible*/
}

.admin-config .admin-config-card-mobile-btn {
    padding: 0 2rem;
    margin: 1rem 0;
}

.edit-icon{
    transform: scale(0.9);
}

.form-content-update-establishment-admin{
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 80%;
    margin: auto;
}

.form-content-update-establishment-admin .form-title{
    text-align: left;
}


.admin-config-edit-button > div > p{
    display: flex;
    align-items: center;
    white-space: nowrap;
}

.establishment-info{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    width: 100%;
}

.license-info{
    border: 1px solid var(--primary10);
    border-radius: 10px;
}

.license{
    width: 100%;
}

.license{
    display: flex;
    flex-direction: column;
    gap: 0.6rem;
}

.license-header{
    background-color: var(--primary10);
    padding: 0.6rem 4rem;
    border-radius: 10px;
    text-align: center;
}

.license-content > div{
    padding: 0.6rem 0;
    width: 100%;
}

.license-content > div:not(:last-child){
    border-bottom: 1px solid var(--primary10);
}

.license-content > div:not(.license-content-item){
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 1vh;
}

.license-content-item{
    display: flex;
    justify-content: center;
    gap: 1vw;
    white-space: nowrap;
}

.challenges-progress-bar{
    width: 80%;
}

.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1vh;
    width: 100%;
    padding: 1rem 0;
    background-color: var(--primary10);
    border-radius: 10px;
}

.contact-content{
    display: flex;
    flex-direction: column;
    gap: 2vh;
}

.contact-content > div{
    display: flex;
    flex-direction: column;
    gap: 1vh;
}

.contact-content > div > h2{
    white-space: nowrap;
}

.contact-info{
    display: flex;
    gap: 0.6rem;
}

.max-challenges-reached{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    gap: 1vh;
    background-color: var(--secondary10);
    border: 2px solid var(--secondary50);
    border-radius: 10px;
    padding: 0.9rem 1.6rem;
}

.admin-config-nav{
    margin: 0 0.9rem;
}


@media screen and (max-width: 365px){
    .license-content-item{
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (min-width: 768px){

    .establishment-info{
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        gap: 5vw;
        background-color: var(--primary10);
        border-radius: 10px;
        margin-bottom: 0.9rem;
    }

    /* .admin-config-edit-button{
        width: 30% !important;
        margin-right: 1.6rem;
    } */

    .main-admin-config{
        flex-direction: row;
        align-items: flex-start;
        padding: 0;
        gap: 1.6rem;
        width: 100%;
    }

    .license{
        display: flex;
        flex-direction: column;
        gap: 2.5vh;
    }

    .contact{
        padding: 2.5rem;
    }

    :modal#modal-update-establishment-admin{
        width: 58vw;
        height: 64.25vh;
        padding: 0;
        background-color: var(--primary00);
        border-radius: 20px;
        box-shadow: 0px 9px 14px rgba(18, 34, 54, 0.1);
        border: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    #modal-update-establishment-admin::backdrop{
        background-color: rgba(0, 0, 0, 0.2);
    }

    .modal-update-establishment-admin-header{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 3vw;
        padding: 2rem 0;
        border-bottom: 1px solid var(--primary30);
    }

    .close-modal-cross{
        position: absolute;
        right: 3rem;
        transform: scale(0.9);
    }

    .close-modal-cross:hover{
        cursor: pointer;
        opacity: 0.6;
        transition: opacity 0.3s;
    }

    .button-update-establishment-admin{
        width: 100%;
        border-top: 1px solid var(--primary30);
        padding: 1.6rem 0;
    }

    .admin-config-edit-confirm-button{
        width: 20.5% !important;
        margin: 1rem auto;
    }
}

.max-challenges-reached span {
    color: var(--Bleu90, #122236);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
.button-go-next{
    padding: 0;
    border: 1px solid red;
}
.button-go-next,
.button-go-next svg path {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: none;
    gap: 0.5rem;
    font-size: 1.25rem;
    font-family: var(--secondary-font);
    transition: 0.2s ease-in-out;
    color: var(--primary90);
}

.button-go-next svg {
    transform: rotate(180deg);
}
.button-go-next svg{
}


.button-go-next:hover svg path,
.button-go-next:hover {
    cursor: pointer;
    color: var(--primary30);
    transition: 0.2s ease-in-out;
    stroke: var(--primary30);
}

@media screen and (max-width: 480px) {
    .button-go-next .delete-text-mobile {
        display: none;
    }
}
/*Empêcher un margin automatique de material ui moche*/
/* clown emoji */
.admin-challenge-planning-content{
    width: 80%;
}
.admin-challenge-planning .planning-header{
    text-align: center;
    margin-bottom: 1rem;
}

.admin-challenge-planning .planning-item{
    border-radius: 10px;
    border: 1px solid var(--primary30);
    height: fit-content;
    padding: 0.9rem;
}

.planning-item.has-selection{
    height: 5.8rem;
}

@media screen and (min-width: 768px){
    .planning-header .text {
        width: 50%;
        margin: auto;
    }

    .planning-item.has-selection{
        height: fit-content;
    }

    .planning-item.has-selection > div{
        display: flex;
        justify-content: space-between;
    }
}
.project-edition {
    margin-bottom: 3vh;
    width: 100%;
}

.project-edition .edition-page-header h4 {
    font-family: Barlow;
}

.component-modal-overlay {
    pointer-events: none;
}

h3, h4 {
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.project-edition h3 {
    color: var(--primary90);
    font-family: Barlow;
    font-size: 1.125rem;
}

.project-edition h4 {
    color: var(--primary90);
    font-family: Ubuntu;
    font-size: 1rem;
}

.edition-page-header {
    background-color: var(--primary30);
    padding-top: 2em;
    padding-bottom: 2em;
    padding-left: 3rem;
    padding-right: 3rem;
    position: relative;
}

.bloc-challenge-details {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.edition-page-header .text-start {
    padding-top: 8px;
}

.edition-page-content {
    display: flex;
    flex-direction: row;
}

.edition-page-content .bloc-project {
    padding: 2rem 3rem;
    width: 45%;
}

.edition-page-content .bloc-project .team-name {
    margin: 1rem auto 3rem;
}

.bloc-info-profile-participation {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: 2px solid var(--primary10);
}

.bloc-info-profile-participation-team {
    padding: 1rem 2rem;
    border-radius: 10px;
    border: 2px solid var(--primary10);
    width: 30rem;
    margin-left: auto;
    margin-right: auto;
}
@media screen and (max-width: 900px) {
    .bloc-info-profile-participation-team {
        width: 80%;
    }
}

.group-avatar-teamspace{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
}
.bloc-info-profile-participation .text-end.teamButtons {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: .25rem;
}

.subchallenge-details .icon-pen {
    width: 20px;
}

.subchallenge-details .icon-pen path {
    fill: #828D9B;
}

.subchallenge-details .icon-pen:hover {
    transform: scale(1.1);
}

.subchallenge-details .selected-subchallenge {
    display: flex;
    justify-content: space-between;
}

.choice-subchallenge {
    background-color: var(--primary10);
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 10px;
}

.bloc-project-progression .eliminated {
    color: #B00362;
    font-weight: 700;
    font-size: 1rem;
}

.bloc-info-project-edit {
    border: 2px var(--primary10) solid;
    border-radius: 10px;
    text-align: justify;
    padding: 1rem;
}

.edition-page-content .step-project {
    width: 55%;
}

.edition-page-content .step-details {
    padding: 2rem 3rem;
    background-color: var(--primary10)
}

.edition-page-content .step-details .smaller-h2 {
    font-size: 1.2rem;
}

.edition-page-content .step-details .no-border-white-bg {
    background: #FFF;
    border-radius: 40px;
    padding: 0.5rem 1rem;
    display: inline-block;
    font-size: 1rem;
}

.edition-page-content .step-details.default {
    background-color: var(--primary10);
}

.edition-page-content .step-details.warning {
    background-color: var(--error10);
    padding-top: 7vh;
    padding-bottom: 7vh;
}

.edition-page-content .step-details h4.bordered {
    border-radius: 40px;
    border: 2px solid #B00362;
    background: #FFF;
    display: inline-block;
    padding: 0.5rem 0.8rem;
}

.edition-page-content .section-collapse-steps-project {
    padding: 2rem 3rem;
}

.warning svg {
    width: 33px;
    height: 33px;
}

.step-project .info-resource {
    color: var(--primary50);
    font-family: Barlow;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}

.step-project .info-resource span {
    text-decoration-line: underline;
    cursor: pointer;
}

.step-project .bloc-info-deliverable {
    background-color: var(--primary10);
    border-radius: 10px;
    margin-bottom: 1vh;
    padding: 0.75em;
}

.save-send-buttons {
    display: flex;
    flex-direction: row;
    gap: 2rem;
    padding: 1rem;
}

@media screen and (max-width: 900px) {
    .bloc-challenge-details {
        flex-direction: column;
    }

    .bloc-challenge-details .chip {
        position: absolute;
        bottom: 1.5rem;
    }

    .edition-page-header .text-end {
        padding-top: 10px;
    }

    .edition-page-content {
        flex-direction: column;
    }

    .edition-page-content .bloc-project {
        width: 100%;
    }

    .edition-page-content .step-project {
        width: 100%;
    }
}

@media screen and (max-width: 450px) {
    .edition-page-content .bloc-project {
        padding: 2rem 2rem;
    }

    .save-send-buttons {
        flex-direction: column;
    }
}
.container-challenge-resultats {
    /*border: 1px solid red;*/
    width: 100%;
    margin-bottom: 5rem;
}

.container-challenge-resultats h2 {
    font-family: var(--font-primary);
    text-align: center;
}

.container-challenge-resultats h3 {
    font-family: var(--font-secondary);
    text-align: center;
    font-weight: 800;
    font-size: 20px
}

.container-projects-selected {
    /*border: 1px solid green;*/
    margin-top: 2rem;
}
.container-projects-selected-content{
    margin-left: auto;
    margin-right: auto;
    /*border: 1px solid black;*/
    width: 90%;
}

.container-projects-finals {
    /*border: 1px solid #B00362;*/
    margin-top: 2rem;
}

.container-projects-eliminated {
    /*border: 1px solid #F86767;*/
    margin-top: 2rem;
}
.container-challenge-resultats .button-see-project{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}
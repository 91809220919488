/*Tout le contenu de la page*/
.discoverMakee {
    max-width: 80%;
    display: flex;
    gap: 7rem;
    flex-direction: column;
    align-items: center;
    margin-top: 8rem;
}


/*Section de présentation générale (entreprises/écoles)*/
.discoverMakee .discoverMakee-presentation,
.discoverMakee .discoverMakee-challenges {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

/*Contenu de chaque catégorie de la présentation (entreprises, puis écoles)*/
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies,
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools {
    display: flex;
    justify-content: space-between;
    background-color: var(--primary10);
    border-radius: 20px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    margin: auto;
}

/*Contenu TEXTUEL global de chaque catégorie de la présentation (titre, description/liste, bouton)*/
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies .discoverMakee-presentation-companies-textPresentation,
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools .discoverMakee-presentation-schools-textPresentation {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    justify-content: space-between;
}

/*Contenu TEXTUEL de chaque liste de la présentation*/
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies .discoverMakee-presentation-companies-textPresentation ul,
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools .discoverMakee-presentation-schools-textPresentation ul {
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

/*Div contenant l'Image de décoration de chaque catégorie de la présentation*/
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies .discoverMakee-presentation-companies-decorationImg,
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools .discoverMakee-presentation-schools-decorationImg {
    width: 60%;
    overflow: hidden;
    border-radius: 50% 0 0 50%;
}

/*l'Image de décoration de chaque catégorie de la présentation*/
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies .discoverMakee-presentation-companies-decorationImg img,
.discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools .discoverMakee-presentation-schools-decorationImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: 50%;
}

/*Block actualités*/
.discoverMakee .discoverMakee-news {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

/*La div contenant la bannière de présentation du challenge discover, du block actualités*/
.discoverMakee .discoverMakee-news .discoverMakee-news-discoverChallengeBanner {
    border-radius: 20px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    width: 100%;
    cursor: pointer;
    transition: all 0.2s ease;

}

/*La div contenant la bannière de présentation du challenge discover, du block actualités*/
/*Quand on le hover*/
.discoverMakee .discoverMakee-news .discoverMakee-news-discoverChallengeBanner:hover {
    filter: brightness(70%);
}

/*Bannière de présentation du challenge discover, du block actualités*/
.discoverMakee .discoverMakee-news .discoverMakee-news-discoverChallengeBanner img {
    width: 100%;
    object-fit: cover;
}

/*Bouton pour en savoir plus, du block actualités*/
.discoverMakee .discoverMakee-news .action-button {
    width: 20%;
    min-width: fit-content;
}

/*Modification de la taille des blocks challenge mobile*/
.discoverMakee .discoverMakee-challenges .challenge-card,
.discoverMakee .discoverMakee-challenges .challenge-card-desktop {
    width: 100%;
}


/***************************************************
*
*                    MOBILE
*
****************************************************/

@media screen and (max-width: 900px) {

    /*Tout le contenu de la page*/
    .discoverMakee {
        gap: 2rem;
    }

    /*Bannière en haut de page*/
    .discoverMakee .discoverMakee-header {
        justify-content: stretch;
        padding: 1rem;
        height: 5rem;
    }

    /*Logo de la bannière en haut de page*/
    .discoverMakee .discoverMakee-header svg {
        width: 20%;
        height: 3rem;
        float: left;
    }

    /*Les boutons de connexion de la bannière*/
    .discoverMakee .discoverMakee-header .discoverMakee-header-accountBtns {
        gap: 1rem;
        display: flex;
        flex-direction: row;
        height: 3rem;
        width: 70%;
    }

    /*Centrage de TOUS les gros titres et les titres de la section de présentation*/
    .discoverMakee h1,
    .discoverMakee h2 {
        text-align: center;
    }

    /*Contenu de chaque catégorie de la présentation (entreprises, puis écoles)*/
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies,
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools {
        flex-direction: column;
        align-items: center;
        justify-content: center;
        max-height: 90%;
    }

    /*Contenu TEXTUEL global de chaque catégorie de la présentation (titre, description/liste, bouton)*/
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies .discoverMakee-presentation-companies-textPresentation,
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools .discoverMakee-presentation-schools-textPresentation {
        width: 80%;
        margin-bottom: 3rem;
        padding: 0;
        padding-top: 2rem;
    }

    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies .discoverMakee-presentation-companies-textPresentation .discoverMakee-presentation-companies-textPresentation-btn,
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools .discoverMakee-presentation-schools-textPresentation .discoverMakee-presentation-schools-textPresentation-btn {
        position: relative;
        margin: auto;
        top: 1rem;
        margin-bottom: -6rem;
    }



    /*Div contenant l'Image de décoration de chaque catégorie de la présentation*/
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies .discoverMakee-presentation-companies-decorationImg,
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools .discoverMakee-presentation-schools-decorationImg {
        width: 100%;
        display: flex;
        justify-content: center;
        position: relative;
        margin-bottom: -5rem;
    }

    /*l'Image de décoration de chaque catégorie de la présentation*/
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-companies .discoverMakee-presentation-companies-decorationImg img,
    .discoverMakee .discoverMakee-presentation .discoverMakee-presentation-schools .discoverMakee-presentation-schools-decorationImg img {
        width: 90%;
        height: 150%;
        border-radius: 50%;
    }
}

/*.redirect-alpha-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 100%;
    height: 84vh;
    min-height: fit-content;
    max-height: 100%;
    margin-top: 10vh;
}

.redirect-alpha-block-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
    margin-left: 5vw;
    text-align: center;
}

.redirect-alpha-block-text .h1-auth {
    margin-bottom: 6vh;
}

.redirect-alpha-block-text .form-text {
    color: black;
    margin-bottom: 7vh;
}

.redirect-alpha-block-text .form-text a {
    color: black;
}

.redirect-alpha-block-text button {
    margin: auto;
    width: 30rem;
}

.redirect-alpha-block-text button span {
    font-size: 21px;
}

.h1-auth .text-logo {
    font-family: var(--font-logo);
    color: var(--actionprimary50);
    font-weight: 400;
}

.redirect-alpha-block .diamond-login {
    height: 90vh;
    position: fixed;
    right: 0;
    top: 10vh;
}

@media screen and (max-width: 1200px) {
    .redirect-alpha-block .diamond-login {
        width: 40%;
    }

    .redirect-alpha-block-text {
        width: 60%;
        margin-left: 2vw;
    }

    .redirect-alpha-block-text button {
        width: 25rem;
    }
}

@media screen and (max-width: 768px) {
    .redirect-alpha-block {
        margin-top: 0;
    }

    .redirect-alpha-block .diamond-login {
        display: none;
    }

    .redirect-alpha-block-text {
        width: 95%;
        margin: auto;
        text-align: center;
    }

    .redirect-alpha-block-text .makee-diamond-login {
        margin-bottom: 10vh;
    }

    .redirect-alpha-block-text button {
        width: 20rem;
    }
}*/


.home-no-logged {
    margin-top: 10rem;
    /* outline: 1px solid red; */
    width: 100%;
}

.home-no-logged .presentation {
    position: relative;
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    /* outline: 1px solid lawngreen; */
    display: flex;
    justify-content: space-between;
    gap: 0;
    min-height: 10rem;
}

.home-no-logged .presentation .block-buttons {
    /* outline: 1px solid yellow; */
    width: 25rem;
}

.home-no-logged .presentation .block-buttons .buttons {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-top: auto;
}

.home-no-logged .presentation .icon-fleche {
    margin-top: auto;
    margin-bottom: auto;
    /* outline: 1px solid purple; */
    margin-left: -1vw;
}

.home-no-logged .presentation .title {
    /* outline: 1px solid aquamarine; */
    width: fit-content;
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

.home-no-logged .presentation .title h1 {
    font-family: var(--font-primary);
    font-size: 42px;
    text-transform: uppercase;
    text-align: left;
    width: 100%;
}

.home-no-logged .presentation .title p {
    font-family: var(--font-primary);
    font-size: 24px;
    font-weight: 300;
    /* outline: 1px solid orange; */
    width: 100%;
}

.home-no-logged .presentation .title h1 span {
    color: var(--actionprimary50);
    font-family: var(--font-logo);
    font-weight: 500;
    text-transform: none;
}

.home-no-logged .block-list-challenges {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    margin-top: 5rem;
}

.home-no-logged .hero-banner {
    position: relative;
    width: 100%;
    max-width: 100%;
    /* outline: 1px solid royalblue; */
}

.home-no-logged .hero-banner img {
    width: 100%;
    max-width: 100%;
}

.home-no-logged .hero-banner .block-button {
    position: absolute;
    bottom: 2rem;
    width: 100%;
    display: flex;
    justify-content: center;
}

.home-no-logged .hero-banner .block-button .button {
    border-radius: 10px;
    width: fit-content;
    padding: 1rem 4rem;
}

.home-no-logged .block-list-challenges .list-challenges {
    width: 80%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* outline: 1px solid green; */
}

.home-no-logged .block-list-challenges .challenge-model {
    /* outline: 1px solid red; */
    width: 80%;
}

.home-no-logged .explanation {
    width: 100%;
    padding: 5rem;
    padding-top: 5rem;
    background-color: var(--actionprimary50);
    /* outline: 1px solid red; */
}
.home-no-logged .explanation .title {
    text-align: center;
    color: white;
    margin-bottom: 2rem;
    font-family: var(--font-tertiary);
    font-size: 32px;
}
.title-alpha-makee-home {
    font-size: 32px;
}
.home-no-logged .explanation .cards {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: row;
    gap: 2rem;
    border-radius: 20px;
    /* outline: 1px solid red; */
}

.home-no-logged .explanation .card {
    min-height: 15rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    /* outline: 1px solid green; */
    padding: 1rem;
}

.home-no-logged .explanation .card .text {
    /* outline: 1px solid red; */
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.home-no-logged .explanation .card .text .number {
    /* outline: 1px solid red; */
    font-family: var(--font-tertiary);
    color: var(--actionprimary50);
    font-size: 48px;
    font-weight: 900;
    line-height: 42px;
}

.home-no-logged .explanation .card .text .description {
    font-size: 24px;
    font-family: var(--font-tertiary);
    text-align: left;
}

.home-no-logged .explanation .card .text .description span {
    font-weight: bold;
    text-align: left;
}

.home-no-logged .explanation .card img {
    width: 14rem;
    max-width: 14rem;
    /* outline: 1px solid lime; */
}

.home-no-logged .block-list-challenges h1 {
    text-align: center;
    margin-bottom: 3rem;
}

.home-no-logged .block-list-challenges .list-challenges {
    margin-top: 5rem;
    margin-bottom: 5rem;
}

.home-no-logged .block-list-challenges .list-challenges .pagination {
    /* outline: 1px solid green; */
}

.home-no-logged .block-list-challenges .list-challenges .pagination ul{
    /* outline: 1px solid red; */
    margin-left: auto;
    margin-right: auto;
}

@media screen and (min-width: 900px) and (max-width: 1000px) {
    .home-no-logged .explanation .card {
        flex-direction: column;
        align-items: center;
    }
}
@media screen and (max-width: 900px) {
    .home-no-logged .explanation .card .text .description {
        font-size: 18px;
    }
    .home-no-logged .presentation {
        flex-direction: column;
    }

    .home-no-logged .presentation .title h1 {
        font-size: 32px;
    }
    .home-no-logged .presentation .title p {
        font-size: 18px;
    }
    .home-no-logged .presentation .block-buttons {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .home-no-logged .presentation .block-buttons .buttons {
        width: 80%;
    }

    .home-no-logged .presentation .icon-fleche {
        margin-left: 0;
    }

    .home-no-logged .explanation .card {
        height: 10rem;
    }

    .home-no-logged .explanation .card .text .number {
        min-width: 3rem;
    }

    .home-no-logged .explanation .card .text {
        flex-direction: row;
        align-items: center;
        gap: .5rem;
    }

    .home-no-logged .explanation .card .text {
        width: fit-content;
        max-width: fit-content;
    }

    .home-no-logged .explanation .cards {
        grid-template-columns: repeat(1, 1fr);
    }
}
@media screen and (max-width: 450px) {
    .home-no-logged .explanation {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .home-no-logged .explanation .cards {
        width: 15rem;
    }
    .home-no-logged .explanation .card {
        height: auto;
        max-width: auto;
        min-width: auto;
        width: 100%;
    }
    
    .home-no-logged .explanation .card .text{
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .home-no-logged .hero-banner .block-button .button {
        width: 15rem;
        padding: 0;
        padding-top: .5rem;
        padding-bottom: .5rem;
    }
    .home-no-logged .hero-banner .block-button .button span{
        width: 100%;
    }
}
#list-teams-join {
    margin-top: 3vh;
    margin-bottom: 3vh;
    width: 80%;
    /* padding-top: 3vh;
    padding-bottom: 3vh; */
    /* border: 1px solid #e6e6e6; */
}

#list-teams-join .p-bold {
    color: var(--primary90);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#list-teams-join .card-team-join {
    border-radius: 10px;
    background-color: var(--primary10);
}

#list-teams-join .card-team-join .content {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 2em;
    padding-right: 2em;
}

#list-teams-join .card-team-join .content .team-name{
    color: var(--primary90);
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}
#list-teams-join .card-team-join .content .card-team-join-bio {
    margin-top: 1em;
    background-color: var(--primary00);
    padding: 1em;
    border-radius: 10px;
}
#list-teams-join .card-team-join .content .card-team-join-bio .word-team {
    color: #000;
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#list-teams-join .card-team-join .content .card-team-join-bio .bio {
    color: #000;
    font-family: Barlow;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}



@media only screen and (min-width: 900px) {
    #bloc-info-teams-join {
        width: 60%;
        margin: auto;
    }
}

.blocinfo-warning-modal-jointeam {
    margin-top: 2em;
    background: var(--primary10);
    padding: 1em;
    border-radius: 10px;
}

#bloc-info-teams-join span {
    font-weight: 700;
}
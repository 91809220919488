.admin-edit-challenge-steps .header {
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 0.35rem;
}

.required-field {
    color: var(--actionprimary50);
    font-style: italic;
}


section.admin-edit-challenge-steps {
    margin-bottom: 3em;
}

.admin-edit-challenge-steps > .header {
    width: 80%;
    margin: auto;
    margin-bottom: 1.5rem;
}

.admin-edit-challenge-steps .stepCard {
    background-color: var(--primary10);
    border-radius: 10px;
}

.admin-edit-challenge-steps label {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 1rem;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-top: .5rem;
}

.admin-edit-challenge-steps .stepCard h3{
    color: var(--primary90);
    font-family: var(--font-primary);
    font-size: 1.5rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin: 0;
}

.admin-edit-challenge-steps .fixed-questions-list{
    margin-top: 0.5em;
    background-color: var(--secondary10);
    padding: 1.2rem 0.62rem;
    border-radius: 10px;
}

.admin-edit-challenge-steps #info-recap-challenge-step {
    background-color: var(--primary10);
    border-radius: 10px;
    padding: 0.62rem 0.62rem;
    width: auto;
    margin-bottom: 2rem;
}

.admin-edit-challenge-steps #info-recap-challenge-step .challenge-name-bloc {
    width: auto;
    margin: auto;
    text-align: center;
}

.admin-edit-challenge-steps #info-recap-challenge-step .challenge-name {
    padding: 0.3em 0.62em;
    border-radius: 10px;
}

.admin-edit-challenge-steps #info-recap-challenge-step .bloc-date {
    background-color: var(--primary00);
    padding: 0.3em 0.62em;
    border-radius: 10px;
}

.admin-edit-challenge-steps .info-collapse {
    background-color: var(--primary00);
    border-radius: 10px;
    margin-bottom: 1em;
}

.admin-edit-challenge-steps .info-collapse .rotate-iconopen {
    transition: transform 0.3s ease-in-out;
    transform: rotate(-180deg);
}

.admin-edit-challenge-steps .info-collapse .rotate-iconclose {
    transition: transform 0.3s ease-in-out;
}

.admin-edit-challenge-steps .info-collapse .icon-help {
    width: 20px;
    margin-left: 0.3em;
    margin-bottom: 0.1em;
}

.admin-edit-challenge-steps .info-collapse .icon-help path {
    fill: var(--primary90);
}

.admin-edit-challenge-steps .fixed-todo-list {
    margin-top: 0.5em;
    background-color: var(--primary10);
    padding: 1.2rem 0.62rem;
    border-radius: 10px;
}
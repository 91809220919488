@import url('https://fonts.googleapis.com/css2?family=Barlow:wght@400;500;600;700&family=Ubuntu:wght@500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Fugaz+One&display=swap');

iframe#webpack-dev-server-client-overlay {
    display: none !important
}

/* import des fonts */

/* CSS VARIABLES */

:root {
    /* PRIMARY COLOR */
    --primary90: #122236;
    --primary70: #3F4F64;
    --primary50: #828D9B;
    --primary30: #D9E0EA;
    --primary10: #EDF1F7;
    --primary00: #FFFFFF;

    /* SECONDARY COLOR */
    --secondary50: #D43465;
    --secondary10: #FDEEF3;

    /* PRIMARY ACTION */
    --actionprimary50: #F86767;
    --actionprimary30: #FFD0D0;
    --actionprimary10: #FFF1F1;

    /* VALIDATE */
    --validate50: #00CD71;
    --validate10: #ECFDF3;

    /* ERROR */
    --error50: #B00362;
    --error10: #F8E2EE;

    /* WAITING; */
    --wait50: #F98648;
    --wait10: #FFEADE;

    /* GRADIENT */
    --gradient: linear-gradient(275deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%);
    --firefoxgradient: -moz-linear-gradient(275deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%);


    /* FONTS */
    --font-primary: 'Ubuntu', sans-serif;
    --font-secondary: 'Barlow', sans-serif;
    --font-tertiary: 'Inter', sans-serif;
    --font-logo: 'Fugaz One', sans-serif;
    /* SHADOW */
    --pop-up: 0px 9px 14px rgba(18, 34, 54, 0.1);
    --menu: 0px -5px 14px rgba(18, 34, 54, 0.1);

    /* BORDER */
    --border: 2px solid var(--primary10);
}

/* paramètres de l'application */

*,
*::before,
*::after {
    box-sizing: border-box;
}

body {
    margin: 0;
    font-family: var(--font-secondary);
}

/* tout ce qui n'est pas de la nav ou du header */
/* permet d'afficher une certaine marge */

.container-app {
    color: var(--primary90);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 16.4vw;
    margin-top: 15vh;
    position: relative;
}

.container-app-configure {
    color: var(--primary90);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 0vw;
    margin-bottom: 9vh;
    margin-top: 15vh;
    position: relative;
}

.container-app-logout {
    margin: 0;
}

.container-app-nothing {
    margin: 0 !important;
}

/* paramètres des fonts de l'application */

/* h1 */

h1 {
    font-family: var(--font-primary);
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}


/* h2 */

h2 {
    font-family: var(--font-secondary);
    font-weight: 700;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.h2 {
    font-family: var(--font-secondary);
    font-weight: 700;
    font-size: 1.125rem;
    margin: 0;
    padding: 0;
}

/* paragraphes */

p {
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: 0.9rem;
    margin: 0;
    padding: 0;
}

.bold {
    font-weight: bold;
}

.text {
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: 0.9rem;
    margin: 0;
    padding: 0;
    word-break: break-word;
    white-space: pre-wrap;
    text-align: justify;
}

.delete-space-paragraph {
    margin-block-start: 0;
    margin-block-end: 0;
}

/* textes de lead */

p.small-lead-text,
a.small-lead-text,
li.small-lead-text {
    font-family: var(--font-secondary);
    font-weight: 500;
    font-size: 1rem;
    margin: 0;
    padding: 0;
}

.small-lead-text span {
    font-weight: 400;
}

p.big-lead-text,
a.big-lead-text {
    font-family: 'Ubuntu', sans-serif;
    font-weight: 500;
    font-size: 1.9rem;
    margin: 0;
    padding: 0;
}

.big-lead-text p {}

/* textes de formulaire */

.form-title {
    font-family: var(--font-secondary);
    font-weight: 600;
    font-size: 1rem;
    margin: 0;
    padding: 0;
}

.form-title-auth {
    font-family: var(--font-secondary);
    font-weight: 600;
    font-size: 1.5rem;
    margin: 0;
    padding: 0;
}

.form-text {
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
}

.form-text-auth {
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: 1.3rem;
    margin-top: 1rem;
    margin-bottom: 0;
    padding: 0;
}

/* textes des formulaires */

.form-text {
    font-family: var(--font-secondary);
    font-weight: 400;
    font-size: 1.25rem;
    margin: 0;
    padding: 0;
}

/* texte des bouton */

.button-text {
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    font-size: 0.9rem;
    margin: 0;
    padding: 0;
}

.button-text-auth {
    font-family: 'Barlow', sans-serif;
    font-weight: 700;
    font-size: 1.25rem;
    margin: 0;
}

/* Responsive */
.h1-auth {
    font-size: 1.5rem;
    font-weight: bold;
}

.global-translate-icon {
    cursor: pointer;
    user-select: none;
}

.align-left-flex {
    display: flex;
    justify-content: start;
}


.bold {
    font-weight: bold;
}

@media screen and (max-width: 900px) {
    .container-app {
        margin-left: 0;
        /*margin-top: 10vh;*/
        margin-bottom: 10vh;
    }

    .container-app-configure {
        margin-left: 0;
        margin-top: 10vh;
        margin-bottom: 13vh;
    }

    .container-app-logout {
        /*margin-top: 0 !important;*/
        /*margin-top: 6rem !important;*/
    }
}

@media screen and (max-width: 768px) {
    .h1-auth {
        font-size: 1.5rem;
    }

    h2.h2 {
        font-size: 1.125rem;
    }

    .text {
        font-size: 0.9rem;
    }

    .small-lead-text {
        font-size: 1rem;
    }

    p.big-lead-text,
    a.big-lead-text,
    .big-lead-text {
        font-size: 1.25rem;
    }

    .form-title {
        font-size: 1rem;
    }

    .form-text {
        font-size: 1rem;
    }

    .button-text {
        font-size: 0.9rem;
    }

    .button-text-auth {
        font-size: 1.125rem;
    }

    .form-text-auth {
        font-size: 1rem;
    }


}


/* Customisation de la scrollbar */
::-webkit-scrollbar {
    width: .5rem;
}

::-webkit-scrollbar-track {
    background-color: var(--primary10);
    border-radius: 10px;
}

::-webkit-scrollbar-thumb {
    background-color: var(--primary50);
    /*background: linear-gradient(275deg, #B00362 -11.94%, #F86767 67.21%, #F8BD0E 145.85%);*/
    border-radius: 10px;
}
.admin-name-email-establishment {
    width: 100vw;
    height: calc(100vh - 8rem);
    display: flex;
    justify-content: space-between;
    text-align: center;
}

.admin-name-email-establishment .container {
    margin-top: 8rem;
    width: 46vw;
    margin-left: 11vw;
}

.admin-name-email-establishment h1 {
    margin-bottom: 0.6rem;
}


.admin-name-email-establishment .paragraphs {
    margin-bottom: 2.5rem;
    text-align: center;
    white-space: pre-wrap;
}

.admin-name-email-establishment .fields {
    color: var(--primary70);
}

.admin-name-email-establishment span {
    font-size: 1.7rem;
    font-weight: 700
}

.admin-name-email-establishment form {
    display: flex;
    flex-direction: column;
}

.admin-name-email-establishment form input {
    border-radius: 5px;
    font-weight: 400;
    color: #122236;
}

.admin-name-email-establishment .diamond {
    position: fixed;
    right: -10vw;
    width: 50%
}

.admin-name-email-establishment .makee-diamond-login {
    display: none;
}

.admin-name-email-establishment .container form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        margin: 0;
        margin-bottom: 3rem;
    }

@media screen and (max-width: 992px) {
    .admin-name-email-establishment .container {
        margin-top: 3rem;
    }
}

@media screen and (max-width: 768px) {

    .admin-name-email-establishment {
        margin-top: -3rem;
        width: 100vw;
    }

    .admin-name-email-establishment .container form {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 2rem;
        margin: 0;
        margin-bottom: 3rem;
    }

    .admin-name-email-establishment .container form input {
        width: 100%;
    }

    .admin-name-email-establishment .container {
        margin-top: 6rem;
        width: 100%;
        padding: 0 1rem;
        margin-left: 0;
        max-width: 100%;
    }

    .admin-name-email-establishment .container .makee-diamond-login {
        margin-bottom: 2rem;
        width: 100%;
        padding: 0 1rem;
        margin-left: 0;
    }

    .admin-name-email-establishment .container .paragraphs {
        margin-bottom: 2rem;
    }


    .admin-name-email-establishment .diamond {
        display: none;
    }

    .admin-name-email-establishment .makee-diamond-login {
        display: block;
    }

}
.participant-challenge {
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
}

.description-2 {
    font-weight: 600;
}
.participant-registered{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 1rem;
}
.participant-registered-status{
    border-radius: 10px;
    padding: 12px 15px 12px 15px;
    border: 2px solid var(--primary10);
    width: 100%;
    text-align: center;
    color: var(--primary90);
    font-weight: 700;
    font-family: var(--font-primary);
    width: 100%;
}

input[type="checkbox"]{
    background-color: var(--actionprimary10);
    appearance: none;
    width: 1.5rem;
    height: 1.5rem;
    border: 2px solid var(--actionprimary50);
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}
input[type="checkbox"]::before{
    content: "";
    width: 1rem;
    height: 0.8rem;
    transform: scale(0);
    transition: all 0.1s ease-in-out;
    box-shadow: inset 1em 1em var(--actionprimary50);
    transform-origin: bottom left;
    clip-path: polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%);
}
input[type="checkbox"]:checked::before {
    transform: scale(1);
}

.block-checkbox{
    display: flex;
    margin-top: 2rem;
    gap: 10px;
    justify-content: center;
    align-items: center;
}
.block-checkbox label{
    cursor: pointer;
    user-select: none;
}
.component-modal-content-body a{
    color: var(--actionprimary50);
    font-weight: 600;
}

.participant-challenge-modal-body{
}
.participant-challenge-modal-body p{
    margin-top: 1rem;
}



.h5modal {
    color: var(--primary90);
    font-family: Barlow;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

.desc-sub-challenge {
    color: var(--primary90);
    font-family: Barlow;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.bloc-info-modal {
    background: var(--primary10);
    padding: 1em;
    border-radius: 10px;
}

#run-project .label-desc {
    color: var(--primary90);
    font-family: Barlow;
    font-style: italic;
    font-weight: 400;
    line-height: 20px;
    margin-bottom: 0.5em;
    font-size: 13px;
}

#run-project .label {
    color: var(--primary90);
    font-family: Barlow;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
}

#run-project .label-radio-check {
    color: var(--primary90);
    font-family: Barlow;
    /* font-size: 14px; */
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

#run-project .label-radio {
    color: var(--primary90);
    font-family: Barlow;
    /* font-size: 14px; */
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

.buttons-login-register{
    display: flex;
    gap: 1rem;
}

.text-criteria-register{
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}

.sub-text-criteria-register{
    font-size: 14px!important;
    text-align: left;
    margin-bottom: 2rem;
}
.bloc-eligibilities-check{
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    margin-top: 1rem;
}
.bloc-eligibility-check{
    display: flex;
    gap: 1rem;
    width: 75%;
}
.bloc-eligibilities-check{
    display: flex;
    gap: 1rem;
}
.eligibility-label{
    font-size: 14px;
    cursor: pointer;
    line-height: 24px;
}
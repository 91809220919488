.admin-home-page {
    width: 80%;
    margin-top: 2rem;
}
.admin-home-page h1 {
    margin-bottom: 2rem;
    font-size: 2rem;
    text-align: center;
}
.block-challenges{
    display: flex;
    flex-direction: column;
    gap: 30px;
    margin-bottom: 2rem;
}
.block-challenge{
}
@media screen and (max-width: 900px){
    .admin-home-page{
    }
}
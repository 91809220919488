.establishment-config-global { /*La page globale de configuration*/
    width: 80%;
}

.establishment-config-global .establishment-config-card {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1rem;
}

@media screen and (min-width: 900px) {
    .establishment-config-global .establishment-config-card { /*La carte de l'établissement au-dessus de la navbar*/
        padding: .25rem;
        border-radius: 10px;
        background-color: var(--primary10);
        flex-direction: row;
        margin-bottom: .9rem;
        justify-content: space-between;
        margin-top: 2rem;
    }
    
    .establishment-config-global .establishment-config-card .establishment-config-card-btn{ /*Le bouton de modif de l'établissement*/
        display: flex;
        justify-content: center;
        padding: 2rem;
    }
}
:modal.modal-update-email-profile{
    width: 100%;
    max-width: 100%;
    height: 62vh;
    padding: 0;
    background-color: var(--primary00);
    border-radius: 20px;
    box-shadow: 0px 9px 14px rgba(18, 34, 54, 0.1);
    border: none;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow-x: hidden;
    animation: modal-update-email-profile-appear 0.5s;
    align-items: center;
}

.modal-update-email-profile::-webkit-scrollbar{
    display: none;
}

.modal-update-email-profile{
    inset-block-start: 45%;
}

.modal-update-email-profile::backdrop{
    background-color: rgba(0, 0, 0, 0.2);
}

.modal-update-email-profile-header{
    width: 100%;
    display: flex;
    justify-content: center !important;
    align-items: center;
    gap: 3vw;
    padding-top: 2rem;
}

.modal-update-email-profile-header{
    width: 100% !important;
}

.button-update-email-profile{
    width: 100% !important;
    justify-content: center !important;
    gap: 0.6rem;
    margin-bottom: 1vh;
}

/* cela ne marche pas sinon */
.button-update-email-profile > button > div{
    width: 100% !important;
}

/* cela ne marche pas sinon */
.button-update-email-profile .button-text{
    width: 100%;
    text-align: center;
}

.button-update-email-profile > button{
    width: 45% !important;
}

.close-modal-cross{
    position: absolute;
    right: 3rem;
    transform: scale(0.9);
}

.close-modal-cross:hover{
    cursor: pointer;
    opacity: 0.6;
    transition: opacity 0.3s;
}

.form-content-update-email-profile{
    display: flex;
    flex-direction: column;
    gap: 2vh;
    width: 100%;
    align-items: center;
    text-align: center;
}

.form-content-update-email-profile .input-text-field{
    margin-left: 3rem;
    width: 90% !important;
}

.button-update-email-profile{
    width: 100%;
    padding-bottom: 2.8rem;
}

.form-content-update-email-profile > .text{
    width: 100%;
    margin-bottom: 1.5rem;
}

@media screen and (min-width: 768px){
    :modal.modal-update-email-profile{
        width: 58vw;
        height: 64.25vh;
    }

    .modal-update-email-profile{
        inset-block-start: 0;
    }

    .modal-update-email-profile-header{
        border-bottom: 1px solid var(--primary30);
        padding: 2rem 0;
    }

    .button-update-email-profile{
        border-top: 1px solid var(--primary30);
        padding: 1.6rem 0;
    }

    .form-content-update-email-profile .input-text-field{
        margin-left: 8rem;
    }

    .form-content-update-email-profile > .text{
        width: 80%;
    }

    .button-update-email-profile > button{
        width: 25% !important;
    }
}

@keyframes modal-update-email-profile-appear{
    from{
        opacity: 0;
        transform: translateY(100%);
    }
    to{
        opacity: 1;
        transform: translateY(0);
    }
}
.test{
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
#stepper {
    height: 8px;
    position: relative;
}
#stepper .step {
    height: 8px;
    padding: 0;
    border-radius: 0;
    background-color: var(--primary10);
    background: var(--primary10);
    position: relative;
}
/* #stepper .step.running {
    background: var(--actionprimary50);
    animation: gradient-fill 1s forwards;
}
   */
/* #stepper .default-step {
    position: absolute;
    width: 0%;
    height: 8px;
    background: var(--gradient);
    animation: gradient-fill 1s forwards;
} */
@keyframes gradient-fill {
    0% {
        width: 0;
    }
    100% {
        width: 100%;
    }
}
.criterium{
    display: flex;
    flex-direction: column;
    border-radius: 10px;
    border: 1px solid var(--primary50);
    width: 90%;
}

.criterium .criterium-header{
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid var(--primary50);
}

.criteria .criterium .criterium-header .criterium-header-left{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 80%;
    border-right: 1px solid var(--primary50);
    padding: 0.5rem;
}

.criteria .criterium .criterium-header .criterium-header-left .trash-icon{
    margin-right: 0.5rem;
    opacity: 0.8;
    width: 1.5rem;
    height: 1.5rem;
}

.criteria .criterium .criterium-header .criterium-header-right{
    margin: auto;
    width: 30%;
}

.criterium-header-right p{
    display: flex;
    justify-content: center;
    align-items: center;
}

.criterium-header-right input.empty-input{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.criterium-header-right input{
    width: 25%;
    text-align: center;
}

.criteria .criterium .criterium-description{
    padding: 0.5rem;
}

textarea.input-editable, input.input-editable {
    border: none;
    outline: none;
    resize: none !important;
    -moz-appearance: textfield;
    appearance: textfield;
    overflow: hidden;
    padding-left: .5rem;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

textarea.input-editable{
    width: 100% !important;

    /* La hauteur était trop importante et ça rendait moche lors de l'ajout d'un critère */
    height: 22px;
}

@media screen and (min-width: 768px){
    .criterium{
        width: 60%;
    }
    
    .criteria .header {
        width: 40%;
    }

    .criteria .criterium .criterium-header .criterium-header-right{
        width: 15%;
    }

    .criteria .criterium .criterium-header .criterium-header-left{
        width: 85%;
    }

    .criteria .criterium .criterium-header .criterium-header-left .criterium-name{
        width: 100%;
    }

    .criteria .footer{
        width: 60%;
    }
}
body:has(.signup-participant-email){
    height: 100%;
    overflow: hidden;
}

.signup-participant-email{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    text-align: center;
    width: 100%;
    padding: 3rem;
}

.signup-participant-email-header{
    display: flex;
    position: fixed;
    top: 0;
    margin: auto;
    margin-top: 5.46vh;
    width: 100%;
}

.signup-participant-email-section-container{
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 3rem;
}

.signup-participant-email-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.4rem;
}

.signup-participant-email-main > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
}

.italic{
    font-style: italic;
}

.link{
    color: var(--actionprimary50);
    border-bottom: 1px solid var(--actionprimary50);
    text-decoration: none;
    white-space: nowrap;
}

.signup-participant-email-diamond{
    position: fixed;
    bottom: 3rem;
    height: 16.25vh;
}

@media screen and (min-width: 768px){
    .signup-participant-email-makee-diamond-login{
        display: none;
    }

    .signup-participant-email{
        padding: 10rem 3rem;
    }

    .signup-participant-email-header{
        position: fixed;
        top: 11vh;
    }

    .signup-participant-email .go-back{
        display: none;
    }

    .form-header h1{
        width: 60%;
        margin: auto;
    }

    .form-header p{
        width: 50%;
        margin: auto;
    }

    .form-content{
        width: 60%;
        margin: auto;
    }

    .signup-participant-email-section-container{
        margin: 0;
    }

    .signup-participant-email > footer > a{
        position: absolute;
        bottom: 2rem;
        right: 36rem;
    }
}
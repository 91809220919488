.error {
    border: solid 2px red;
    border-radius: 12px;
}

.error-message {
    color: red;
}

.rule-text-disabled {
    -webkit-text-fill-color: rgba(0, 0, 0, 0.38);
}

.two-view-height {
    margin-top: 7vh;
}

.admin-eligibilities{
    text-align: left;
}

.admin-eligibilities h4{
    font-size: 18px;
}
.container-button-add-eligibilities{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    margin-top: 1rem;
    margin-bottom: 1.5rem;
}
.button-add-eligibilities{
    width: 50%;
    font-size: 14px;
}
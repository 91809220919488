.general-challenge-my-project {
    margin-bottom: 5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.general-challenge-my-project h3 {
    color: var(--primary90);
    text-align: center;
    font-family: var(--font-primary);
    font-size: 24px;
    font-weight: 700;
}

.general-challenge-my-project-block-content {
    width: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}

.general-challenge-my-project-content {
    width: 100%;
    word-break: break-all;
}

.my-project-infos-details {
    width: 100%;
    background-color: white;
    position: relative;
    border-radius: 0 0 10px 10px;
    overflow: hidden;
    border: 2px solid var(--primary10);

}

.my-project-infos-details .content {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 14px;
    padding: 1.5rem;
    border-bottom-style: none;
    display: flex;
    flex-direction: column;
}

.my-project-infos-details .content .participation-team {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    font-weight: 400;
}

.my-project-infos-details .content .participation-team .warning {
    color: var(--error50);
}

.my-project-infos-details .content .project-status {
    display: flex;
    justify-content: space-between;
    font-family: var(--font-secondary);
    font-size: 14px;
    font-style: italic;
    font-weight: 400;
}

.my-project-infos-details .content .project-status h4 {
    color: var(--primary90);
    font-style: normal;
}

.my-project-infos-details .block-dateEndDeposit-button {
    display: flex;
}
/* 
.my-project-infos-details .block-dateEndDeposit-button .block-button.bottom-left-radius button {
    border-radius: 0 0 10px 10px;
} */

.my-project-infos-details .block-dateEndDeposit-button .block-dateEndDeposit {
    flex: 1;
    background-color: var(--primary30);
    display: flex;
    align-items: center;
    justify-content: center;
    /* padding: 1rem; */
    /* border-radius: 0 0 0 10px; */
}

.my-project-infos-details .block-dateEndDeposit-button .block-dateEndDeposit p {
    text-align: center;
    color: var(--primary90);
    font-family: var(--font-primary);
    font-size: 14px;
    font-weight: 700;
}

.my-project-infos-details .block-dateEndDeposit-button .block-button {
    /* width: 50%; */
    flex: 1;
}

.my-project-infos-details .block-dateEndDeposit-button .block-button button {
    height: 100%;
    /* border-radius: 0 0 10px 0; */
    border-radius: 0;
}


@media screen and (max-width: 768px) {
    .general-challenge-my-project-block-content { /*Le composant "mon projet" était petit en version mobile avec 60%*/
        width: 85%;
    }
}



footer{
    width: 100%;
    height: 10vh;
    /*Qui a mis ce truc ??? visibility: hidden; */
    bottom: 0;
    left: 0;
    right: 0;
    display: block;
}

@media screen and (max-width: 900px) {
    footer{
        margin-top: 10vh;
    }
}
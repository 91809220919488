body:has(.admin-profile-update-email) {
    height: 100%;
    overflow: hidden;
}

.admin-profile-update-email {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    text-align: center;
    width: 100%;
    padding: 0 3rem;
    margin-top: 20vh;
}

.admin-profile-update-email .admin-profile-update-email-header{
    display: flex;
    position: fixed;
    top: 0;
    margin: auto;
    margin-top: 5.46vh;
    width: 100%;
}

.admin-profile-update-email-section-container{
    height: 100vh;
    margin-top: -12rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5.3rem;
}

.admin-profile-update-email-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.4rem;
}

.admin-profile-update-email-main > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
}

.admin-profile-update-email .italic {
    font-style: italic;
}

.admin-profile-update-email .link {
    color: var(--actionprimary50);
    border-bottom: 1px solid var(--actionprimary50);
    text-decoration: none;
    white-space: nowrap;
}

.email-sent-text{
    color: var(--secondary50);
}

@media screen and (min-width: 768px){
    .makee-diamond-login-reset-password{
        display: none;
    }

    .admin-profile-update-email{
        margin-top: 10vh;
    }

    .admin-profile-update-email .admin-profile-update-email-header {
        position: fixed;
        top: 11vh;
    }

    .admin-profile-update-email-go-back{
        position: fixed;
        left: 3rem;
    }

    .admin-profile-update-email-go-back .button-go-back{
        justify-content: flex-start;
        gap: 1rem;
    }

    .admin-profile-update-email-section-container{
        margin: 0;
    }

    .admin-profile-update-email .admin-profile-update-email>footer>a {
        position: absolute;
        bottom: 2rem;
        right: 36rem;
    }

    .diamond-update-email{
        position: fixed;
        bottom: 0;
        height: 16.25vh;
    }
}
.resource-list{
    display: flex;
    flex-direction: column;
    gap: 3rem;
    width: 100%;
}

.resources{
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}

.resources p.button-text{
    display: flex;
    align-items: center;
}

@media screen and (min-width: 768px){
    .resources{
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;
    }

    .resource-list .resourcess-add-button{
        width: 40%;
        margin: auto;
    }
}
.block-admin-create-challenge {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    position: relative;
    margin-bottom: 5vh;
    margin-top: 3rem;
}

.admin-create-challenge {
    width: 60%;
    display: flex;
    flex-direction: column;
    align-items: start;
}

.text-obligatory {
    margin-bottom: 20px;
}

.admin-create-challenge .form-text {
    margin-bottom: 0.5rem;
    font-weight: 600;
    line-height: 20px;
}

.form-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    min-width: 80%;
    min-height: 10vh;
    background: var(--primary00);
    margin-left: 16.4vw;
    margin-bottom: 0 !important;
    padding: 1rem;
    border-top: var(--border);
    z-index: 101;
}

.admin-create-challenge .choix-dates {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

.admin-create-challenge .radio-buttons svg {
    fill: var(--actionprimary50)
}

.admin-create-challenge .radio-buttons span:nth-child(2) {
    font-size: 0.9rem;
    font-family: 'Barlow', sans-serif;
}

.admin-create-challenge .text-field-date {
    display: flex;
    gap: 1rem;
}

.admin-create-challenge .helper-text {
    font-style: italic;
    color: red;
}

.form-buttons button {
    width: 15rem;
    height: 5vh;
}

/* Responsive */

@media screen and (max-width: 900px) {
    .form-buttons {
        width: 100%;
        margin: 0;
        border-radius: 10px 10px 0 0;
    }
}

#AdminPreviewChallengeRessources {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
    /* width: 80%; */
    margin: auto;
}

#AdminPreviewChallengeRessources .bloc-title {
    width: 80%;
    margin: auto;
}

#AdminPreviewChallengeRessources .bloc-ressources {
    width: 60%;
    margin: 3rem auto auto;
}

#AdminPreviewChallengeRessources .bloc-ressources p {
    text-align: center;
}

#AdminPreviewChallengeRessources .bloc-title h1 {
    color: var(--primary90, #122236);
    text-align: center;
    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 0.2em;
}

#AdminPreviewChallengeRessources .bloc-title p {
    color: var(--primary90, #122236);
    text-align: center;
    font-family: var(--font-secondary);
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

@media screen and (max-width: 900px) {
    #AdminPreviewChallengeRessources .bloc-ressources {
        width: 90%;
    }
}
.admin-challenge-suivi-teams {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-top: 3rem;
}

.container-content-suivi-teams {
    width: 80%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.container-list-teams {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.container-team-content {
    width: 30vw;
    background-color: var(--primary10);
    padding: 20px 30px;
    border-radius: 10px;
}

.container-team-content-teams {
    background-color: var(--primary10);
    display: flex;
    flex-direction: column;
    gap: .5rem;
    margin-top: 1rem;
}

.container-team-content h2 {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 24px;
    font-weight: 700;
}

.container-team-content h3 {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 0;
}

.container-team-content p {
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 14px;
    font-weight: 400;

}

.chip-user {
}

.block-hr {
    width: 30%;
    border: none;
    height: 12px;
    background-color: var(--primary30);
    margin: 2rem auto;
}

.button-share-resource {
    margin-top: 1rem;
}

.modal-share-resource-block-form {
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.modal-share-resource-field {
   /* border: 1px solid green; */
}

.modal-share-resource-field label {
    text-align: left;
    font-weight: bold;
    width: 100%;
}
.icon-resource{
    margin-left: 0.5rem;
}
.icon-resource svg{
    width: 15px;
    height: 15px;
}
@media screen and (max-width: 900px) {
    .container-content-suivi-teams {
        width: 100%;
    }

    .container-list-teams {
        flex-direction: column;
    }

    .container-team-content {
        width: 100%;
    }
}

.admin-infos-challenge {
    width: 100%;
}

.admin-infos-challenge .center-text {
    text-align: center;
}

.admin-infos-challenge .none-mobile {
    display: none;
}

.admin-infos-challenge .sticky-nav-bar-mobile {
    display: block;
    z-index: 2;
    margin-bottom: 2rem;
}

.admin-infos-challenge .sticky-nav-bar-desktop {
    display: none;
    z-index: 6;
}

.admin-infos-challenge .go-back-tablet {
    display: none;
}

.admin-infos-challenge .go-back-title {
    display: none;
}

.admin-infos-challenge .logo-challenge {
    width: 10rem;
    height: 10rem;
    padding-left: 10rem;
    padding-top: 10rem;
    border-radius: 10px;
    margin: 0 auto;
    margin-top: 2rem;
    margin-bottom: -1em;
}


@media (min-width: 480px) {
    .admin-infos-challenge .button-go-back {
        position: absolute;
        margin-top: 1rem;
    }

    .admin-infos-challenge .go-back-tablet {
        display: block;
        padding-right: 5rem;
    }
}

@media (min-width: 1024px) {

    .admin-infos-challenge .title-button {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        align-items: center;
        margin-top: 2rem;
    }

    .admin-infos-challenge .go-back-title {
        display: block;
    }

    .admin-infos-challenge .button-go-back {
        position: absolute;
        margin-top: -3rem;
        margin-left: -1rem;
    }

    .admin-infos-challenge .go-back-tablet {
        display: none;
    }

    .admin-infos-challenge .none-mobile {
        display: block;
    }

    .admin-infos-challenge .sticky-nav-bar-mobile {
        display: none;
    }

    .admin-infos-challenge .header {
        display: flex;
        flex-direction: column-reverse;
        background-color: var(--primary00);
    }

    .admin-infos-challenge .sticky-nav-bar-desktop {
        position: -webkit-sticky;
        position: sticky;
        top: 12vh;
        display: block;
        background-color: var(--primary00);
        padding: 1rem 0;
        z-index: 6;
    }

    .admin-infos-challenge .logo-challenge {
        width: 10rem;
        height: 10rem;
        padding-left: 10rem;
        padding-top: 10rem;
        border-radius: 10px;
        margin: 0;
    }

    .admin-infos-challenge .header .challenge-infos {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;
        width: 95%;
    }

    .admin-infos-challenge .header .challenge-infos .state {
        margin: 0;
        margin-bottom: 0.5rem;
    }

    .admin-infos-challenge .challenge-infos .text,
    .admin-infos-challenge .challenge-infos .h1 {
        text-align: left !important;
    }

    .admin-infos-challenge .img-background {
        margin-bottom: 0 !important;
    }

    .admin-infos-challenge .edit-buttons {
        margin: 0;
        padding: 0;
        max-width: 30vw;
    }

    .admin-infos-challenge .challenge-infos .state {
        margin-top: 0 !important;
    }

    .admin-infos-challenge .challenge-infos .text {
        margin-bottom: 0 !important;
    }

}

.admin-infos-challenge .admin-infos-challenge-container {
    width: 100%;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.admin-infos-challenge .admin-infos-challenge-content {
    width: 95%;
}

.challenge-infos-container {
    width: 100%;
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 900px) {
    .challenge-infos-container {
        margin-top: 2rem;
    }
}

/* general */

.admin-challenge-menu-tabs-container {
    width: 100%;
    margin-top: 2vh;
    display: flex;
    justify-content: center;
}

.admin-challenge-menu-tabs-container-sticky {
    width: 100%;
    margin-top: 2vh;
    display: flex;
    justify-content: center;
}

.admin-challenge-menu-tabs-content {
    width: 95%;
}

.admin-infos-challenge .edit-buttons {
    border-radius: 13px;
    transition: 0.2s ease-in-out;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 0.5rem;
}

.admin-infos-challenge .edit-buttons button {
    margin: 0 auto;
}

.admin-infos-challenge button {
    max-width: 30rem;
}

.admin-infos-challenge .challenge-infos .state {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
    width: fit-content;
    background-color: var(--primary10);
    padding: 0.3rem 0.6rem;
    border-radius: 40px;
    margin: 0 auto;
    margin-top: 3rem;
    margin-bottom: 1rem;
}

.admin-infos-challenge .challenge-infos h1 {
    text-align: center;
    margin-bottom: 0.5rem;
}

.admin-infos-challenge .challenge-infos .text {
    text-align: center;
    margin-bottom: 1rem;
}

.admin-infos-challenge .img-background {
    width: 100%;
    height: 35vh;
    margin-bottom: 1.5rem;
}

.admin-infos-challenge .is-public {
    background-color: var(--primary10);
    padding: 1rem 1.5rem;
    border-radius: 10px;
    margin: 0 auto;
    margin-bottom: 3rem;
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
}

.admin-infos-challenge .bold {
    font-weight: 600;
}

.admin-infos-challenge .publication {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 3rem;
    margin-top: 2rem;
}

.admin-infos-challenge .dates-previsionnelles {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    margin-bottom: 1rem;
}

.admin-infos-challenge .dates-previsionnelles .dates {
    display: flex;
    gap: 0.5rem;
}

.admin-infos-challenge .dates-previsionnelles .dates .debut,
.admin-infos-challenge .dates-previsionnelles .dates .fin {
    display: flex;
    flex-direction: column;
    gap: 0.3rem;
    width: 100%;
}

.admin-infos-challenge .dates-previsionnelles .dates .debut .text,
.admin-infos-challenge .dates-previsionnelles .dates .fin .text {
    display: flex;
    gap: 0.3rem;
    align-items: center;
    width: 100%;
    background-color: var(--primary10);
    padding: 0.5rem 1rem;
    border-radius: 8px;
}

.admin-infos-challenge .dates-previsionnelles .alert {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    background-color: var(--error10);
    padding: 1rem;
    border: 2px solid var(--error50);
    border-radius: 10px;
    align-items: center;
    text-align: center;
    max-width: 30rem;
    margin: 0 auto;
}

.admin-infos-challenge .dates-previsionnelles .alert svg path {
    fill: var(--error50);
}

.admin-infos-challenge .text-italic {
    font-style: italic;
    color: var(--primary50);
}

.admin-infos-challenge .infos-generales {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 3rem;
}

.admin-infos-challenge .infos-generales .h2 {
    text-align: left;
}

.admin-infos-challenge .infos-generales div .h2 {
    text-align: left;
}

@media screen and (max-width: 900px) {
    .admin-infos-challenge .infos-generales .h2 {
        text-align: center;
    }

    .admin-infos-challenge .infos-generales div .h2 {
        text-align: center;
    }

    .admin-infos-challenge .infos-generales .text {
        text-align: center;
    }
}

.admin-infos-challenge .infos-generales div {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.admin-infos-challenge .infos-generales .modalites {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.admin-infos-challenge .infos-generales .modalites .title-eligibilities{
    text-align: left;
    font-size: 16px;
}
.admin-infos-challenge .infos-generales .modalites .eligibility{
    text-align: left;
    font-size: 14px;
}
.admin-infos-challenge .infos-generales .modalites .no-eligibilities{
    text-align: left;
    font-size: 14px;
    font-style: italic;
}

.admin-infos-challenge .infos-generales .all-modalites {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}

.admin-infos-challenge .infos-generales .all-modalites {
    display: flex;
    flex-direction: row;
}

.admin-infos-challenge .infos-generales .all-modalites div {
    width: 100%;
    background-color: var(--primary10);
    padding: 0.5rem 1rem;
    display: flex;
    align-items: center;
    border-radius: 8px;
    justify-content: center;
    text-align: center;
    gap: 0;
    min-height: 6rem;
}

.admin-infos-challenge .infos-generales .all-modalites div .bold.number {
    font-size: 1.25rem;
}

.admin-infos-challenge .infos-generales .all-modalites div .nb-participants {
    background-color: var(--primary00);
    margin: 0 auto;
    margin-top: 0.5rem;
    padding: 0.1rem 0.5rem;
    border-radius: 30px;
}

.admin-infos-challenge .infos-generales .reglement {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.admin-infos-challenge .infos-generales .reglement a {
    background-color: var(--primary10);
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 0.5rem 1rem;
    border-radius: 10px;
    text-decoration: none;
    color: var(--primary90);
}

.admin-infos-challenge .infos-generales .reglement a svg path {
    transition: 0.3s ease;
    fill: var(--primary50)
}

.admin-infos-challenge .infos-generales .reglement a:hover svg path {
    fill: var(--primary90);
    transition: 0.3s ease;
}

.admin-infos-challenge .list {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.admin-infos-challenge .list .h1 {
    text-align: center;
    margin-bottom: 1rem;
}

.admin-infos-challenge .ressource {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    background-color: var(--primary10);
    padding: 0.5rem 1rem;
    border-radius: 10px;
    text-decoration: none;
    color: var(--primary90);
    margin-bottom: 0.5rem;
    width: 100%;
}

.admin-infos-challenge .ressource .texts {
    display: flex;
    flex-direction: column;
    gap: 0rem;
}

.admin-infos-challenge .ressource svg path {
    stroke: var(--primary50);
    transition: 0.3s ease;
}

.admin-infos-challenge .ressource:hover svg path {
    stroke: var(--primary90);
    transition: 0.3s ease;
}

.admin-infos-challenge .defi {
    background-color: var(--primary10);
    padding: 1.5rem 2rem;
    border-radius: 10px;
    display: flex;
    gap: 1rem;
    flex-direction: column;
    width: 100%;
}

.admin-infos-challenge .prix {
    display: flex;
    flex-direction: column;
    background-color: var(--primary10);
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
}

.admin-infos-challenge .prix .img {
    width: 15vh;
    height: 15vh;
    padding-left: 15vh;
    padding-top: 15vh;
}

.admin-infos-challenge .prix .texts-img {
    display: flex;
    align-items: center;
    gap: 1rem;
}

.admin-infos-challenge .prix .text {
    padding: 1rem 2rem;
}

.admin-infos-challenge .prix .bold {
    padding-right: 2rem;
}


.admin-infos-challenge .partenaire {
    display: flex;
    flex-direction: column;
    max-width: 15rem;
    margin: 0 auto;

}

.admin-infos-challenge .partenaires-cards {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(15rem, 1fr));
    gap: 1rem;
    /* Espace entre les cartes, ajustez selon vos besoins */
    max-width: 100%;
    margin: 0 auto;
    width: 100%;
}

.admin-infos-challenge .partenaire {
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
}

.admin-infos-challenge .partenaire .texts {
    background-color: var(--primary10);
    padding: 1rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border-radius: 0 0 10px 10px;
}

.admin-infos-challenge .partenaire .texts p {
    font-size: 1rem;
}

.admin-infos-challenge .partenaire .texts a {
    text-decoration: none;
    font-style: italic;
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    font-size: 0.9rem;
    color: var(--actionprimary50);
}

.admin-infos-challenge .partenaire .texts .liens {
    color: var(--actionprimary50);
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-size: 0.5rem;
}

.admin-infos-challenge .partenaire .texts a svg path {
    stroke: var(--actionprimary50);
}

.admin-infos-challenge .partenaire .img {
    height: 20vh;
    width: 100%;
    padding-top: 100%;
    /* Crée un ratio de 1:1 pour l'image */
}

.admin-infos-challenge .questions {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
}

.admin-infos-challenge .questions svg path {
    stroke: var(--primary50);
    transition: 0.3s ease;
}

.admin-infos-challenge .bold:hover svg path {
    stroke: var(--primary90);
    transition: 0.3s ease;
}

.admin-infos-challenge .questions .bold {
    background-color: var(--primary10);
    border-radius: 10px;
    padding: 0.5rem 1rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.admin-infos-challenge .questions .bold:hover {
    cursor: pointer;
}

.admin-infos-challenge .questions .text {
    padding: 0.5rem 1rem;
    margin-bottom: 1rem;
}

.admin-infos-challenge .questions .rotate svg {
    transition: 0.5s ease;
    transform: rotate(0);
}

.admin-infos-challenge .questions .rotate svg {
    transform: rotate(-180deg);
    transition: 0.5s ease;
}

.admin-infos-challenge-general .modal {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);
}

.admin-infos-challenge-general .modal .text {
    max-width: 30rem;
}

.admin-infos-challenge-general .modal .modal-content {
    background-color: var(--primary00);
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100vw;
    padding: 2.5rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    align-items: center;
    border-radius: 30px 30px 0 0;
    box-shadow: var(--menu);
}

.admin-infos-challenge-general .modal .modal-content.unable {
    border: 2px solid var(--error50);
}

.admin-infos-challenge-general .modal .modal-content ul {
    width: auto;
    margin-left: -2rem;
}

.admin-infos-challenge-general .modal .modal-content svg path {
    stroke: var(--actionprimary50);
}

.admin-infos-challenge-general .modal .modal-content.error svg path {
    stroke: var(--error50);
}

.admin-infos-challenge-general .modal .modal-content.error .text.error {
    color: var(--error50);
    font-weight: 600;
}

.admin-infos-challenge-general .modal .modal-content .h2 {
    text-align: center;
}

.admin-infos-challenge-general .modal .modal-content .recap {
    max-width: 20rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
}

.admin-infos-challenge-general .modal .modal-content .recap .text {
    text-align: center;
}

.admin-infos-challenge-general .modal .modal-content .recap ul {
    width: fit-content;
    margin: 0 auto;
}

.admin-infos-challenge-general .modal .modal-content .recap ul li.text {
    text-align: left;
    width: fit-content;
}

.admin-infos-challenge-general .modal .modal-content .buttons {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    margin-top: 2rem;
    width: 100%;
}

.admin-infos-challenge-general .modal .modal-content.unable .buttons button {
    width: 100%;
    margin: 0 auto;
}

.admin-infos-challenge-general .modal .modal-content .buttons button {
    width: 50%;
}


@media screen and (min-width: 480px) {

    .admin-infos-challenge .img-background {
        width: 100%;
        height: 35vh;
        margin-bottom: 1.5rem;
    }

    .admin-infos-challenge-general {
        max-width: 50rem;
        margin: 3rem auto;
    }

    .admin-infos-challenge .partenaires-cards {
        grid-template-columns: repeat(auto-fit, minmax(12rem, 1fr));
    }

    .admin-infos-challenge .partenaire .texts p {
        font-size: 1rem;
    }

    .admin-infos-challenge .partenaire .texts a {
        font-size: 0.8rem;
    }

    .admin-infos-challenge-general .modal {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .admin-infos-challenge-general .modal .modal-content {
        background-color: var(--primary00);
        position: relative;
        width: fit-content;
        padding: 2.5rem 2rem;
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: center;
        border-radius: 30px;
        box-shadow: var(--menu);
        margin: 4rem;
    }

}

@media screen and (min-width: 1024px) {
    .admin-infos-challenge-general {
        display: flex;
        max-width: none;
        gap: 4rem;
    }

    .admin-infos-challenge .dates-previsionnelles {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 3rem;
    }

    .admin-infos-challenge-general .h2,
    .admin-infos-challenge-general .h1 {
        margin-bottom: 0.5rem;
    }

    .admin-infos-challenge-general .left {
        width: 45%;
    }

    .admin-infos-challenge button {
        max-width: none;
    }

    .admin-infos-challenge-general .right {
        width: 55%;
    }
}

.admin-infos-challenge-general .right {
    display: flex;
    flex-direction: column;
    gap: 3rem;
}

.nav-tabs {
    display: none;
}

@media screen and (max-width: 900px) {
    .admin-infos-challenge .outlet-nav-bar-slider {
        display: none;
    }

    .nav-tabs {
        display: block;
    }
}

.title {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
}

.title-admin-challenge {
    display: flex;
    flex-direction: column;
    align-items: start;
    margin-bottom: 1rem;
}

@media screen and (max-width: 900px) {
    .title-admin-challenge {
        align-items: center;
    }
}

.live-challenge {
    background-color: var(--primary10);
    max-width: 30rem;
    margin-bottom: 3rem;
    padding: 1rem;
    border-radius: 10px;
}

.live-challenge-title {
    font-size: 16px;
    font-weight: bold;
    text-align: left;
}

.live-challenge-description {
    font-size: 14px;
    text-align: left;
}

.live-challenge .add-button {
    margin-top: 1rem;
    min-width: 100%;
}

.card-name {
    background-color: var(--primary10);
    border-radius: 10px;
    padding: 2rem;
    display: flex;
    align-items: center;
    gap: 3rem;
}

.card-pp {
    border-radius: 50%;
    width: 10rem;
    height: 10rem;
}

.card-name-text p {
    font-size: 2rem;
    font-weight: 700;
    width: 100%;
    word-break: break-word;
}

@media screen and (max-width: 900px) {
    .card-name {
        flex-wrap: wrap;
        justify-content: center;
        gap: 1.5rem;
    }

    .card-name-text p {
        text-align: center;
    }
}
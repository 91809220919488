.partner{
    border-radius: 10px;
    border: 1px solid var(--primary50);
}

.partner > div{
    padding: 0.62rem;
}

.partner > div:not(:last-child){
    border-bottom: 1px solid var(--primary50);
}

.partner-title div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.partner-title .icons{
    display: flex;
    gap: 0.5rem;
}

.partner .category-title{
    margin-bottom: 0.31rem;
}

.partner-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.partner-header > .partner-title{
    width: 100%;
}

.partner-image img{
    min-width: 20vh;
    min-height: 20vh;
}

.partner-image .image-uploader{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
    align-items: center;
}

.partner .icon-clickable:hover{
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease-in-out;
}

.partner input.input-editable,.partner textarea.input-editable{
    border: none;
    outline: none;
    width: 100%;
    resize: none !important;
}

.partner-link span.unbold{
    font-weight: 400;
}
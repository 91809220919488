.dare{
    border-radius: 10px;
    border: 1px solid var(--primary50);
}

.dare > div{
    padding: 0.62rem;
}

.dare > div:not(:last-child){
    border-bottom: 1px solid var(--primary50);
}

.dare-title div{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dare-title .icons{
    display: flex;
    gap: 0.5rem;
}

.dare .category-title{
    margin-bottom: 0.31rem;
}

.dare-header{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dare-header > .dare-title{
    width: 100%;
}

.dare .icon-clickable:hover{
    cursor: pointer;
    opacity: 0.6;
    transition: 0.2s ease-in-out;
}

.dare input.input-editable,.dare textarea.input-editable{
    border: none;
    outline: none;
    width: 100%;
    resize: none !important;
}

.dare .thin{
    font-weight: 400;
}
.card-my-participation{
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.card-my-participation .content{
    background-color: white;
    padding: 12px;
    border-radius: 10px;
    position: relative;
}
.card-my-participation .content p{
    color: var(--primary90);
    font-family: var(--font-secondary);
    font-size: 14px;
    font-weight: 600;
}
.card-my-participation .icon-team{
    position: absolute;
    bottom: 1rem;
    right: 1rem;
}
@media screen and (max-width: 500px){
    .card-my-participation .icon-team{
        top: 1rem;
    }
}
#detail-project {
    margin-bottom: 3vh;
    width: 100%;
}
.bloc-info-project-challenge {
    padding-top: 2em!important;
    padding-bottom: 2em!important;
}

#bloc-info-project-challenge {
    background-color: var(--primary10);
    padding-top: 1.5vh;
    padding-bottom: 1.5vh;
    padding-left: 3rem;
    padding-right: 3rem;
}

#detail-project #bloc-project-profile {
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 3rem;
    padding-right: 3rem;
}
#detail-project #bloc-project-profile h2{
    padding-bottom: 2vh;
}
#detail-project #bloc-project-team {
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 3rem;
    padding-right: 3rem;
}

#detail-project #bloc-project-team h4{
    padding-bottom: 2vh;
}

/* #detail-project h4{
    color: var(--primary90);
    font-family: Barlow;
    font-size: 1rem;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
} */

#detail-project #bloc-choice-subchallenge {
    padding-top: 1vh;
    padding-bottom: 2vh;
    padding-left: 2rem;
    padding-right: 2rem
}

#detail-project #bloc-project-breakthrough {
    padding-top: 1vh;
    padding-bottom: 2vh;
    padding-left: 2rem;
    padding-right: 2rem
}

@media screen and (min-width: 900px) {
    #bloc-info-project-challenge {
        background-color: var(--primary30);
        padding-top: 1.5vh;
        padding-bottom: 1.5vh;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    #detail-project #bloc-project-profile h2{
        padding-bottom: 3vh;
    }
    #detail-project #bloc-project-team h2{
        padding-bottom: 3vh;
    }
    #detail-project #bloc-project-profile {
        padding-top: 3vh;
        padding-bottom: 3vh;
        padding-left: 3rem;
        padding-right: 3rem;
    }

    #detail-project #bloc-project-team {
        padding-top: 3vh;
        padding-bottom: 3vh;
        padding-left: 3rem;
        padding-right: 3rem;
    }
    #detail-project #bloc-choice-subchallenge {
        padding-top: 0vh;
        padding-bottom: 3vh;
        padding-left: 3rem;
        padding-right: 3rem
    }
    #detail-project #bloc-project-breakthrough {
        padding-top: 0vh;
        padding-bottom: 3vh;
        padding-left: 3rem;
        padding-right: 3rem
    }
}

#bloc-project-profile .bloc-info-profile-participation {
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 10px;
    border: 2px solid var(--primary10);
}

#bloc-project-team .bloc-info-profile-participation {
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 10px;
    border: 2px solid var(--primary10);
}

.bloc-info-profile-participation .text-end.teamButtons {
    display: flex;
    flex-direction: column;
    align-items: end;
    gap: .25rem;
}

#detail-project h4 {
    color: var(--primary90);
    font-family: Barlow;
    font-style: normal;
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
}


#detail-project h3 {
    color: var(--primary90);
    font-family: Barlow;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
}

/* @media screen and (min-width: 900px) {
    #detail-project h3 {
        color: var(--primary90);
        font-family: Barlow;
        font-size: 1.125rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
    }
    #detail-project h4{
        color: var(--primary90);
        font-family: Barlow;
        font-size: 1rem;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }
} */

#bloc-choice-subchallenge .icon-pen {
    width: 20px;
}

#bloc-choice-subchallenge .icon-pen path{
    fill:#828D9B;
}
#bloc-choice-subchallenge .icon-pen:hover{
    transform: scale(1.1);
}

#bloc-choice-subchallenge .choice-subchallenge{
    background-color: var(--primary10);
    padding: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    border-radius: 10px;
}

#bloc-choice-subchallenge .bold-600 {
    font-weight: 600;
}


#detail-project #bloc-project-subchallenge {
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 2rem;
    padding-right: 2rem;
}


#detail-project #bloc-project-subchallenge h4{
    border-radius: 40px;
    border: 2px solid var(--Magenta50, #B00362);
    background: var(--White, #FFF);
    display: inline-block;
    padding: 0.3em;
    padding-left: 0.5em;
    padding-right: 0.5em;
    color: var(--primary90);
    font-family: Ubuntu;
    font-style: normal;
    font-weight: 700;
    line-height: 100%;
}

#detail-project #bloc-info-project-challenge p span {
    font-weight: 700;
}

#detail-project .bloc-step-running-tobe-complete {
    width: 90%;
    margin: auto;
    margin-top: 2vh;
    margin-bottom: 2vh;
}

#detail-project .info-resource {
    color: var(--primary50);
    font-family: Barlow;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
}

#detail-project .info-resource span{
    color: var(--primary50);
    font-family: Barlow;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    cursor: pointer;
}

#detail-project .main_project_img {
    aspect-ratio: 1200/350;
    background-size: cover;
    width: 100%;
    border-radius: 10px;
}

#detail-project .bloc-info-deliverable {
    background-color: var(--primary10);
    border-radius: 10px;
    margin-bottom: 1vh;
    padding: 0.75em;
}

#detail-project #info-attachment p {
    color: var(--primary90);
    text-align: center;
    font-family: Barlow;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}
#detail-project #info-attachment .limit {
    color: var(--primary90);
    text-align: center;
    font-family: Barlow;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#detail-project #section-collapse-steps-project {
    padding-top: 2vh;
    padding-bottom: 2vh;
    padding-left: 3rem;
    padding-right: 3rem;
}

#detail-project .default {
    background-color: var(--primary10);
}
#detail-project #bloc-project-subchallenge.warning {
    background-color: var(--error10);
    padding-top: 7vh;
    padding-bottom: 7vh;
}

#detail-project #title_main_picture span {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

#detail-project #title_main_picture .error_main_picture {
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

#detail-project .deliverable_necessarily {
    color: rgba(0, 0, 0, 0.6);
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}

#detail-project .error_deliverable_empty {
    color: #d32f2f;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.66;
    letter-spacing: 0.03333em;
    text-align: left;
    margin-top: 4px;
    margin-right: 14px;
    margin-bottom: 0;
    margin-left: 14px;
}


#detail-project .question-default-compact {
    background-color: var(--primary10);
}

#detail-project .bloc-info-project-edit {
    /* margin:3rem; */
    margin-left: 3rem;
    margin-right: 3rem;
    margin-bottom: 3rem;
    /* margin-top: 2rem; */
    padding: 1rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border: 2px var(--primary10) solid;
    border-radius: 10px;
    text-align: justify;
}

#detail-project .bloc-info-project-edit .image-preview {
    border-radius: 10px;
}

#detail-project .bloc-info-project-edit .icon-edit svg {
    cursor: pointer;
}

#detail-project .bloc-info-project-edit .icon-edit svg:hover {
    transform: scale(1.1);
}

#detail-project .bloc-info-project-edit .icon-edit svg path {
    fill: var(--primary50);
}

#detail-project .bloc-info-project-edit-open {
    margin:3rem;
    margin-top: 2rem;
    /* padding: 0.5rem; */
    /* padding-top: 1rem; */
    padding-bottom: 1rem;
    /* border: 2px var(--primary10) solid; */
    border-radius: 10px;
    word-break: break-all;
}

#detail-project .presentation {
    margin-left: 3rem;
    margin-right: 3rem;
    margin-top: 2rem;
    margin-bottom: 1rem;
}

#detail-project .iconWarn path {
    fill: var(--actionprimary50);
}

.ckeditor-container-normal .ck-editor__editable{
    min-height: 3em;
}
.ckeditor-container-short-desc .ck-editor__editable{
    min-height: 4.5em;
}
.ckeditor-container-long-desc .ck-editor__editable{
    min-height: 8em;
}

.error-ckeditor{
    border: 1px solid red
}

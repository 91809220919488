.action-button {
    border: none;
    position: relative;
    transition: all 0.2s ease-in-out;
    width: 100%;
    padding: 12px 15px 12px 15px;
}

.action-button p {
    text-align: center;
    font-weight: 500;
    z-index: 1;
    position: relative;
}

.primary {
    position: relative;
    background: var(--actionprimary10);
    z-index: 5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.primary .after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    background: var(--gradient);
    border-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.primary span {
    position: relative;
    z-index: 2;
    color: var(--actionprimary10);
}

.primary::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 10px;
    background: var(--gradient);
    opacity: 0;
}

.primary:hover::before {
    border-style: solid;
    border-width: 2px;
    border-image: var(--gradient) 1;
    border-radius: 10px;
    mask: var(--gradient) padding-box, var(--gradient);
    -webkit-mask: var(--gradient) padding-box, var(--gradient);
    mask-composite: exclude;
    opacity: 1;
}

.primary:hover .after {
    opacity: 0;
}

.primary:hover span {
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}


.secondary{
    position: relative;
    background-color: var(--actionprimary50);
    color: white;
    font-weight: 500;
    z-index: 5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.secondary:hover{
    color: var(--actionprimary50);
    background-color: var(--actionprimary10);
}


.action-button-icon{
    display: flex;
    align-items: center;
    z-index: 5;
    margin-right: .5rem;
}

.icon-text-end {
    flex-direction: row-reverse;
}
.icon-text-end .action-button-icon {
    margin-right: 0;
    margin-left: .5rem;
}
.icon-text-end {
    flex-direction: row;
}
.icon-text-end .action-button-icon {
    margin-right: .5rem;
    margin-left: 0;
}
.icon-start{
    left: 1rem;
    position: absolute;
}
.icon-end{
    right: 1rem;
    position: absolute;
}
.icon-primary svg path{
    fill: var(--primary00);
    transition: all 0.2s ease-in-out;
}
.primary:hover .icon-primary svg path{
    fill: url(#gradient);
}
.icon-secondary svg path{
    fill: white;
    transition: all 0.2s ease-in-out;
}
.secondary:hover .icon-secondary svg path{
    fill: var(--actionprimary50);
}
.grey{
    background-color: var(--primary10);
    border-radius: 10px;
}
.grey:hover{
    background-color: var(--primary30);
}
.grey path {

}

.disabled{
    border-radius: 10px;
    /* pointer-events: none; */
    background-color: var(--primary30);
    color: var(--primary50);
    user-select: none;
    display: flex;
    justify-content: center;
    align-items: center;
}

.little-underline{
    position: relative;
    color: var(--actionprimary50);
    width: fit-content;
    padding: 0;
    background-color: transparent;
    font-weight: 500;
    border-bottom: 1px solid var(--actionprimary50);
    line-height: 18px;
    transition: all 0.2s ease-in-out;
}
.little-underline-disabled {
    position: relative;
    color: grey;
    width: fit-content;
    padding: 0;
    background-color: transparent;
    font-weight: 500;
    border-bottom: 1px solid grey;
    line-height: 18px;
    transition: all 0.2s ease-in-out;
    pointer-events: none;
}
.little-underline:hover{
    color: var(--error50);
    border-color: var(--error50);
}

.little-underline .icon-end{
    top: 2px;
    right: -1.5rem;
    position: absolute;
    fill: var(--actionprimary50);
    transition: all 0.2s ease-in-out;
    height: 18px;
}

/* TODO - modifier en fill */
.little-underline .icon-end{
    width: 8px;
}
.little-underline .icon-end path{
    stroke: var(--actionprimary50);
}

.little-underline:hover path{
    stroke: var(--error50);
}
/* TODO - modifier en fill */

.see-project{
    position: relative;
    color: var(--actionprimary50);
    width: fit-content;
    padding: 0;
    background-color: transparent;
    font-weight: 500;
    line-height: 18px;
    transition: all 0.2s ease-in-out;
    margin-right: 1rem  ;
}
.see-project .icon-end{
    position: absolute;
    right: -1.5rem;
    width: .5rem;
}
.see-project .icon-end svg path{
    stroke: var(--actionprimary50);
    transition: all 0.2s ease-in-out;
}
.see-project:hover{
    color: var(--error50);
}
.see-project:hover svg path{
    stroke: var(--error50);
}
.saumon50 svg path{
    stroke: var(--actionprimary50);
}
.component-modal-content-body{
    display: flex;
    flex-direction: column;
    gap: 15px;
}
.component-modal-content-body p{
    font-size: 16px;
}
.component-modal-content-body span{
    font-weight: bold;
}
.tertiary{
    background-color: var(--actionprimary10);
    border-radius: 10px;
    color: var(--actionprimary50);
    border: 2px solid transparent;
    transition: border .2s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
}
.tertiary svg path{
    /*stroke: var(--actionprimary50); Si besoin pour certains SVG*/
    fill: var(--actionprimary50);
}
.tertiary:hover{
    border: 2px solid var(--actionprimary50);
}


.deselect {
    position: relative;
    z-index: 5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    background-color: transparent;
    /* background: var(--White, #FFF); */
    color: var(--primary90);
    font-family: var(--font-primary);
    transition: all .2s ease-in-out;
    border: 1px solid var(--primary30, #D9E0EA);
}

.deselect:hover {
    border: 1px solid transparent;
    background: var(--actionprimary50);
    color: white;
}

.deselect:hover .after {
    opacity: 0;
}

.choice {
    position: relative;
    z-index: 5;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    /* background: var(--White, #FFF); */
    background: var(--actionprimary50);
    color: var(--primary00);
}


.primary-card {
    position: relative;
    background: var(--actionprimary10);
    z-index: 5;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
}

.primary-card .after {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    opacity: 1;
    background: var(--gradient);
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    transition: all 0.2s ease-in-out;
}

.primary-card span {
    position: relative;
    z-index: 2;
    color: var(--actionprimary10);
}

.primary-card::before {
    content: "";
    position: absolute;
    inset: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    background: var(--gradient);
    mask-composite: exclude;
    opacity: 0;
}

.primary-card:hover::before {
    border-style: solid;
    border-width: 2px;
    border-image: var(--gradient) 1;
    /* border-radius: 10px; */
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    mask: var(--gradient) padding-box, var(--gradient);
    -webkit-mask: var(--gradient) padding-box, var(--gradient);
    opacity: 1;
}

.primary-card:hover .after {
    opacity: 0;
}

.primary-card:hover span {
    background: var(--gradient);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.disabled-card{
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    pointer-events: none;
    background-color: var(--primary30);
    color: var(--primary50);
    user-select: none;
}

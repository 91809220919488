.container-app-logout .portfolio {
    margin-top: 6rem;
}

.portfolio {
    width: 100%;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.portfolio .portfolio-userDetails {
    display: flex;
    gap: 2rem;
    flex-direction: column;
    align-items: center;
}

.portfolio .portfolio-userDetails .portfolio-userDetails-picture {
    border-radius: 50%;
    overflow: hidden;
    width: 10rem;
    height: 10rem;
}

.portfolio .portfolio-userDetails .portfolio-userDetails-picture img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.portfolio .portfolio-projects .portfolio-projects-singleProject{
    height: 100%;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    transition: all 0.2s ease;
}

.portfolio .portfolio-projects .portfolio-projects-singleProject:hover{
    filter: brightness(80%);
}


.portfolio .portfolio-projects .portfolio-projects-singleProject .portfolio-projects-singleProject-coverImg img{
    width: 100%;
    object-fit: contain;
}

.portfolio .portfolio-projects .portfolio-projects-singleProject .portfolio-projects-singleProject-challengeName{
    background-color: var(--primary90);
    color: white;
    padding: 1rem;
    border-radius: 20px 20px 0 0;
    display: flex;
    align-content: center;
    height: 100%;
    flex: 1;
}

.portfolio .portfolio-projects .portfolio-projects-singleProject .portfolio-projects-singleProject-projectInfos{
    background-color: var(--primary10);
    border-radius: 0 0 20px 20px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    height: 100%;
}



.portfolio-empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    gap: 3rem;
}

.portfolio .portfolio-empty .portfolio-empty-txt {
    font-size: larger;
}

.portfolio .portfolio-empty .portfolio-empty-img {
    max-width: 20vw;
}

@media screen and (max-width: 900px) {
    .portfolio .portfolio-empty .portfolio-empty-txt {
        font-size: medium;
    }

    .portfolio .portfolio-empty .portfolio-empty-img {
        max-width: 40vw;
    }
}
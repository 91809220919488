.admin-challenge-planning{
    width:  100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 2rem;
}
@media screen and (max-width: 900px) {
    .admin-challenge-planning{
        width: 100%;
    }
}
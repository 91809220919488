body:has(.password-reset-done) {
    height: 100%;
    overflow: hidden;
}

.password-reset-done {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: fit-content;
    text-align: center;
    width: 100%;
    margin-top: 15vh;
}

.password-reset-done .password-reset-header {
    display: flex;
    position: fixed;
    top: 0;
    margin: auto;
    margin-top: 5.46vh;
    width: 100%;
}

.password-reset-done-section-container{
    height: 100vh;
    margin-top: -5rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 5.3rem;
}

.password-reset-done-main{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 3.4rem;
}

.password-reset-done-main > div{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1.6rem;
}

.password-reset-done .italic {
    font-style: italic;
}

.password-reset-done .link {
    color: var(--actionprimary50);
    border-bottom: 1px solid var(--actionprimary50);
    text-decoration: none;
    white-space: nowrap;
}

.email-sent-text{
    color: var(--secondary50);
}

@media screen and (min-width: 768px){
    .makee-diamond-login-reset-password{
        display: none;
    }

    .password-reset-done{
        margin-top: 20vh;
    }

    .password-reset-done .password-reset-header {
        position: fixed;
        top: 11vh;
    }

    .password-reset-done .go-back{
        display: none;
    }

    .password-reset-done .form-header h1 {
        width: 60%;
        margin: auto;
    }

    .password-reset-done .form-header p {
        width: 50%;
        margin: auto;
    }

    .password-reset-done .form-content {
        width: 60%;
        margin: auto;
    }

    .password-reset-done-section-container{
        margin: 0;
    }

    .password-reset-done .password-reset-done>footer>a {
        position: absolute;
        bottom: 2rem;
        right: 36rem;
    }

    .diamond-reset-done{
        position: fixed;
        bottom: 3rem;
        height: 16.25vh;
    }
}
/* page  globale (container) */
.create-account {
    margin-top: 1rem;
    text-align: center;
}

/* ------------------------------------------- */

/* --------------- informations -------------- */

/* ------------------------------------------- */

/* progress bar */
.create-account .progress-bar {
    position: relative;
    margin-bottom: 5rem;
}

/* logo au dessus des pages */
.create-account .diamond-login {
    margin: 2rem auto;
}

div.loginHeader2 {
    display: none;
}

/* texte au dessus du formulaire */
.create-account .text-top {
    margin-bottom: 1rem;
}

/* liens pour associer son compte à un compte existant */
.create-account .text-top a {
    color: var(--actionprimary50);
}

/* formulaire pour renseigner ses informations */
.create-account form {
    display: flex;
    flex-direction: column;
}

/* butons de validation de formulaire */
.create-account form .buttons {
    display: flex;
    gap: 0.5rem;
}

.create-account form .buttons button {
    width: 50%;
}

/* ------------------------------------------- */

/* ------------------ done ------------------- */

/* ------------------------------------------- */

.create-account-done {
    display: flex;
    flex-direction: column;
    text-align: center;
    position: fixed;
    top: 7rem;
    left: 0;
    right: 0;
    bottom: 0;
}

/* texte au dessus de l'illustration */
.create-account-done h1 {
    margin-bottom: 1rem;
}

/* illustration */
.create-account-done .img-mobile {
    display: block;
    max-height: 300px;
    width: fit-content;
    margin: 0 auto;
}

.create-account-done .img-desktop {
    display: none;
}
.text-signUp{
    font-size: 24px;
}
.block-button-signup-challenge{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 5rem;
}
.button-signup-challenge{
    width: 20rem;
    position: relative;
    display: flex;
    justify-content: center;
}
/* ------------------------------------------- */

/* ---------------- tablet ------------------- */

/* ------------------------------------------- */

@media screen and (max-width: 350px) {
    .button-signup-challenge{
        width: 15rem;
    }
}

@media screen and (min-width: 900px) {

    /* page globale */
    .create-account {
        margin-top: 10rem;
        margin-bottom: 5rem;
    }

    /* barre de progression */
    .create-account .stepper {
        width: 36vw;
        margin: auto;
        max-width: 1300px;
    }

    /* page create account avec email et password  */
    .create-account .create-account-email-password {
        width: 36vw;
        margin: auto;
    }

    /* boutons de formulaire */
    .create-account-done button {
        width: 36vw;
        min-width: 50%;
        margin: auto;
    }

    /* logo à droite qui disparait */
    .create-account .diamond-login {
        display: none;
    }

    div.loginHeader2 {
        display: flex;
    }

    /* illustration lors de la validation */
    .create-account-done .img-illustration {
        margin: auto;
        margin-bottom: 2rem;
    }

    .create-account-done .img-mobile {
        display: none;
    }

    .create-account-done .img-desktop {
        display: block;
        max-width: 40rem;
        margin: 0 auto;
        transform: scale(1.5);
        pointer-events: none;
    }
}

@media screen and (max-width: 1200px) {
    /* page create account avec email et password  */
    .create-account .create-account-email-password {
        width: 60vw;
    }
}

@media screen and (max-width: 768px) {
    /* page create account avec email et password  */
    .create-account {
        padding: 0;
    }

    .create-account .create-account-email-password {
        width: 90vw;
        margin: auto;
        margin-bottom: 10rem;
    }
}

@media screen and (max-width: 900px) {
    .create-account-done {
        top: 12rem;
    }
}
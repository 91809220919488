/* design global de la carte  */

.card-name-date {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 0;
    height: 153px;
    border-radius: 10px;
    background-color: #EDF1F7;
    color: #122236;
    transition: 0.2s ease-in-out;
}

/* image de la carte */

.card-name-date .circle-contact-pict {
    border-radius: 100%;
    height: 12vh;
    margin: 20px;
    width: 12vh;
    min-width: none;
    max-height: 90px;
    max-width: 90px;
    min-height: 90px;
    min-width: 90px;
}

/* textes de la carte (nom et date) */

.card-name-date .name {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 0;
    line-height: 28px;
    margin-bottom: 0.1rem;
}

.card-pp {
    width: 7rem;
    height: 7rem;
    margin: 0 20px 0 30px;
}

.card-name-date .date {
    font-size: 1rem;
    font-weight: 500;
    margin: 0;
    line-height: 19px;
}

/* variant large de la carte */

.card-name-date-large {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin: 0;
    padding: 2rem 0;
    border-radius: 10px;
    background-color: #EDF1F7;
    color: #122236;
    transition: 0.2s ease-in-out;
}

.card-name-date-large .circle-contact-pict {
    border-radius: 100%;
    height: 7rem;
    width: 7rem;
    border: 2px solid #122236;
}

.card-name-date-large svg {
    margin: -1.5rem 0 2.5rem 0;
    width: 7rem;
    height: auto;
}
